import moment from 'moment';
import 'moment/locale/ar';
import { useState } from 'react';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import AppNotificationsDropdownMenu from './AppNotificationsDropdownMenu';
import { Link as RouterLink } from 'react-router-dom';
import './TopAppbar.scss';
import EmailIcon from '../../../common/icons/EmailIcon';
import MainAppProfileMenu from './MainAppProfileMenu';
import routerLinks from '../routerLinks';
import { MenuOutlined, CloseOutlined } from '@ant-design/icons';
import { useContext } from 'react';
import MainAppBarContext from '../../../contexts/main-app-bar-context/MainAppBarProvider';

const TopAppbar = () => {
  const { i18n } = useTranslation();
  const { menuMdOpened, setMenuMdOpened } = useContext(MainAppBarContext);
  useEffect(() => {
    moment.locale(i18n.language);
  }, [i18n.language]);

  const [timeNow, setTimeNow] = useState(
    moment().format('Do - MMMM - YYYY , h : mm : ss a')
  );
  useEffect(() => {
    const cb = () => {
      setTimeNow(moment().format('Do - MMMM - YYYY , h : mm : ss a'));
    };
    setInterval(cb, 1000);
    return () => {
      clearInterval(cb);
    };
  }, []);

  return (
    <div className="top-app-bar">
      <button
        onClick={() => {
          setMenuMdOpened((prev) => !prev);
        }}
        className="menu-btn"
      >
        {menuMdOpened ? <CloseOutlined /> : <MenuOutlined />}
      </button>
      <div className="date-welcome-wrapper">
        <p className="date-p">{timeNow}</p>
        <h3 className="welcome-p">مرحبا بعودتك !</h3>
      </div>

      <div className="notifications-msgs-profile-wrapper">
        <AppNotificationsDropdownMenu parentClassName="notifications-msgs-profile-wrapper" />
        <RouterLink
          className="msgs-link"
          to={routerLinks?.websiteContactsRoute}
        >
          <EmailIcon color="#404041" />
          الرسائل
        </RouterLink>

        <MainAppProfileMenu />
      </div>
    </div>
  );
};

export default TopAppbar;
