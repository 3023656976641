/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { yupResolver } from '@hookform/resolvers/yup';
import { Form } from 'antd';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import * as Yup from 'yup';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import FileInput from '../../common/file-input/FileInput';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import Xicon from '../../common/icons/Xicon';
import { getPackageFeaturesArr } from '../../const-values/packageFeaturesValues';
import imagesSrcs from '../../helpers/imagesSrcs';
import { setWebsitePackageFormOpenedAction } from '../../redux/website-packages-slice/websitePackagesSlice';
import {
  createWebsitePackageAction,
  updateWebsitePackageAction
} from '../../redux/website-packages-slice/websitePackagesSliceThunkApi';
import checkRes from '../../utils/checkRes';
import './WebsitePackageForm.scss';

const schema = Yup.object().shape({
  name_ar: Yup.string().required('من فضلك ادخل اسم الجهاز بالعربية'),
  name_en: Yup.string().required('من فضلك ادخل اسم الجهاز بالانجليزية'),
  price: Yup.string()
    .required('من فضلك سعر الباقة')
    .matches(/^(\d+)?(\.\d+)?$/, 'ادخل السعر على هيئة ارقام')
    .test('price', 'سعر خاطئ', (v, context) => {
      let result = true;
      if (!v || (v && parseFloat(v) <= 0)) {
        result = false;
      }
      return result;
    }),
  priceAfterDiscount: Yup.string()
    .notRequired()
    .matches(/^(\d+)?(\.\d+)?$/, 'ادخل السعر على هيئة ارقام')
    .test('priceAfterDiscount', 'سعر خاطئ', (v, context) => {
      let result = true;
      if (v && parseFloat(v) <= 0) {
        result = false;
      }
      return result;
    })
    .test('priceAfterDiscount', 'السعر اعلى من المتوقع', (v, context) => {
      let result = true;
      if (v && parseFloat(v) >= parseFloat(context.parent?.price))
        result = false;
      return result;
    }),
  duration_ar: Yup.string().required('المدة بالعربية'),
  duration_en: Yup.string().required('المدة بالانجليزية')
});

const { medalIcon, fontSizeIcon, imageIcon } = imagesSrcs;

const emptyFormValues = {
  name_ar: '',
  name_en: '',
  price: '',
  priceAfterDiscount: '',
  duration_ar: '',
  duration_en: '',
  device_ar: '',
  device_en: '',
  features: [],
  logo: null
};

const WebsitePackageForm = () => {
  const [files, setFiles] = useState(null);
  const dispatch = useDispatch();
  const selectedTableRow = useSelector(
    (store) => store.websitePackages.selectedTableRow
  );
  ///////////////
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    unregister,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: emptyFormValues
  });

  console.log('watch : ', watch());

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      setValue('name_ar', selectedTableRow?.name_ar || '');
      setValue('name_en', selectedTableRow?.name_en || '');
      setValue('price', selectedTableRow?.price || '');
      setValue(
        'priceAfterDiscount',
        selectedTableRow?.priceAfterDiscount || ''
      );
      setValue('duration_ar', selectedTableRow?.duration_ar || '');
      setValue('duration_en', selectedTableRow?.duration_en || '');
      setValue('device_ar', selectedTableRow?.device_ar || '');
      setValue('device_en', selectedTableRow?.device_en || '');
      if (selectedTableRow?.features?.length > 0)
        setValue(
          'features',
          selectedTableRow.features.map((item) => item?.name)
        );
    }
  }, [selectedTableRow]);
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('name_ar', data.name_ar || '');
    formData.append('name_en', data.name_en || '');
    formData.append('price', data.price || '');
    formData.append('priceAfterDiscount', data.priceAfterDiscount || '');
    formData.append('duration_ar', data.duration_ar || '');
    formData.append('duration_en', data.duration_en || '');
    formData.append('device_ar', data.device_ar || '');
    formData.append('device_en', data.device_en || '');
    if (data?.features?.length > 0) {
      for (let i of data.features) formData.append('features[]', i);
    }
    if (data?.logo?.length > 0) {
      formData.append('logo', data.logo[0]);
    }
    if (!selectedTableRow) {
      const action = await dispatch(
        createWebsitePackageAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action?.payload?.res;
      if (checkRes(res)) {
        reset(emptyFormValues);
        setFiles(null);
        dispatch(setWebsitePackageFormOpenedAction(false));
      }
    } else {
      formData.append('id', selectedTableRow?.id || '');

      const action = await dispatch(
        updateWebsitePackageAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action?.payload?.res;
      if (checkRes(res)) {
        reset(emptyFormValues);
        setFiles(null);
        dispatch(setWebsitePackageFormOpenedAction(false));
      }
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form website-package-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <p className="form-title">
        إضافة باقة جديدة
        <button
          type="button"
          className="back-btn"
          onClick={() => {
            dispatch(setWebsitePackageFormOpenedAction(false));
          }}
        >
          {/* <ArrowToLeftIcon color="#40404199" /> */}
          <Xicon color="#40404199" />
        </button>
      </p>
      <div className="form-body">
        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>إســم الباقة بالعربية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="name_ar"
              type="text"
              placeholder={'إســم الباقة بالعربية...'}
              // label="الاســــم"
              errorMsg={errors?.name_ar?.message}
              validateStatus={errors?.name_ar ? 'error' : ''}
              control={control}
            />
          </div>

          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>إســم الباقة بالانجليزية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="name_en"
              type="text"
              placeholder={'إســم الباقة بالانجليزية...'}
              // label="الاســــم"
              errorMsg={errors?.name_en?.message}
              validateStatus={errors?.name_en ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        {/*  */}
        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>سعر الباقة</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="price"
              type="text"
              placeholder={'سعر الباقة...'}
              // label="الاســــم"
              errorMsg={errors?.price?.message}
              validateStatus={errors?.price ? 'error' : ''}
              control={control}
            />
          </div>
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>السعر بعد الخصم</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="priceAfterDiscount"
              type="text"
              placeholder={'السعر بعد الخصم...'}
              // label="الاســــم"
              errorMsg={errors?.priceAfterDiscount?.message}
              validateStatus={errors?.priceAfterDiscount ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        {/*  */}

        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>مدة الباقة بالعربية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="duration_ar"
              type="text"
              placeholder={'مدة الباقة بالعربية...'}
              // label="الاســــم"
              errorMsg={errors?.duration_ar?.message}
              validateStatus={errors?.duration_ar ? 'error' : ''}
              control={control}
            />
          </div>
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>مدة الباقة بالانجليزية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="duration_en"
              type="text"
              placeholder={'مدة الباقة بالانجليزية...'}
              // label="الاســــم"
              errorMsg={errors?.duration_en?.message}
              validateStatus={errors?.duration_en ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>إسم الجهاز بالعربية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="device_ar"
              type="text"
              placeholder={'إسم الجهاز بالعربية...'}
              // label="الاســــم"
              errorMsg={errors?.device_ar?.message}
              validateStatus={errors?.device_ar ? 'error' : ''}
              control={control}
            />
          </div>
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              <span>اسم الجهاز بالانجليزية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="device_en"
              type="text"
              placeholder={'اسم الجهاز بالانجليزية...'}
              // label="الاســــم"
              errorMsg={errors?.device_en?.message}
              validateStatus={errors?.device_en ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        {/*  */}
        <div className="custom-select-wrap without-icon shared-app-select-option-label-wrapper multiple-select-option">
          <p className="outside-label">
            <img src={fontSizeIcon} alt="name" />
            مميزات الباقة
          </p>
          <AntdSelectOption
            name={`features`}
            errorMsg={errors?.features && errors.features.message}
            validateStatus={errors?.features ? 'error' : ''}
            control={control}
            setValue={setValue}
            placeholder="مميزات الباقة"
            mode="tags"
            options={
              getPackageFeaturesArr()?.length > 0 &&
              getPackageFeaturesArr().map((obj) => ({
                title: obj?.name,
                value: obj?.value
              }))
            }
            showSearch={true}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            formClassName="website-package-form"
          />
        </div>
        {/*  */}

        {/* END DESC */}
        <div className="file-inputs-wrap">
          <FileInput
            name="logo"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label={
              <p
                className="outside-label"
                style={{
                  color: '#404041',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  marginBottom: 8,
                  paddingRight: 8
                }}
              >
                <img src={imageIcon} alt="name" />
                الصورة
              </p>
            }
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الصورة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedTableRow?.logo ? [{ url: selectedTableRow.logo }] : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.logo?.message}
            files={files}
            setFiles={setFiles}
          />
        </div>

        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            type="button"
            onClick={() => {
              dispatch(setWebsitePackageFormOpenedAction(false));
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {/* {isLoadingSignin && <LoadingModal />} */}
    </Form>
  );
};

export default WebsitePackageForm;
