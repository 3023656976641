import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  changeWebsiteServiceStatusAction,
  createWebsiteServiceAction,
  deleteWebsiteServiceAction,
  getWebsiteServicesAction,
  getSingleWebsiteServiceAction,
  updateWebsiteServiceAction,
  getWebsiteServicesFeaturesAction
} from './websiteServiceSliceThunkApi';

const initialState = {
  /////////////////////
  fetchedSingleService: null,
  isLoadingTableData: false,
  fetchedTableData: [],
  fetchDataCount: 0,
  deleteModalOpened: false,
  isSubmitting: false,
  selectedTableRow: null,
  confirmModal: {
    opened: false,
    type: ''
  }
};

const websiteServicesSlice = createSlice({
  name: 'websiteServicesSlice',
  initialState,
  reducers: {
    setFetchDataCount: (sliceState, action) => {
      sliceState.fetchDataCount = action.payload;
    },
    setDeleteModalOpened: (sliceState, action) => {
      sliceState.deleteModalOpened = action.payload;
    },
    setSelectedTableRow: (sliceState, action) => {
      sliceState.selectedTableRow = action.payload;
    },
    setConfirmModal: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    }
  },
  extraReducers: {
    // get services
    [getWebsiteServicesAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getWebsiteServicesAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedTableData = data?.data;
      if (data?.pagination) state.tablePagination = data.pagination;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getWebsiteServicesAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    // get services feataures
    [getWebsiteServicesFeaturesAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getWebsiteServicesFeaturesAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedTableData = data?.data;
      if (data?.pagination) state.tablePagination = data.pagination;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getWebsiteServicesFeaturesAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    // get single service
    [getSingleWebsiteServiceAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getSingleWebsiteServiceAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedSingleService = data;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getSingleWebsiteServiceAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    //
    // change service status
    [changeWebsiteServiceStatusAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [changeWebsiteServiceStatusAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.confirmModal = {
          opened: false,
          type: ''
        };
      }
    },
    [changeWebsiteServiceStatusAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // create service
    [createWebsiteServiceAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [createWebsiteServiceAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchDataCount += 1;
        state.postFormModalOpened = false;
      }
    },
    [createWebsiteServiceAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // update service
    [updateWebsiteServiceAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [updateWebsiteServiceAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.postFormModalOpened = false;
      }
    },
    [updateWebsiteServiceAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    // delete service
    [deleteWebsiteServiceAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [deleteWebsiteServiceAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.deleteModalOpened = false;
      }
    },
    [deleteWebsiteServiceAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
    //
  }
});

const websiteServicesReducer = websiteServicesSlice.reducer;

export const {
  setPostFormModalOpened,
  setFetchDataCount,
  setDeleteModalOpened,
  setSelectedTableRow,
  setConfirmModal,
  setSelectedTab
} = websiteServicesSlice.actions;

export default websiteServicesReducer;
