import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  deleteWebsiteContactAction,
  getSingleWebsiteContactAction,
  getWebsiteContactsAction
} from './websiteContactsSliceThunkApi';

const initialState = {
  /////////////////////
  fetchedSingleContact: null,
  isLoadingTableData: false,
  fetchedTableData: [],
  //
  tablePagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  },
  fetchDataCount: 0,
  deleteModalOpened: false,
  isSubmitting: false,
  selectedTableRow: null,
  confirmModal: {
    opened: false,
    type: ''
  }
};

const websiteContactsSlice = createSlice({
  name: 'websiteContactsSlice',
  initialState,
  reducers: {
    setFetchDataCount: (sliceState, action) => {
      sliceState.fetchDataCount = action.payload;
    },
    setDeleteModalOpened: (sliceState, action) => {
      sliceState.deleteModalOpened = action.payload;
    },
    setSelectedTableRow: (sliceState, action) => {
      sliceState.selectedTableRow = action.payload;
    },
    setConfirmModal: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    }
  },
  extraReducers: {
    // get contacts
    [getWebsiteContactsAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getWebsiteContactsAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedTableData = data?.data;
      if (data?.pagination) state.tablePagination = data.pagination;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getWebsiteContactsAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    // get single contact
    [getSingleWebsiteContactAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getSingleWebsiteContactAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedSingleContact = data;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getSingleWebsiteContactAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    //
    //
    // delete contact
    [deleteWebsiteContactAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [deleteWebsiteContactAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.deleteModalOpened = false;
      }
    },
    [deleteWebsiteContactAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
    //
  }
});

const websiteContactsReducer = websiteContactsSlice.reducer;

export const {
  setPostFormModalOpened,
  setFetchDataCount,
  setDeleteModalOpened,
  setSelectedTableRow,
  setConfirmModal,
  setSelectedTab
} = websiteContactsSlice.actions;

export default websiteContactsReducer;
