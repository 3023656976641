import axios from 'axios';
import apisUrls from '../urls';

const createDeviceBrandApi = (values, edit = false,id) => {
  const url = !edit ? apisUrls?.createDeviceBrand : `${apisUrls?.updateDeviceBrand}?deviceBrand_id=${id}`;
  if (values?.icon?.length > 0) {
    values.icon = values.icon[0];
  }
  return axios.post(url, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export default createDeviceBrandApi;
