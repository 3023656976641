/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import './ExperienceForm.scss';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import FileInput from '../../common/file-input/FileInput';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import checkRes from '../../utils/checkRes';
import { setSelectedTableRow } from '../../redux/website-experiences-slice/websiteExperiencesSlice';
import {
  createWebsiteExperienceAction,
  deleteWebsiteExperienceImageAction,
  updateWebsiteExperienceAction
} from '../../redux/website-experiences-slice/websiteExperiencesSliceThunkApi';

const schema = Yup.object().shape({
  title_ar: Yup.string().required('من فضلك ادخل الاسم بالعربية'),
  title_en: Yup.string().required('من فضلك ادخل الاسم بالانجليزية'),
  desc_ar: Yup.string().required('من فضلك ادخل التفاصيل بالعربية'),
  desc_en: Yup.string().required('من فضلك ادخل التفاصيل بالانجليزية')
});

const { medalIcon } = imagesSrcs;

const emptyFormValues = {
  title_ar: '',
  title_en: '',
  subTitle_ar: '',
  subTitle_en: '',
  desc_ar: '',
  desc_en: '',
  image: null,
  images: null
};

const ExperienceForm = ({ fetchedTableData, submitAction }) => {
  const [files, setFiles] = useState(null);
  const [files1, setFiles1] = useState(null);
  const dispatch = useDispatch();
  const selectedTableRow = useSelector(
    (store) => store.websiteExperiences.selectedTableRow
  );
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    unregister,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: emptyFormValues
  });

  console.log('watch : ', watch());
  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      setValue('title_ar', selectedTableRow?.title_ar || '');
      setValue('title_en', selectedTableRow?.title_en || '');
      setValue('subTitle_ar', selectedTableRow?.subTitle_ar || '');
      setValue('subTitle_en', selectedTableRow?.subTitle_en || '');
      setValue('desc_ar', selectedTableRow?.desc_ar || '');
      setValue('desc_en', selectedTableRow?.desc_en || '');
    }
  }, [selectedTableRow, fetchedTableData]);
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('title_ar', data.title_ar || '');
    formData.append('title_en', data.title_en || '');
    formData.append('subTitle_ar', data.subTitle_ar || '');
    formData.append('subTitle_en', data.subTitle_en || '');
    formData.append('desc_ar', data.desc_ar || '');
    formData.append('desc_en', data.desc_en || '');
    if (data?.image?.length > 0) {
      formData.append('image', data.image[0]);
    }
    if (data?.images?.length > 0) {
      for (let i of data.images) {
        formData.append('images[]', i);
      }
    }

    if (!selectedTableRow) {
      const action = await dispatch(
        createWebsiteExperienceAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action?.payload?.res;
      if (checkRes(res)) {
        reset(emptyFormValues);
        setFiles(null);
        setFiles1(null);
      }
    } else {
      formData.append('id', selectedTableRow?.id || '');

      const action = await dispatch(
        updateWebsiteExperienceAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action?.payload?.res;
      if (checkRes(res)) {
        reset(emptyFormValues);
        setFiles(null);
        setFiles1(null);
      }
    }
  };

  const handleDeleteImgReq = (id) => {
    const formData = new FormData();
    formData.append('image_id', id);
    dispatch(
      deleteWebsiteExperienceImageAction({
        dataToBeSubmitted: formData
      })
    );
    // const res = action?.payload?.res;
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form website-experience-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان الرئيسى بالعربية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="title_ar"
            type="text"
            placeholder={'العنوان الرئيسى بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.title_ar?.message}
            validateStatus={errors?.title_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان الرئيسى بالانجليزية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="title_en"
            type="text"
            placeholder={'العنوان الرئيسى بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.title_en?.message}
            validateStatus={errors?.title_en ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان الفرعى بالعربية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="subTitle_ar"
            type="text"
            placeholder={'العنوان الفرعى بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.subTitle_ar?.message}
            validateStatus={errors?.subTitle_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان الفرعى بالانجليزية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="subTitle_en"
            type="text"
            placeholder={'العنوان الفرعى بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.subTitle_en?.message}
            validateStatus={errors?.subTitle_en ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="shared-app-text-field-label-wrapper is-text-area">
          <p className="outside-label">
            <img src={imagesSrcs?.deviceIcon} alt="name" />
            <span>التفاصيل بالعربية</span>
          </p>
          <AntdTextarea
            className="form-text-field"
            rows={5}
            name="desc_ar"
            type="text"
            placeholder={'التفاصيل بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.desc_ar?.message}
            validateStatus={errors?.desc_ar ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="shared-app-text-field-label-wrapper is-text-area">
          <p className="outside-label">
            <img src={imagesSrcs?.deviceIcon} alt="name" />
            <span>التفاصيل بالانجليزية</span>
          </p>
          <AntdTextarea
            className="form-text-field"
            rows={5}
            name="desc_en"
            type="text"
            placeholder={'التفاصيل بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.desc_en?.message}
            validateStatus={errors?.desc_en ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="file-inputs-wrap">
          <FileInput
            name="image"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label={
              <p
                className="outside-label"
                style={{
                  color: '#404041',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  marginBottom: 0,
                  paddingRight: 8
                }}
              >
                <img src={imagesSrcs?.imageIcon} alt="name" />
                الصورة
              </p>
            }
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الصورة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedTableRow?.image ? [{ url: selectedTableRow.image }] : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.image?.message}
            files={files}
            setFiles={setFiles}
          />
        </div>

        <div
          style={{
            marginTop: 14
          }}
          className="file-inputs-wrap"
        >
          <FileInput
            name="images"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label={
              <p
                className="outside-label"
                style={{
                  color: '#404041',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  marginBottom: 0,
                  paddingRight: 8
                }}
              >
                <img src={imagesSrcs?.imageIcon} alt="name" />
                مجموعة الصور
              </p>
            }
            accept="image/*"
            multiple={true}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الصور وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedTableRow?.images
                ? selectedTableRow.images.map((obj) => ({
                    ...obj,
                    url: obj?.image
                  }))
                : []
            }
            showError={false}
            errorMsg={errors?.images?.message}
            files={files1}
            setFiles={setFiles1}
            canDelete={true}
            handleDeleteImgReq={handleDeleteImgReq}
          />
        </div>

        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            type="button"
            onClick={() => {
              reset(emptyFormValues);
              dispatch(setSelectedTableRow(null));
              setFiles(null);
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {/* {isLoadingSignin && <LoadingModal />} */}
    </Form>
  );
};

export default ExperienceForm;
