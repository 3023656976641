import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  deleteWebsiteContactApi,
  getAllWebsiteContactsApi,
  getSingleWebsiteContactApi
} from '../../apis/website-contacts-apis/websiteContactsApis';
import customApiRequest from '../../helpers/customApiRequest';

export const getWebsiteContactsAction = createAsyncThunk(
  'websiteContacts/getWebsiteContactsAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getAllWebsiteContactsApi(filterObj),
      thunkAPI
    });
  }
);

export const getSingleWebsiteContactAction = createAsyncThunk(
  'websiteContacts/getSingleWebsiteContactAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getSingleWebsiteContactApi(filterObj),
      thunkAPI
    });
  }
);

//
export const deleteWebsiteContactAction = createAsyncThunk(
  'websiteContacts/deleteWebsiteContactAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteWebsiteContactApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
