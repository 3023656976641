/* eslint-disable eqeqeq */

import CustomImage from '../../common/custom-image/CustomImage';
import EditIcon from '../../common/icons/EditIcon';
import TrashIcon from '../../common/icons/TrashIcon';
import ActivateIcon from '../../common/icons/ActivateIcon';
import DeactivateIcon from '../../common/icons/DeactivateIcon';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';
import adminTypesValues, {
  getAdminRolesArr
} from '../../const-values/adminRoles';
import { Link } from 'react-router-dom';
import sharedRouterLinks from '../../components/app/shared-routes/sharedRouterLinks';
import { adminStatusValues } from '../../const-values/adminStatus';

const ProfileAdminsTableColumns = ({
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  setConfirmModal,
  setDeleteModalOpened,
  i18n,
  t,
  ...props
}) => {
  const handleClickConfirm = (record, type) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setConfirmModal({
        opened: true,
        type
      });
    }
  };
  const handleDeletePackage = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setDeleteModalOpened(true);
    }
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap two-rows">
        <div className="main-btns-wrapper">
          <button
            className="action-btn edit"
            onClick={() => handleDeletePackage(record)}
          >
            <TrashIcon color="#196c10" />
          </button>
          <Link
            to={sharedRouterLinks?.updateAdminFormRoute(record?.id)}
            className="action-btn edit"
          >
            <EditIcon color="#196c10" />
          </Link>
        </div>

        {record?.primaryAdmin === adminTypesValues?.primaryAdmin ? null : (
          <div className="accept-reject-btns">
            {record?.status == adminStatusValues?.notActive ? (
              <button
                className="accept-btn"
                onClick={() => {
                  handleClickConfirm(
                    record,
                    confirmModalTypesValues?.successModal
                  );
                }}
              >
                <ActivateIcon />
                تفعيل
              </button>
            ) : null}
            {record?.status == adminStatusValues?.active ? (
              <button
                className="deactivate-btn"
                onClick={() =>
                  handleClickConfirm(
                    record,
                    confirmModalTypesValues?.errorModal
                  )
                }
              >
                <DeactivateIcon />
                إلغاء التفعيل
              </button>
            ) : null}
          </div>
        )}
      </div>
    );
  };

  const renderRolesList = (arr) => {
    return (
      <div className="roles-list-wrapper">
        {arr.map((v, idx) => {
          const foundRole = getAdminRolesArr(t).find(
            (item) => item?.value == v
          );
          if (foundRole)
            return (
              <div key={idx} className="role-wrapper">
                {foundRole?.name}
              </div>
            );
          return null;
        })}
      </div>
    );
  };

  // const SingleTag = (item) => {
  //   if (item?.pacakge?.name) {
  //     return ()
  //   }
  // }

  // const renderSubscriptionTags = arr => {
  //   return (
  //     arr.map(item => {
  //       return (
  //         <SingleTag key={item?.id} item={item} />
  //       )
  //     })
  //   )
  // }

  return [
    {
      title: '',
      dataIndex: 'image',
      render: (_, record) => {
        return (
          <div className="table-img-wrapper">
            <CustomImage className="table-package-img" src={record?.icon} />
          </div>
        );
      }
    },
    {
      title: 'الاسم',
      dataIndex: 'name',
      render: (_, record) => {
        return record?.name || '----';
      }
    },
    {
      title: 'رقم الهاتف',
      dataIndex: 'phone',
      render: (_, record) => {
        return record?.phone || '----';
      }
    },
    {
      title: 'البريد الالكترونى',
      dataIndex: 'email',
      render: (_, record) => {
        return record?.email || '----';
      }
    },

    {
      title: 'إجراءات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">
            {record?.primaryAdmin === adminTypesValues?.primaryAdmin
              ? '---'
              : renderActions(record)}
          </div>
        );
      }
    }

    // {
    //   title: 'عرض التفاصيل',
    //   dataIndex: 'details',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <Tooltip title="عرض التفاصيل">
    //         <Button
    //           size="large"
    //           type="dashed"
    //           shape="round"
    //           icon={<EyeOutlined />}
    //           onClick={() => handleViewDelegateDetails(record)}
    //         />
    //       </Tooltip>
    //     );
    //   }
    // }
  ];
};

export default ProfileAdminsTableColumns;
