/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import './DeviceForm.scss';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import {
  setDeviceFormOpenedAction,
  setSelectedTableRow
} from '../../redux/devices-slice/devicesSlice';
import Xicon from '../../common/icons/Xicon';
import FileInput from '../../common/file-input/FileInput';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import { getDevicePapersSizesArr } from '../../const-values/devicePapersSizesValues';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import { getDeviceFontsSizesArr } from '../../const-values/deviceFontsSizesValues';
import {
  createDeviceAction,
  updateDeviceAction
} from '../../redux/devices-slice/devicesSliceThunkApis';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import { brandsStatus } from '../../const-values/brandsStatus';
import { useState } from 'react';
import DeleteIcon from '../../common/icons/DeleteIcon';
import PlusIcon from '../../common/icons/PlusIcon';

const schema = Yup.object().shape({
  name_ar: Yup.string().required('من فضلك ادخل اسم الجهاز بالعربية'),
  name_en: Yup.string().required('من فضلك ادخل اسم الجهاز بالانجليزية'),
  country_id: Yup.string().required('من فضلك اختار البلد'),
  model: Yup.string().required('من فضلك اختار موديل الجهاز'),
  brand_id: Yup.string().required('من فضلك اختار براند الجهاز')
  // papers: Yup.array()
  //   .min(1, 'من فضلك ادخل احجام ورقة الطباعة')
  //   .required('من فضلك ادخل احجام ورقة الطباعة'),
  // deviceFont: Yup.array()
  //   .min(1, 'من فضلك ادخل ادخل أحجام خط الطباعة')
  //   .required('من فضلك ادخل ادخل أحجام خط الطباعة'),
  // deviceFeatures: Yup.array()
  //   .min(1, 'من فضلك ادخل تفاصيل الجهاز')
  //   .required('من فضلك تفاصيل الجهاز الطباعة')
});

const {
  medalIcon,
  deviceIcon,
  paperSizeIcon,
  fontSizeIcon,
  deviceDescIcon,
  imageIcon,
  priceIcon
} = imagesSrcs;

const emptyFormValues = {
  name_ar: '',
  name_en: '',
  model: '',
  brand_id: '',
  country_id: '1',
  devicePaper:  [{ name_ar: '', name_en: '' }],
  deviceFeatures:  [{ name_ar: '', name_en: '' }],
  deviceFont:  [{ name_ar: '', name_en: '' }],
  image: null
};

const DeviceForm = () => {
  const [files, setFiles] = useState(null);
  const dispatch = useDispatch();
  const selectedTableRow = useSelector(
    (store) => store.devices.selectedTableRow
  );

  useEffect(() => {
    return () => {
      window.scrollTo(0, 0);
    };
  }, []);

  ///////////////
  // brands selectors
  const fetchedBrands = useSelector((store) => store.brands.fetchedBrands);
  const fetchedCountries = useSelector(
    (store) => store.devices.fetchedCountries
  );
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    unregister,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      ...emptyFormValues,
      devicePaper: selectedTableRow&&selectedTableRow.devicePaper?.length ? selectedTableRow.devicePaper : [{ name_ar: '', name_en: '' }],
      deviceFeatures: selectedTableRow&&selectedTableRow.deviceFeatures?.length  ? selectedTableRow.devicePaper : [{ name_ar: '', name_en: '' }],
      deviceFont: selectedTableRow&&selectedTableRow.deviceFont?.length  ? selectedTableRow.devicePaper : [{ name_ar: '', name_en: '' }]
    }
  });

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {

      reset({
        ...emptyFormValues,
        devicePaper: selectedTableRow&&selectedTableRow.devicePaper?.length  ? selectedTableRow.devicePaper : [{ name_ar: '', name_en: '' }],
        deviceFeatures: selectedTableRow&&selectedTableRow.deviceFeatures?.length  ? selectedTableRow.devicePaper : [{ name_ar: '', name_en: '' }],
        deviceFont: selectedTableRow&&selectedTableRow.deviceFont?.length ? selectedTableRow.devicePaper : [{ name_ar: '', name_en: '' }]
      });


      setValue('name_ar', selectedTableRow?.name_ar);
      setValue('name_en', selectedTableRow?.name_en);
      setValue('model', selectedTableRow?.model || '');
      setValue('description_ar', selectedTableRow?.description_ar || '');
      setValue('description_en', selectedTableRow?.description_en || '');
      setValue('price', Number(selectedTableRow?.deviceCountry?.[0]?.price) || '');
      setValue(
        'brand_id',
        selectedTableRow?.brand.id ? String(selectedTableRow?.brand.id) : ''
      );
      setValue('country_id', selectedTableRow?.deviceCountry?.[0]?.country_id || '1');
      if (selectedTableRow?.devicePaper?.length > 0)
        setValue('devicePaper', selectedTableRow.devicePaper);
      if (selectedTableRow?.deviceFont?.length > 0)
        setValue('deviceFont', selectedTableRow.deviceFont);
      if (selectedTableRow?.deviceFeatures?.length > 0)
        setValue('deviceFeatures', selectedTableRow.deviceFeatures);
    }
  }, [selectedTableRow]);
  useEffect(() => {
    //empty data from form on first render
    reset( {
      ...emptyFormValues,
      devicePaper: selectedTableRow&&selectedTableRow.devicePaper?.length  ? selectedTableRow.devicePaper : [{ name_ar: '', name_en: '' }],
      deviceFeatures: selectedTableRow&&selectedTableRow.deviceFeatures?.length  ? selectedTableRow.devicePaper : [{ name_ar: '', name_en: '' }],
      deviceFont: selectedTableRow&&selectedTableRow.deviceFont?.length  ? selectedTableRow.devicePaper : [{ name_ar: '', name_en: '' }]
    });
  }, []);

  const onSubmit = (data) => {
    const formData = new FormData();

    formData.append('name_ar', data.name_ar || '');
    formData.append('name_en', data.name_en || '');
    formData.append('description_ar', data?.description_ar || '');
    formData.append('description_en', data?.description_en || '');
    formData.append('price', data?.price || '');
    formData.append('deviceCountry', `[{"country_id":${data?.country_id},"price":${data?.price}}]`);
    formData.append('model', data?.model || '');
    formData.append('brand_id', data.brand_id || '');
    let countryId = Number(data?.country_id);
    if (countryId) {
      formData.append('country_id', data?.country_id || '');
    } else {
      const country = fetchedCountries.find(
        (obj) => obj.name === data?.country_id
      );
      formData.append('country_id', country?.id || '');
    }

    if (data?.devicePaper?.length > 0) {
      formData.append('devicePaper', JSON.stringify(data.devicePaper));
    }

    if (data?.deviceFont?.length > 0) {
      formData.append('deviceFont', JSON.stringify(data?.deviceFont));
    }
    if (data?.deviceFeatures?.length > 0) {
      formData.append('deviceFeatures', JSON.stringify(data?.deviceFeatures));
    }
    if (data?.image?.length > 0) {
      formData.append('image', data.image[0]);
    }
    if (!selectedTableRow) {
      dispatch(
        createDeviceAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
            dispatch(setSelectedTableRow(null));
          }
        })
      );
    } else {
      formData.append('device_id', selectedTableRow?.id || '');
      dispatch(
        updateDeviceAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
            dispatch(setSelectedTableRow(null));
          }
        })
      );
    }
  };

  const [form] = Form.useForm();

  // useEffect(() => {
  //   return () => {
  //     dispatch(setSelectedTableRow(null));
  //     setValue(emptyFormValues);
  //   };
  // }, []);

  return (
    <Form
      className="custom-shared-form device-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <p className="form-title">
        إضافة جهاز جديد
        {/* <button
          type="button"
          className="back-btn"
          onClick={() => {
            dispatch(setDeviceFormOpenedAction(false));
          }}
        >
          <Xicon color="#40404199" />
        </button> */}
      </p>
      <div className="form-body">
        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={deviceIcon} alt="name" />
              <span>إســم الجهاز بالعربية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="name_ar"
              type="text"
              placeholder={'إســم الجهاز بالعربية...'}
              // label="الاســــم"
              errorMsg={errors?.name_ar?.message}
              validateStatus={errors?.name_ar ? 'error' : ''}
              control={control}
            />
          </div>

          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={deviceIcon} alt="name" />
              <span>إســم الجهاز بالانجليزية</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="name_en"
              type="text"
              placeholder={'إســم الجهاز بالانجليزية...'}
              // label="الاســــم"
              errorMsg={errors?.name_en?.message}
              validateStatus={errors?.name_en ? 'error' : ''}
              control={control}
            />
          </div>
        </div>

        <div className="two-cols-row">
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={deviceIcon} alt="name" />
              <span>موديل الجهاز</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="model"
              type="text"
              placeholder={'موديل الجهاز...'}
              // label="الاســــم"
              errorMsg={errors?.model?.message}
              validateStatus={errors?.model ? 'error' : ''}
              control={control}
            />
          </div>
          <div className="shared-app-text-field-label-wrapper">
            <p className="outside-label">
              <img src={priceIcon} alt="name" />
              <span>سعر الجهاز</span>
            </p>
            <AntdTextField
              className="form-text-field"
              name="price"
              type="number"
              placeholder={'سعر الجهاز...'}
              // label="الاســــم"
              errorMsg={errors?.price?.message}
              validateStatus={errors?.price ? 'error' : ''}
              control={control}
            />
          </div>
        </div>
        <div className="select-label-wrap shared-app-select-option-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            أختار براند الجهاز
          </p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`brand_id`}
              errorMsg={errors?.brand_id && errors.brand_id.message}
              validateStatus={errors?.brand_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="أختار براند الجهاز"
              options={
                fetchedBrands?.data?.length > 0 &&
                fetchedBrands?.data
                  ?.filter((obj) => obj?.status === brandsStatus?.active)
                  .map((obj) => ({
                    title: obj?.name,
                    value: obj?.id
                  }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="device-form"
            />
          </div>
        </div>

        <div className="custom-select-wrap without-icon shared-app-select-option-label-wrapper">
          <p className="outside-label">
            <img src={fontSizeIcon} alt="name" />
            ادخل البلد
          </p>
          <AntdSelectOption
            name={`country_id`}
            errorMsg={errors?.country_id && errors.country_id.message}
            validateStatus={errors?.country_id ? 'error' : ''}
            control={control}
            setValue={setValue}
            placeholder="ادخل البلد"
            options={
              fetchedCountries?.data?.length > 0 &&
              fetchedCountries?.data?.map((obj) => ({
                title: obj?.name,
                value: obj?.id
              }))
            }
            showSearch={true}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            formClassName="device-form"
          />
        </div>
        <div className="custom-select-wrap without-icon shared-app-select-option-label-wrapper multiple-select-option feature-inputs">
          <p className="outside-label">
            <img src={deviceDescIcon} alt="name" />
            ادخل احجام ورقة الطباعة
          </p>

          {watch('devicePaper')?.map((feature, index) => (
            <div key={index} className="feature-input-group">
              <div className="two-cols-row">
                <AntdTextField
                  name={`devicePaper[${index}].name_ar`}
                  type="text"
                  placeholder="اسم احجام ورقة الطباعة بالعربية"
                  errorMsg={errors?.devicePaper?.[index]?.name_ar?.message}
                  validateStatus={
                    errors?.devicePaper?.[index]?.name_ar ? 'error' : ''
                  }
                  
                  control={control}
                />
                <AntdTextField
                  name={`devicePaper[${index}].name_en`}
                  type="text"
                  placeholder="Printing paper sizes name in English"
                  errorMsg={errors?.devicePaper?.[index]?.name_en?.message}
                  validateStatus={
                    errors?.devicePaper?.[index]?.name_en ? 'error' : ''
                  }
                  

                  control={control}
                />
              </div>
              <button
                type="button"
                onClick={() => {
                  const updatedDevicePaper = watch('devicePaper').filter(
                    (_, i) => i !== index
                  );
                  setValue('devicePaper', updatedDevicePaper);
                }}
              >
                <DeleteIcon />
              </button>
            </div>
          ))}

          <button
            type="button"
            className="plus-icon"
            onClick={() => {
              setValue('devicePaper', [
                ...watch('devicePaper'),
                { name_ar: '', name_en: '' }
              ]);
            }}
          >
            <PlusIcon />
          </button>
        </div>
    

           <div className="custom-select-wrap without-icon shared-app-select-option-label-wrapper multiple-select-option feature-inputs">
          <p className="outside-label">
            <img src={fontSizeIcon} alt="name" />
            ادخل أحجام خط الطباعة
          </p>

          {watch('deviceFont')?.map((feature, index) => (
            <div key={index} className="feature-input-group">
              <div className="two-cols-row">
                <AntdTextField
                  name={`deviceFont[${index}].name_ar`}
                  type="text"
                  placeholder=" خط الطباعة بالعربية"
                  errorMsg={errors?.deviceFont?.[index]?.name_ar?.message}
                  validateStatus={
                    errors?.deviceFont?.[index]?.name_ar ? 'error' : ''
                  }
                  
                  control={control}
                />
                <AntdTextField
                  name={`deviceFont[${index}].name_en`}
                  type="text"
                  placeholder="Print line  in English"
                  errorMsg={errors?.deviceFont?.[index]?.name_en?.message}
                  validateStatus={
                    errors?.deviceFont?.[index]?.name_en ? 'error' : ''
                  }
                  control={control}
                />
              </div>
              <button
                type="button"
                onClick={() => {
                  const updatedDeviceFont = watch('deviceFont').filter(
                    (_, i) => i !== index
                  );
                  setValue('deviceFont', updatedDeviceFont);
                }}
              >
                <DeleteIcon />
              </button>
            </div>
          ))}

          <button
            type="button"
            className="plus-icon"
            onClick={() => {
              setValue('deviceFont', [
                ...watch('deviceFont'),
                { name_ar: '', name_en: '' }
              ]);
            }}
          >
            <PlusIcon />
          </button>
        </div>
   

        <div className="custom-select-wrap without-icon shared-app-select-option-label-wrapper multiple-select-option feature-inputs">
          <p className="outside-label">
            <img src={paperSizeIcon} alt="name" />
            مميزات الجهاز
          </p>

          {watch('deviceFeatures')?.map((feature, index) => (
            <div key={index} className="feature-input-group">
              <div className="two-cols-row">
                <AntdTextField
                  name={`deviceFeatures[${index}].name_ar`}
                  type="text"
                  placeholder="اسم الخاصية بالعربية"
                  errorMsg={errors?.deviceFeatures?.[index]?.name_ar?.message}
                  validateStatus={
                    errors?.deviceFeatures?.[index]?.name_ar ? 'error' : ''
                  }
                  
                  control={control}
                />
                <AntdTextField
                  name={`deviceFeatures[${index}].name_en`}
                  type="text"
                  placeholder="Feature name in English"
                  errorMsg={errors?.deviceFeatures?.[index]?.name_en?.message}
                  validateStatus={
                    errors?.deviceFeatures?.[index]?.name_en ? 'error' : ''
                  }
                  

                  control={control}
                />
              </div>
              <button
                type="button"
                onClick={() => {
                  const updatedFeatures = watch('deviceFeatures').filter(
                    (_, i) => i !== index
                  );
                  setValue('deviceFeatures', updatedFeatures);
                }}
              >
                <DeleteIcon />
              </button>
            </div>
          ))}

          <button
            type="button"
            className="plus-icon"
            onClick={() => {
              setValue('deviceFeatures', [
                ...watch('deviceFeatures'),
                { name_ar: '', name_en: '' }
              ]);
            }}
          >
            <PlusIcon />
          </button>
        </div>
        {/* DESC */}
        <div className="shared-app-text-field-label-wrapper is-text-area">
          <p className="outside-label">
            <img src={deviceIcon} alt="name" />
            <span>تفاصيل الجهاز بالعربية</span>
          </p>
          <AntdTextarea
            className="form-text-field"
            rows={5}
            name="description_ar"
            type="text"
            placeholder={'تفاصيل الجهاز بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.description_ar?.message}
            validateStatus={errors?.description_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper is-text-area">
          <p className="outside-label">
            <img src={deviceIcon} alt="name" />
            <span>تفاصيل الجهاز بالانجليزية</span>
          </p>
          <AntdTextarea
            className="form-text-field"
            rows={5}
            name="description_en"
            type="text"
            placeholder={'تفاصيل الجهاز بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.description_en?.message}
            validateStatus={errors?.description_en ? 'error' : ''}
            control={control}
          />
        </div>
        {/* END DESC */}

        <div className="file-inputs-wrap">
          <FileInput
            files={files}
            setFiles={setFiles}
            name="image"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label={
              <p
                className="outside-label"
                style={{
                  color: '#404041',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  marginBottom: 8,
                  paddingRight: 8
                }}
              >
                <img src={imageIcon} alt="name" />
                صورة الجهاز
              </p>
            }
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الصورة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedTableRow?.image ? [{ url: selectedTableRow.image }] : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.image?.message}
          />
        </div>

        <div className="modal-action-btns-wrapper">
          <button type="submit" className="accept-btn">
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            type="button"
            onClick={() => {
              // dispatch(setDeviceFormOpenedAction(false));
              reset();
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {/* {isLoadingSignin && <LoadingModal />} */}
    </Form>
  );
};

export default DeviceForm;
