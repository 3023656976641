import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  createAppPackageAction,
  deleteAppPackageAction,
  getPackagesAction,
  toggleAppPackageAction,
  updateAppPackageAction
} from './appPackagesSliceThunkApi';

export const deviceOrBrand = {
  device: 1,
  brand: 2
};

const initialState = {
  /////////////////////
  isLoadingTableData: false,
  fetchedTableData: [],
  fetchDataCount: 0,
  packageFormOpened: false,
  watchPackageDevice: null,
  deleteModalOpened: false,
  isSubmitting: false,
  selectedTableRow: null,
  confirmModal: {
    opened: false,
    type: ''
  },
  //
  selectedTab: deviceOrBrand?.device,
  tablePagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  }
};

const packagesSlice = createSlice({
  name: 'packagesSlice',
  initialState,
  reducers: {
    setPackageFormOpenedAction: (sliceState, action) => {
      // window.scrollTo(0, 0);
      sliceState.packageFormOpened = action.payload;
    },
    setWatchPackageDevice: (sliceState, action) => {
      sliceState.watchPackageDevice = action.payload;
    },
    setDeleteModalOpened: (sliceState, action) => {
      sliceState.deleteModalOpened = action.payload;
    },
    setConfirmModal: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    },
    setSelectedTableRow: (sliceState, action) => {
      sliceState.selectedTableRow = action.payload;
    },
    setSelectedTab: (sliceState, action) => {
      sliceState.selectedTab = action.payload;
    }
  },
  extraReducers: {
    [getPackagesAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getPackagesAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedTableData = data?.data;
      if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getPackagesAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    //
    // create package
    [createAppPackageAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [createAppPackageAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchDataCount += 1;
        state.packageFormOpened = false;
      }
    },
    [createAppPackageAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // update package
    [updateAppPackageAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [updateAppPackageAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.packageFormOpened = false;
      }
    },
    [updateAppPackageAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // toggle package
    [toggleAppPackageAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [toggleAppPackageAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchDataCount += 1;
        state.confirmModal = {
          opened: false,
          type: ''
        };
      }
    },
    [toggleAppPackageAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    //
    [deleteAppPackageAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [deleteAppPackageAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.deleteModalOpened = false;
      }
    },
    [deleteAppPackageAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
    //
  }
});

const packagesReducer = packagesSlice.reducer;

export const {
  setPackageFormOpenedAction,
  setWatchPackageDevice,
  setDeleteModalOpened,
  setSelectedTableRow,
  setConfirmModal,
  setSelectedTab
} = packagesSlice.actions;

export default packagesReducer;
