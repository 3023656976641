export const sortPackagesValues = {
  priceDesc: 1,
  priceAsc: 2
};

export const getSortPackagesArr = () => {
  return [
    {
      id: 1,
      name: 'من الأحدث إلى الأقدم',
      value: sortPackagesValues.priceDesc
    },
    {
      id: 2,
      name: 'من الأقدم إلى الأحدث',
      value: sortPackagesValues.priceAsc
    }
  ];
};
