/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import EyeOnIcon from '../../common/icons/EyeOnIcon';
import EyeOffIcon from '../../common/icons/EyeOffIcon';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import changePasswordApi from '../../apis/auth/changePasswordApi';
import checkRes from '../../utils/checkRes';
import LoadingModal from '../../common/loading-modal/LoadingModal';

const schema = Yup.object().shape({
  oldPassword: Yup.string()
    .required('من فضلك ادخل كلمة المرور القديمة')
    .min(6, 'أقل حد 6 حروف'),
  newPassword: Yup.string()
    .required('من فضلك ادخل كلمة المرور الجديدة')
    .min(6, 'أقل حد 6 حروف'),
  newPassword_confirm: Yup.string()
    .required('من فضلك اعد كتابة كلمة المرور الجديدة')
    .oneOf([Yup.ref('newPassword')], 'كلمة مرور خاطئة')
});

const ChangePasswordForm = () => {
  const fetchedSingleAdmin = useSelector(
    (store) => store.settings.fetchedSingleAdmin
  );

  const emptyFormValues = {
    oldPassword: '',
    newPassword: '',
    newPassword_confirm: ''
  };
  const {
    control,
    handleSubmit,
    reset,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      ...emptyFormValues
    }
  });

  const [isSubmitting, setIsSubmitting] = useState(false);
  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('oldPassword', data.oldPassword || '');
    formData.append('newPassword', data.newPassword || '');
    customApiRequest(
      changePasswordApi(formData),
      (res) => {
        setIsSubmitting(false);
        if (checkRes(res)) {
          reset({
            oldPassword: '',
            newPassword: '',
            newPassword_confirm: ''
          });
        }
      },
      (error) => {
        setIsSubmitting(false);
      }
    );
  };

  const [passwrodVisible, setPasswordVisible] = React.useState(false);

  // console.log('watch : ', watch());

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form change-profile-password-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <p className="form-title">تغيير كلمة المرور</p>
      <div className="form-body">
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={imagesSrcs?.lockIcon} alt="lock" />
            <span>كلمة المرور</span>
          </p>
          <div className="shared-form-password-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="oldPassword"
              type={passwrodVisible ? 'text' : 'password'}
              placeholder="كلمة المرور..."
              errorMsg={errors?.oldPassword?.message}
              validateStatus={errors?.oldPassword ? 'error' : ''}
              control={control}
            />

            {watch('oldPassword') && (
              <div
                className="eye-icon-btn"
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </div>
            )}
          </div>
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={imagesSrcs?.lockIcon} alt="lock" />
            <span>كلمة المرور الجديدة</span>
          </p>
          <div className="shared-form-password-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="newPassword"
              type={passwrodVisible ? 'text' : 'password'}
              placeholder="كلمة المرور الجديدة..."
              errorMsg={errors?.newPassword?.message}
              validateStatus={errors?.newPassword ? 'error' : ''}
              control={control}
            />

            {watch('newPassword') && (
              <div
                className="eye-icon-btn"
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </div>
            )}
          </div>
        </div>

        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={imagesSrcs?.lockIcon} alt="lock" />
            <span>تأكيد كلمة المرور الجديدة</span>
          </p>
          <div className="shared-form-password-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="newPassword_confirm"
              type={passwrodVisible ? 'text' : 'password'}
              placeholder="تأكيد كلمة المرور الجديدة..."
              errorMsg={errors?.newPassword_confirm?.message}
              validateStatus={errors?.newPassword_confirm ? 'error' : ''}
              control={control}
            />

            {watch('newPassword_confirm') && (
              <div
                className="eye-icon-btn"
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </div>
            )}
          </div>
        </div>

        <div
          className="modal-action-btns-wrapper"
          style={{
            justifyContent: 'flex-end'
          }}
        >
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            type="button"
            onClick={() => {
              if (!fetchedSingleAdmin) reset(emptyFormValues);
              else
                reset({
                  oldPassword: '',
                  newPassword: '',
                  newPassword_confirm: ''
                });
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {/* {isLoadingSignin && <LoadingModal />} */}
      {isSubmitting && <LoadingModal />}
    </Form>
  );
};

export default ChangePasswordForm;
