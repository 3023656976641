import CommonTag from '../../common/common-tag/CommonTag';
import CustomEmpty from '../../common/custom-empty/CustomEmpty';
import CustomImage from '../../common/custom-image/CustomImage';
import imagesSrcs from '../../helpers/imagesSrcs';

import './PackageDescription.scss';

const PackageDescription = ({ item }) => {
  return (
    <div className="package-description-wrapper">
      <div className="wrapper-title">عرض تفاصيل الاشتراك</div>
      {item ? (
        <div className="content-wrapper">
          <div className="content-label">
            {item?.duration && (
              <div className="brand-label">
                <img src={imagesSrcs?.medalIcon} alt="brand" />
                {`اشتراك ${item.duration}`}
              </div>
            )}
            <div className="name-label">
              <p>ريال سعودى</p>
              <p className="price-span">
                {item?.priceAfterDiscount || item?.price}
              </p>
            </div>

            <CustomImage className="device-img" src={item?.device?.image} />

            {item?.device?.name && (
              <div className="desc-wrapper">
                <p className="desc-label">الجهاز</p>
                <p className="desc-text">{item.device.name}</p>
              </div>
            )}
            {item?.device?.brand?.name && (
              <div className="desc-wrapper">
                <p className="desc-label">براند الجهاز</p>
                <p className="desc-text">{item.device.brand.name}</p>
              </div>
            )}
            {item?.device?.model && (
              <div className="desc-wrapper">
                <p className="desc-label">موديل الجهاز</p>
                <p className="desc-text">{item.device.model}</p>
              </div>
            )}
            {item?.device?.description && (
              <div className="desc-wrapper">
                <p className="desc-label">وصف الجهاز</p>
                <p className="desc-text">{item.device.description}</p>
              </div>
            )}
          </div>

          {item?.device?.papers?.length > 0 && (
            <div className="papers-wrapper">
              <p className="papers-label">ججم ورقة الطباعة</p>
              <div className="list-wrapper">
                {item.device.papers.map((p) => (
                  <CommonTag
                    key={p?.id}
                    svg={
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M8.00016 15.1666C7.48016 15.1666 6.9735 14.8999 6.62683 14.4333L5.9535 13.5333C5.8135 13.3466 5.62683 13.2399 5.42683 13.2266C5.22683 13.2199 5.02683 13.3066 4.86683 13.4733L4.48683 13.1333L4.8535 13.4733C3.8935 14.4999 3.1535 14.4199 2.80016 14.2799C2.44016 14.1399 1.8335 13.6799 1.8335 12.1999V4.69325C1.8335 1.73325 2.68683 0.833252 5.48016 0.833252H10.5202C13.3135 0.833252 14.1668 1.73325 14.1668 4.69325V12.1999C14.1668 13.6733 13.5602 14.1333 13.2002 14.2799C12.8468 14.4199 12.1135 14.4999 11.1468 13.4733C10.9868 13.2999 10.7935 13.2066 10.5802 13.2266C10.3802 13.2399 10.1868 13.3466 10.0468 13.5333L9.3735 14.4333C9.02683 14.8999 8.52016 15.1666 8.00016 15.1666ZM5.38683 12.2199C5.4135 12.2199 5.44683 12.2199 5.4735 12.2199C5.96683 12.2466 6.4335 12.5066 6.74683 12.9266L7.42016 13.8266C7.74683 14.2599 8.24683 14.2599 8.5735 13.8266L9.24683 12.9266C9.56683 12.5066 10.0268 12.2466 10.5268 12.2199C11.0202 12.1933 11.5135 12.3999 11.8735 12.7866C12.3802 13.3266 12.7135 13.3933 12.8268 13.3466C12.9868 13.2799 13.1602 12.8933 13.1602 12.1999V4.69325C13.1602 2.28659 12.7402 1.83325 10.5135 1.83325H5.48016C3.2535 1.83325 2.8335 2.28659 2.8335 4.69325V12.1999C2.8335 12.8999 3.00683 13.2866 3.16683 13.3466C3.28016 13.3933 3.6135 13.3266 4.12016 12.7866C4.48016 12.4199 4.92683 12.2199 5.38683 12.2199Z"
                          fill="#404041"
                          fillOpacity="0.6"
                        />
                        <path
                          d="M10.6668 5.16675H5.3335C5.06016 5.16675 4.8335 4.94008 4.8335 4.66675C4.8335 4.39341 5.06016 4.16675 5.3335 4.16675H10.6668C10.9402 4.16675 11.1668 4.39341 11.1668 4.66675C11.1668 4.94008 10.9402 5.16675 10.6668 5.16675Z"
                          fill="#404041"
                          fillOpacity="0.6"
                        />
                        <path
                          d="M10 7.83325H6C5.72667 7.83325 5.5 7.60659 5.5 7.33325C5.5 7.05992 5.72667 6.83325 6 6.83325H10C10.2733 6.83325 10.5 7.05992 10.5 7.33325C10.5 7.60659 10.2733 7.83325 10 7.83325Z"
                          fill="#404041"
                          fillOpacity="0.6"
                        />
                      </svg>
                    }
                    title={p?.name}
                    className="green"
                  />
                ))}
              </div>
            </div>
          )}

          {item?.device?.fonts?.length > 0 && (
            <div className="papers-wrapper">
              <p className="papers-label">ججم خط الطباعة</p>
              <div className="list-wrapper">
                {item.device.fonts.map((p) => (
                  <CommonTag
                    key={p?.id}
                    svg={
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.2198 5.2801C13.9465 5.2801 13.7198 5.05343 13.7198 4.7801V3.56677C13.7198 3.0801 13.3265 2.68677 12.8398 2.68677H3.15979C2.67312 2.68677 2.27979 3.0801 2.27979 3.56677V4.78677C2.27979 5.0601 2.05312 5.28677 1.77979 5.28677C1.50645 5.28677 1.27979 5.0601 1.27979 4.7801V3.56677C1.27979 2.52677 2.12645 1.68677 3.15979 1.68677H12.8398C13.8798 1.68677 14.7198 2.53343 14.7198 3.56677V4.78677C14.7198 5.0601 14.4998 5.2801 14.2198 5.2801Z"
                          fill="#404041"
                          fillOpacity="0.6"
                        />
                        <path
                          d="M8 14.3133C7.72667 14.3133 7.5 14.0867 7.5 13.8133V2.73999C7.5 2.46666 7.72667 2.23999 8 2.23999C8.27333 2.23999 8.5 2.46666 8.5 2.73999V13.8133C8.5 14.0933 8.27333 14.3133 8 14.3133Z"
                          fill="#404041"
                          fillOpacity="0.6"
                        />
                        <path
                          d="M10.6269 14.3132H5.37354C5.1002 14.3132 4.87354 14.0866 4.87354 13.8132C4.87354 13.5399 5.1002 13.3132 5.37354 13.3132H10.6269C10.9002 13.3132 11.1269 13.5399 11.1269 13.8132C11.1269 14.0866 10.9002 14.3132 10.6269 14.3132Z"
                          fill="#404041"
                          fillOpacity="0.6"
                        />
                      </svg>
                    }
                    title={p?.name}
                    className="green"
                  />
                ))}
              </div>
            </div>
          )}

          {item?.features?.length > 0 && (
            <div className="features-wrapper">
              <p className="features-label">مميزات الباقة</p>
              <div className="list-wrapper">
                {item.features.map((p) => (
                  <p key={p?.id}>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="13"
                      height="10"
                      viewBox="0 0 13 10"
                      fill="none"
                    >
                      <path
                        opacity="0.34"
                        d="M1 5L4.66235 9L12 1"
                        stroke="#2A9D7C"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                    {p?.name}
                  </p>
                ))}
              </div>
            </div>
          )}
        </div>
      ) : (
        <CustomEmpty />
      )}
    </div>
  );
};

export default PackageDescription;
