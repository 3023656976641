/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import {
  useLocation,
  Navigate,
  Route,
  Routes as Switch
} from 'react-router-dom';
import UserContext from '../../contexts/user-context/UserProvider';
import NotFoundPage from '../../pages/not-found-page/NotFoundPage';
import SigninPage from '../../pages/signin-page/SigninPage';
import GeneralRoutes from './general-routes/GeneralRoutes';
import routerLinks from './routerLinks';
import sharedRouterLinks from './shared-routes/sharedRouterLinks';
import SharedRoutes from './shared-routes/SharedRoutes';
import InvoicePublic from './InvoicePublic/InvoicePublic';
import InvoiceNoClient from './InvoiceNoClient/InvoiceNoClient';
const Routes = () => {
  const { loggedIn } = useContext(UserContext);
  const location = useLocation();
  const renderSigninRoute = () => {
    if (!loggedIn) {
      return <SigninPage />;
    } else {
      return <Navigate to={sharedRouterLinks?.homeRoute} />;
      // if (user?.accountType == accountTypes()?.user)
      //   return <Navigate to={routerLinks?.homePage} />;
      // return <Navigate to={merchentRouterLinks?.merchentDashboard} />;
    }
  };

  return (
    <Switch location={location} key={location?.pathname}>
      <Route
        exact
        path={routerLinks?.signinPage}
        element={renderSigninRoute()}
      />
      <Route
        path="/client_public/:client_id/:invoice_id"
        element={<InvoicePublic />}
      />
      <Route path="/invoice_public/:invoice_id" element={<InvoiceNoClient />} />
      {GeneralRoutes()}

      {SharedRoutes()}

      <Route path="*" element={<NotFoundPage />} />
    </Switch>
  );
};

export default Routes;
