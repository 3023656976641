/* eslint-disable eqeqeq */
const getFoundObjByValue = (arr, value) => {
  if (arr?.length > 0) {
    const found = arr.find((item) => item.value == value);
    return found;
  }
  return null;
};

export default getFoundObjByValue;
