import axios from 'axios';
import apisUrls from '../urls';

const createInfluencerApi = (values, edit = false) => {
  return axios.post(
    !edit ? apisUrls?.createInfluencer : apisUrls?.updateInfluencer,
    values,
    {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  );
};

export default createInfluencerApi;
