/* eslint-disable react-hooks/exhaustive-deps */
import { Badge } from 'antd';

import queryString from 'query-string';
import { useLocation, useNavigate } from 'react-router-dom';
import { getSortPackagesArr } from '../../const-values/sortPackagesArr';
import { useTranslation } from 'react-i18next';
import DropdownFilter from '../../common/drop-down-filter/DropdownFilter';
import {
  medalImgSrc,
  usersImgSrc,
  discountImgSrc
} from '../../const-values/imagesImports';
import CreateNewBtn from '../../common/create-new-btn/CreateNewBtn';
import TableFilterSearchComponent from '../../common/table-filter-search-component/TableFilterSearchComponent';
import { useDispatch, useSelector } from 'react-redux';
import sharedRouterLinks from '../../components/app/shared-routes/sharedRouterLinks';
import { useEffect, useState } from 'react';
import {
  setPostFormModalOpened,
  setSelectedTableRow
} from '../../redux/influcencers-slice/influencersSlice';
import imagesSrcs from '../../helpers/imagesSrcs';

const StatBox = ({ title, value, imgSrc }) => {
  return (
    <div className="stat-box">
      <img src={imgSrc} alt="medal" />
      <span className="label-span">{title}</span>
      <Badge
        className="value-badge"
        overflowCount={100000}
        count={value}
        showZero
      />
    </div>
  );
};
const InfluencersPageFilter = () => {
  const dispatch = useDispatch();
  const pagination = useSelector((store) => store.influencers.tablePagination);
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const values = queryString.parse(search);
  const [searchText, setSearchText] = useState('');

  useEffect(() => {
    setSearchText(values?.searchText || '');
  }, [search]);

  return (
    <div className="filter-stats-search-wrapper">
      <div className="sort-by-wrapper">
        <DropdownFilter
          value={values?.sort || ''}
          filterOptionsArr={getSortPackagesArr(t)}
          onChange={(v) => {
            if (v) {
              navigate(
                `${sharedRouterLinks?.appAgentsRoute}?searchText=${
                  values?.searchText || ''
                }&sort=${v || ''}&page=1`
              );
            }
          }}
        />
      </div>

      <div className="stats-new-btn-wrapper">
        <div className="stats-wrap">
          <StatBox
            title="المسوقون"
            value={pagination?.total || '-'}
            imgSrc={imagesSrcs?.facesIcon}
          />
          <StatBox
            title="المنضمون حديثا"
            value={pagination?.newCount || '-'}
            imgSrc={imagesSrcs?.userTagIcon}
          />
          {/* <StatBox
            title="كوبونات الخصم"
            value={pagination?.discountCopouns || '-'}
            imgSrc={discountImgSrc}
          /> */}
        </div>
        <CreateNewBtn
          onClick={() => {
            dispatch(setSelectedTableRow(null));
            dispatch(setPostFormModalOpened(true));
          }}
          btnText="مسوق جديد"
        />
      </div>

      <TableFilterSearchComponent
        searchText={searchText}
        setSearchText={setSearchText}
        // defaultValue={values?.searchText || ''}
        onSearch={() => {
          // if (searchText && searchText.trim()) {
          navigate(
            `${sharedRouterLinks?.appAgentsRoute}?searchText=${
              searchText || ''
            }&status=${values?.status || ''}&page=1`
          );
          // }
        }}
      />

      {(values?.searchText || values?.status) && (
        <button
          className="reset-btn"
          onClick={() => {
            navigate(sharedRouterLinks?.appAgentsRoute);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
          >
            <path d="M12 16c1.671 0 3-1.331 3-3s-1.329-3-3-3-3 1.331-3 3 1.329 3 3 3z"></path>
            <path d="M20.817 11.186a8.94 8.94 0 0 0-1.355-3.219 9.053 9.053 0 0 0-2.43-2.43 8.95 8.95 0 0 0-3.219-1.355 9.028 9.028 0 0 0-1.838-.18V2L8 5l3.975 3V6.002c.484-.002.968.044 1.435.14a6.961 6.961 0 0 1 2.502 1.053 7.005 7.005 0 0 1 1.892 1.892A6.967 6.967 0 0 1 19 13a7.032 7.032 0 0 1-.55 2.725 7.11 7.11 0 0 1-.644 1.188 7.2 7.2 0 0 1-.858 1.039 7.028 7.028 0 0 1-3.536 1.907 7.13 7.13 0 0 1-2.822 0 6.961 6.961 0 0 1-2.503-1.054 7.002 7.002 0 0 1-1.89-1.89A6.996 6.996 0 0 1 5 13H3a9.02 9.02 0 0 0 1.539 5.034 9.096 9.096 0 0 0 2.428 2.428A8.95 8.95 0 0 0 12 22a9.09 9.09 0 0 0 1.814-.183 9.014 9.014 0 0 0 3.218-1.355 8.886 8.886 0 0 0 1.331-1.099 9.228 9.228 0 0 0 1.1-1.332A8.952 8.952 0 0 0 21 13a9.09 9.09 0 0 0-.183-1.814z"></path>
          </svg>
        </button>
      )}
    </div>
  );
};

export default InfluencersPageFilter;
