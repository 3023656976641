/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'antd';
import imagesSrcs from '../../helpers/imagesSrcs';
import { useDispatch, useSelector } from 'react-redux';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import './PaymentTypesForm.scss';
import FileInput from '../../common/file-input/FileInput';
import {
  createPaymentTypeActions,
  updatePaymentTypeAction
} from '../../redux/payment-types-slice/paymentTypesSliceThunkApi';
import { setPostFormModalOpened } from '../../redux/payment-types-slice/paymentTypesSlice';
import { useState } from 'react';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

const schema = Yup.object().shape({
  // name: Yup.string().required('هذا الحقل مطلوب')
});

const PaymentTypesForm = () => {
  const [files, setFiles] = useState(null);
  const dispatch = useDispatch();
  const selectedTableRow = useSelector(
    (store) => store.paymentTypes.selectedTableRow
  );

  const emptyFormValues = {
    name: selectedTableRow?.name || '',
    icon: null
  };
  const {
    handleSubmit,
    setValue,
    watch,
    register,
    unregister,
    control,

    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: {
      ...emptyFormValues
    }
  });

  const onSubmit = (data) => {
    if (selectedTableRow) {
      const formData = new FormData();
      if (data?.icon?.length > 0) {
        formData.append('icon', data.icon[0]);
      }
      formData.append('id', selectedTableRow?.id || '');
      formData.append('name', data?.name || '');
      dispatch(
        updatePaymentTypeAction({
          dataToBeSubmitted: formData
        })
      );
    }
    // if (!selectedTableRow) {
    //   const formData = new FormData();
    //   if (data?.icon?.length > 0) {
    //     formData.append('icon', data.icon[0]);
    //   }
    //   formData.append('name', data?.name || '');
    //   dispatch(
    //     createPaymentTypeActions({
    //       dataToBeSubmitted: formData
    //     })
    //   );
    // }
  };

  const [form] = Form.useForm();
  const { medalIcon } = imagesSrcs;

  return (
    <Form
      className="custom-shared-form payment-type-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        {/* <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>طريقة الدفع </span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="name"
            type="text"
            placeholder={'الاسم ...'}
            // label="الاســــم"
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            control={control}
          />
        </div> */}
        <div className="file-inputs-wrap">
          <FileInput
            name="icon"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label={
              <p
                className="outside-label"
                style={{
                  color: '#404041',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  marginBottom: 8,
                  paddingRight: 8
                }}
              >
                <img src={imagesSrcs?.imageIcon} alt="name" />
                صورة طريقة الدفع
              </p>
            }
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الصورة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedTableRow?.icon ? [{ url: selectedTableRow.icon }] : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.icon?.message}
            files={files}
            setFiles={setFiles}
          />
        </div>

        <div className="modal-action-btns-wrapper">
          <button
            // disabled={!watch('icon')}
            className="accept-btn"
            onClick={() => {}}
          >
            <CheckmarkCircledIcon />
            {selectedTableRow ? 'تعديل' : 'اضافه'}{' '}
          </button>
          <button
            type="button"
            onClick={() => {
              dispatch(setPostFormModalOpened(false));
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {/* {isLoadingSignin && <LoadingModal />} */}
    </Form>
  );
};

export default PaymentTypesForm;
