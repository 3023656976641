import CustomImage from '../../common/custom-image/CustomImage';
import calenderImg from '../../assets/imgs/profile-images/calendar-icon.svg';
import mobileImg from '../../assets/imgs/profile-images/mobile-icon.svg';
import mailImg from '../../assets/imgs/profile-images/mail-icon.svg';
import './InfluencerInfoSection.scss';
import imagesSrcs from '../../helpers/imagesSrcs';
import InfluencerInvoiceModal from './InfluencerInvoiceModal';
import { useDispatch } from 'react-redux';
import {
  setInfluencerInvoiceModalOpened,
  setSelectedInfluencerInvoice
} from '../../redux/influcencers-slice/influencersSlice';
import CustomEmpty from '../../common/custom-empty/CustomEmpty';

const BoxStat = ({ icon, value, title }) => {
  return (
    <div className="box-stat">
      <div className="icon-wrap">{icon}</div>
      <div className="title-wrap">{title}</div>
      <div className="value-wrap">{value}</div>
    </div>
  );
};

const SingleInfo = ({ img, value }) => {
  return (
    <div className="single-info-box">
      <img src={img} alt="info" />
      <p>{value}</p>
    </div>
  );
};

const InfluencerInfoSection = ({
  fetchedSingleInfluencer,
  selectedInfluencerInvoice,
  invoiceModalOpened
}) => {
  const dispatch = useDispatch();
  const influencerInfo = fetchedSingleInfluencer;
  
  return (
    <div className="influencer-info-stats-sections">
      <section className="stats-section">
        <CustomImage className="profile-img" src={influencerInfo?.image} />
        <div className="stats-wrapper">
          <div className="name-email-edit-wrapper">
            <p className="name">{influencerInfo?.name}</p>
            <p className="email">{influencerInfo?.email}</p>
          </div>

          <div className="inf-stats-boxs">
            {influencerInfo?.code && (
              <p className="code-wrap"># {influencerInfo.code}</p>
            )}

            {influencerInfo?.discount && (
              <BoxStat
                value={`${influencerInfo?.discount}%` || '-'}
                title="نسبة خصم الكوبون"
              />
            )}

            <BoxStat
              // icon={<img src={imagesSrcs?.copoun} alt="users" />}
              value={influencerInfo?.useCount || '0'}
              title="عدد مستخدمى الكوبون"
            />
            <BoxStat
              value={influencerInfo?.startDate || '0'}
              title="تاريخ بداية الكوبون"
            />
            <BoxStat
              value={influencerInfo?.endDate || '0'}
              title="تاريخ نهاية الكوبون"
            />
            <BoxStat
              value={influencerInfo?.money_owed || '0'}
              title="الأموال المستحقه"
            />
            <BoxStat
              value={
                influencerInfo?.useCount
                  ? `${influencerInfo?.commission}%`
                  : '0'
              }
              title="عمولة المسوق"
            />
          </div>
        </div>
      </section>

      <div className="info-invoices-wrapper">
        <section className="profile-info-section">
          <h4>بيانات المسوق</h4>

          <div className="infos-wrapper">
            {influencerInfo?.subscription?.startDate && (
              <SingleInfo
                img={calenderImg}
                value={influencerInfo.subscription.startDate}
              />
            )}
            {influencerInfo?.subscription?.endDate && (
              <SingleInfo
                img={calenderImg}
                value={influencerInfo.subscription.endDate}
              />
            )}
            <SingleInfo img={mobileImg} value={influencerInfo?.phone || '-'} />
            <SingleInfo img={mailImg} value={influencerInfo?.email || '-'} />
            {influencerInfo?.facebook && (
              <SingleInfo
                img={imagesSrcs?.facebookColored}
                value={influencerInfo?.facebook || '-'}
              />
            )}
            {influencerInfo?.tiktok && (
              <SingleInfo
                img={imagesSrcs?.tiktokColored}
                value={influencerInfo?.tiktok || '-'}
              />
            )}
            {influencerInfo?.twitter && (
              <SingleInfo
                img={imagesSrcs?.twitterColored}
                value={influencerInfo?.twitter || '-'}
              />
            )}
            {influencerInfo?.youtube && (
              <SingleInfo
                img={imagesSrcs?.youtubeColored}
                value={influencerInfo?.youtube || '-'}
              />
            )}
          </div>
        </section>

        <div className="invoices-wrapper">
          <p className="sec-title">كوبونات المسوق</p>

          {fetchedSingleInfluencer?.invoices?.length > 0 ? (
            <div className="invoices-list-wrapper">
              {fetchedSingleInfluencer.invoices.map((invoice) => {
                return (
                  <div key={invoice.id} className="list-item-wrapper">
                    <div className="main-c">
                      <div className="img-wrap">
                        <img src={imagesSrcs.receiptIcon} alt="receipt" />
                      </div>

                      <div className="item-content">
                        <div className="label">
                          كوبون خصم #{' '}
                          {fetchedSingleInfluencer?.influencerInfo?.code}
                        </div>
                        {fetchedSingleInfluencer?.influencerInfo
                          ?.commission && (
                          <div className="price">
                            عمولة{' '}
                            {
                              fetchedSingleInfluencer?.influencerInfo
                                ?.commission
                            }
                            %
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="date">{invoice?.date}</div>

                    <button
                      className="invoice-deatils-btn"
                      onClick={() => {
                        dispatch(setSelectedInfluencerInvoice(invoice));
                        dispatch(setInfluencerInvoiceModalOpened(true));
                      }}
                    >
                      التفاصيل
                    </button>
                  </div>
                );
              })}

              <InfluencerInvoiceModal
                modalData={selectedInfluencerInvoice}
                parentInfo={fetchedSingleInfluencer?.influencerInfo}
                modalOpened={invoiceModalOpened}
                setModalOpened={(v) =>
                  dispatch(setInfluencerInvoiceModalOpened(v))
                }
              />
            </div>
          ) : (
            <CustomEmpty />
          )}
        </div>

        {/* <div className="note-wrap">
          <h4>ملاحضات مضافة</h4>
          {fetchedSingleInfluencer?.influencerInfo?.note}
        </div> */}
      </div>
    </div>
  );
};

export default InfluencerInfoSection;
