import { createAsyncThunk } from '@reduxjs/toolkit';
import getAllSectionTitlesApi from '../../apis/section-titles-apis/getAllSectionTitlesApi';
import getSingleSectionTitleApi from '../../apis/section-titles-apis/getSingleSectionTitleApi';
import updateSectionTitleApi from '../../apis/section-titles-apis/updateSectionTitleApi';
import customApiRequest from '../../helpers/customApiRequest';

// get all
export const getSectionTitlesAction = createAsyncThunk(
  'sectionTitles/getSectionTitlesAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getAllSectionTitlesApi(filterObj),
      thunkAPI
    });
  }
);

// get single
export const getSingleSectionTitlesAction = createAsyncThunk(
  'sectionTitles/getSingleSectionTitlesAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getSingleSectionTitleApi(filterObj),
      thunkAPI
    });
  }
);

// update title
export const updateSectionTitleAction = createAsyncThunk(
  'sectionTitles/updateSectionTitleAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await updateSectionTitleApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
//
