import { createAsyncThunk } from '@reduxjs/toolkit';
import createDeviceBrandApi from '../../apis/devices-brands-apis/createDeviceBrandApi';
import deleteDeviceBrandApi from '../../apis/devices-brands-apis/deleteDeviceBrandApi';
import getAllDevicesBrands from '../../apis/devices-brands-apis/getAllDevicesBrandsApi';
import customApiRequest from '../../helpers/customApiRequest';
// import checkRes from '../../utils/checkRes';
// import successNotification from '../../utils/successNotification';

export const getBrandsAction = createAsyncThunk(
  'brands/getBrandsAction',
  async (v, thunkAPI) => {
    const x = customApiRequest({
      apiCall: await getAllDevicesBrands(null),
      thunkAPI
    });
    return x;
  }
);
export const createBrandAction = createAsyncThunk(
  'brands/createBrandAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createDeviceBrandApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
export const editBrandAction = createAsyncThunk(
  'brands/updateBrandAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    
    return customApiRequest({
      apiCall: await createDeviceBrandApi(dataToBeSubmitted, true,dataToBeSubmitted.brand_id),
      thunkAPI
    });
  }
);

export const deleteBrandAction = createAsyncThunk(
  'brands/deleteBrandAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteDeviceBrandApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
