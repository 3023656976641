/* eslint-disable react-hooks/exhaustive-deps */
import './PaymentTypesPage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import { motion } from 'framer-motion';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import PaymentTypePageTable from './PaymentTypePageTable';
import { getPaymentTypesAction } from '../../redux/payment-types-slice/paymentTypesSliceThunkApi';
import PaymentTypeFormModal from './PaymentTypeFormModal';
import CreateNewBtn from '../../common/create-new-btn/CreateNewBtn';
import {
  setPostFormModalOpened,
  setSelectedTableRow
} from '../../redux/payment-types-slice/paymentTypesSlice';

const PaymentTypesPage = () => {
  const dispatch = useDispatch();
  const isLoadingTableData = useSelector(
    (store) => store.paymentTypes.isLoadingTableData
  );
  const fetchDataCount = useSelector(
    (store) => store.paymentTypes.fetchDataCount
  );
  const isSubmitting = useSelector((store) => store.paymentTypes.isSubmitting);
  const { search } = useLocation();
  const values = queryString.parse(search);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let isMounted = true;

  useEffect(() => {
    if (isMounted)
      dispatch(
        getPaymentTypesAction({
          filterObj: values
        })
      );

    return function () {
      isMounted = false;
    };
  }, [fetchDataCount, search]);

  if (isLoadingTableData) {
    return (
      <div className="payment-types-page">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 360
          }}
          className="loading-wrapper"
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      </div>
    );
  }
  return (
    <div className="payment-types-page">
      <div className="page-content">
        <CreateNewBtn
          onClick={() => {
            dispatch(setSelectedTableRow(null));
            dispatch(setPostFormModalOpened(true));
          }}
          btnText={'إضافة طريقة دفع جديدة'}
        />
        <div className="filter-stats-search-wrapper"></div>
        <motion.div
          // layout
          initial={{
            opacity: 0,
            translateX: 700,
            transition: {
              opacity: { ease: 'linear', duration: 0.3 },
              translateX: { ease: 'linear', duration: 0.5 }
            }
          }}
          animate={{
            opacity: 1,
            translateX: 0,
            transition: {
              delay: 0.7,
              opacity: { ease: 'linear', duration: 0.8 },
              translateX: { ease: 'linear', duration: 0.5 }
            }
          }}
          exit={{
            opacity: 0,
            translateX: 700,
            transition: {
              opacity: { ease: 'linear', duration: 0.5 },
              translateX: { ease: 'linear', duration: 0.5 }
            }
          }}
          className="page-main-content"
        >
          <div className="page-title">طرق الدفع</div>
          <PaymentTypePageTable />
        </motion.div>
      </div>
      <PaymentTypeFormModal />
      {isSubmitting && <LoadingModal />}
    </div>
  );
};

export default PaymentTypesPage;
