/* eslint-disable react-hooks/exhaustive-deps */
import {
  Document,
  Page,
  Text,
  Image,
  View,
  StyleSheet,
  PDFViewer,
  Font
} from '@react-pdf/renderer';
import { Modal } from 'antd';
import qrImg from '../../assets/imgs/qr.png';
// ../assets/fonts/Poppins-Regular.woff2) format('woff2'
// import fontSrc from '../../../assets/fonts/Cairo-Regular.ttf';
import CairoRegular from '../../assets/fonts/Cairo-Regular.ttf';
import CairoBold from '../../assets/fonts/Cairo-Bold.ttf';
import './ClientInvoiceModal.scss';
import { useState } from 'react';
import { useRef } from 'react';
import { useEffect } from 'react';
import QRCode from 'react-qr-code';
import PdfDocument from '../../components/blocks/PdfDocument';

Font.register({
  // family: 'PoppinsRegular',
  // src: fontSrc

  family: 'Cairo-Regular',
  fonts: [
    {
      src: CairoRegular,
      fontWeight: 'normal'
    },
    {
      src: CairoBold,
      fontWeight: 'bold'
    }
  ]
});

const ClientInvoiceModal = ({
  modalOpened,
  setModalOpened,
  modalData,
  parentInfo
}) => {
  const svgRef = useRef(null);
  const [pngImage, setPngImage] = useState('');

  return (
    <Modal
      wrapClassName="invoice-modal-wrapper"
      className="shared-custom-modal invoice-modal"
      width="96%"
      style={{
        maxWidth: '992px',
        top: '50%',
        left: '50%',
        height: '96vh',
        maxHeight: '1080px',
        margin: '0 auto'
      }}
      title=""
      open={modalOpened}
      // onOk={() => {
      //   setModalOpened(false);
      // }}
      onCancel={() => {
        if (setModalOpened) {
          setModalOpened(false);
        }
      }}
      footer={false}
    >
      {modalData && parentInfo && (
        <PdfDocument
          modalData={modalData}
          parentInfo={parentInfo}
          imageUrl={qrImg}
        />
      )}
      {modalData && parentInfo && (
        <div
          style={{
            opacity: '0',
            position: 'absolute',
            top: '50%',
            left: '0'
          }}
        >
          <QRCode
            ref={svgRef}
            value={`https://dashboard.mycash.sa/client_public/${parentInfo?.id}/${modalData?.id}`}
            style={{
              height: '200px',
              width: '100%'
            }}
          />
        </div>
      )}
    </Modal>
  );
};

export default ClientInvoiceModal;
