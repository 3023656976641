import deviceDescIcon from '../assets/imgs/icons/form-icons/device-desc-icon.svg';
import deviceIcon from '../assets/imgs/icons/form-icons/device-icon.svg';
import fontSizeIcon from '../assets/imgs/icons/form-icons/font-size-icon.svg';
import imageIcon from '../assets/imgs/icons/form-icons/image-icon.svg';
import packageIcon from '../assets/imgs/icons/form-icons/package-icon.svg';
import paperSizeIcon from '../assets/imgs/icons/form-icons/paper-size-icon.svg';
import priceIcon from '../assets/imgs/icons/form-icons/price-icon.svg';
import specsIcon from '../assets/imgs/icons/form-icons/specs-icon.svg';
import medalIcon from '../assets/imgs/icons/form-icons/medal-icon.svg';
import emailIcon from '../assets/imgs/icons/form-icons/email-icon.svg';
import phoneIcon from '../assets/imgs/icons/form-icons/phone-icon.svg';
import lockIcon from '../assets/imgs/icons/form-icons/lock-icon.svg';
import youtubeIcon from '../assets/imgs/icons/form-icons/youtube.svg';
import youtubeColored from '../assets/imgs/icons/form-icons/youtube-colored.svg';
import facebook from '../assets/imgs/icons/form-icons/facebook.svg';
import facebookColored from '../assets/imgs/icons/form-icons/facebook-colored.svg';
import instagram from '../assets/imgs/icons/form-icons/instagram.svg';
import twitter from '../assets/imgs/icons/form-icons/twitter.svg';
import twitterColored from '../assets/imgs/icons/form-icons/twitter-colored.svg';
import snapchat from '../assets/imgs/icons/form-icons/snapchat.svg';
import tiktok from '../assets/imgs/icons/form-icons/tiktok.svg';
import tiktokColored from '../assets/imgs/icons/form-icons/tiktok-colored.svg';
import linkImg from '../assets/imgs/icons/link.svg';
import bubble from '../assets/imgs/icons/form-icons/bubble.svg';
import copoun from '../assets/imgs/icons/form-icons/ticket-discount.svg';
import usersGroup from '../assets/imgs/icons/users-group.svg';
import products from '../assets/imgs/icons/products.svg';
import termsIcon from '../assets/imgs/icons/judge.svg';
import privacyIcon from '../assets/imgs/icons/privacy.svg';
import facesIcon from '../assets/imgs/icons/smileys.svg';
import userTagIcon from '../assets/imgs/icons/user-tag.svg';
import receiptIcon from '../assets/imgs/icons/receipt.svg';
import editIcon from '../assets/imgs/icons/edit-icon.svg';
import userIcon from '../assets/imgs/icons/user.svg';
import percentIcon from '../assets/imgs/icons/form-icons/percentage-square.svg';
import rotateRight from '../assets/imgs/icons/form-icons/rotate-right.svg';
import arrowLeft from '../assets/imgs/icons/form-icons/arrow-left.svg';

const imagesSrcs = {
  deviceDescIcon,
  deviceIcon,
  fontSizeIcon,
  imageIcon,
  packageIcon,
  paperSizeIcon,
  priceIcon,
  specsIcon,
  medalIcon,
  emailIcon,
  phoneIcon,
  lockIcon,
  youtubeIcon,
  youtubeColored,
  facebook,
  facebookColored,
  instagram,
  twitter,
  twitterColored,
  snapchat,
  tiktok,
  tiktokColored,
  linkImg,
  bubble,
  copoun,
  usersGroup,
  products,
  termsIcon,
  privacyIcon,
  facesIcon,
  userTagIcon,
  receiptIcon,
  editIcon,
  userIcon,
  percentIcon,
  rotateRight,
  arrowLeft
};

export default imagesSrcs;
