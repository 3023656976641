/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import FileInput from '../../common/file-input/FileInput';
import './WebsiteSettingsForm.scss';
import checkRes from '../../utils/checkRes';
import { useTranslation } from 'react-i18next';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import getGeneralSettingsApi from '../../apis/general/generalSettingsApi';
import { LoadingOutlined } from '@ant-design/icons';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import updateGeneralSettingsApi from '../../apis/general/updateGeneralSettingsApi';

const imgsFormats = ['image/jpg', 'image/jpeg', 'image/png'];
const videosFormats = ['video/mp4', 'image/jpeg', 'image/png'];
const SUPPORTED_FORMATS = {
  pdf: ['application/pdf'],
  images: imgsFormats,
  videos: videosFormats,
  videoImgs: [...imgsFormats, ...videosFormats]
};

const FILE_SIZE = 5 * 1024 * 1024;

const schema = Yup.object().shape({
  video: Yup.mixed()
    .test('fileFormat', 'صيغة الملف غير مدعومة', (value) => {
      let result = true;
      if (value?.length > 0) {
        if (
          value[0] &&
          // typeof value.arrayBuffer === "function" &&
          !SUPPORTED_FORMATS.videoImgs.includes(value[0].type)
        ) {
          result = false;
        }
      }
      return result;
    })
    .test('fileSize', 'حجم الملف كبير', (value) => {
      let result = true;
      if (value?.length > 0) {
        if (value[0] && value[0].size > FILE_SIZE) {
          result = false;
        }
      }
      return result;
    })
});

const { medalIcon } = imagesSrcs;

const emptyFormValues = {
  facebook: '',
  twitter: '',
  instagram: '',
  tiktok: '',
  snap: '',
  youtube: '',
  address_ar: '',
  address_en: '',
  footer_ar: '',
  footer_en: '',
  homeTitle_ar: '',
  homeTitle_en: '',
  homeSubTitle_ar: '',
  homeSubTitle_en: '',
  appStore: '',
  googlePlay: '',
  phone: '',
  video: null
};

const WebsiteSettingsForm = () => {
  const [files, setFiles] = useState(null);

  const { i18n } = useTranslation();
  const customApiRequest = useCustomApiRequest();
  const [isLoading, setIsLoading] = useState(0);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [fetchCount, setFetchCount] = useState(0);
  const [fetchedSettings, setFetchedSettings] = useState(null);
  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    customApiRequest(
      getGeneralSettingsApi(i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setFetchedSettings(res.data.data);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchCount]);

  // handle initial values
  useEffect(() => {
    if (fetchedSettings) {
      setValue('facebook', fetchedSettings?.facebook || '');
      setValue('twitter', fetchedSettings?.twitter || '');
      setValue('instagram', fetchedSettings?.instagram || '');
      setValue('tiktok', fetchedSettings?.tiktok || '');
      setValue('snap', fetchedSettings?.snap || '');
      setValue('youtube', fetchedSettings?.youtube || '');
      setValue('address_ar', fetchedSettings?.address_ar || '');
      setValue('address_en', fetchedSettings?.address_en || '');
      setValue('footer_ar', fetchedSettings?.footer_ar || '');
      setValue('footer_en', fetchedSettings?.footer_en || '');
      setValue('homeTitle_ar', fetchedSettings?.homeTitle_ar || '');
      setValue('homeTitle_en', fetchedSettings?.homeTitle_en || '');
      setValue('homeSubTitle_ar', fetchedSettings?.homeSubTitle_ar || '');
      setValue('homeSubTitle_en', fetchedSettings?.homeSubTitle_en || '');
      setValue('appStore', fetchedSettings?.appStore || '');
      setValue('phone', fetchedSettings?.phone || '');
      setValue('googlePlay', fetchedSettings?.googlePlay || '');
    }
  }, [fetchedSettings]);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    unregister,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: emptyFormValues
  });

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('facebook', data.facebook || '');
    formData.append('twitter', data.twitter || '');
    formData.append('instagram', data.instagram || '');
    formData.append('tiktok', data.tiktok || '');
    formData.append('snap', data.snap || '');
    formData.append('youtube', data.youtube || '');
    formData.append('address_ar', data.address_ar || '');
    formData.append('address_en', data.address_en || '');
    formData.append('footer_ar', data.footer_ar || '');
    formData.append('footer_en', data.footer_en || '');
    formData.append('homeTitle_ar', data.homeTitle_ar || '');
    formData.append('homeTitle_en', data.homeTitle_en || '');
    formData.append('homeSubTitle_ar', data.homeSubTitle_ar || '');
    formData.append('homeSubTitle_en', data.homeSubTitle_en || '');
    formData.append('appStore', data.appStore || '');
    formData.append('googlePlay', data.googlePlay || '');
    formData.append('phone', data.phone || '');
    if (data?.video?.length > 0) {
      formData.append('video', data.video[0]);
    }

    customApiRequest(
      updateGeneralSettingsApi(formData),
      (res) => {
        setIsSubmitting(false);
        window.scroll(0, 0);
        // if (checkRes(res)) {
        //   setFetchCount((prev) => prev + 1);
        // }
      },
      (error) => {
        setIsSubmitting(false);
      }
    );
  };

  const [form] = Form.useForm();

  if (isLoading) {
    return (
      <div className="settings-page">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 360
          }}
          className="loading-wrapper"
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      </div>
    );
  }

  return (
    <Form
      className="custom-shared-form website-service-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>فيسبوك</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="facebook"
            type="text"
            placeholder={'فيسبوك...'}
            // label="الاســــم"
            errorMsg={errors?.facebook?.message}
            validateStatus={errors?.facebook ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>تويتر</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="twitter"
            type="text"
            placeholder={'تويتر...'}
            // label="الاســــم"
            errorMsg={errors?.twitter?.message}
            validateStatus={errors?.twitter ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>انستجرام</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="instagram"
            type="text"
            placeholder={'انستجرام...'}
            // label="الاســــم"
            errorMsg={errors?.instagram?.message}
            validateStatus={errors?.instagram ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>تيك توك</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="tiktok"
            type="text"
            placeholder={'تيك توك...'}
            // label="الاســــم"
            errorMsg={errors?.tiktok?.message}
            validateStatus={errors?.tiktok ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>سناب شات</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="snap"
            type="text"
            placeholder={'سناب شات...'}
            // label="الاســــم"
            errorMsg={errors?.snap?.message}
            validateStatus={errors?.snap ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>يوتيوب</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="youtube"
            type="text"
            placeholder={'يوتيوب...'}
            // label="الاســــم"
            errorMsg={errors?.youtube?.message}
            validateStatus={errors?.youtube ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان بالعربية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="address_ar"
            type="text"
            placeholder={'العنوان بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.address_ar?.message}
            validateStatus={errors?.address_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان بالانجليزية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="address_en"
            type="text"
            placeholder={'العنوان بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.address_en?.message}
            validateStatus={errors?.address_en ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>تزييل الموقع بالعربية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="footer_ar"
            type="text"
            placeholder={'تزييل الموقع بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.footer_ar?.message}
            validateStatus={errors?.footer_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>تزييل الموقع بالانجليزية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="footer_en"
            type="text"
            placeholder={'تزييل الموقع بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.footer_en?.message}
            validateStatus={errors?.footer_en ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان الرئيسى بالعربية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="homeTitle_ar"
            type="text"
            placeholder={'العنوان الرئيسى بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.homeTitle_ar?.message}
            validateStatus={errors?.homeTitle_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان الرئيسى بالانجليزية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="homeTitle_en"
            type="text"
            placeholder={'العنوان الرئيسى بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.homeTitle_en?.message}
            validateStatus={errors?.homeTitle_en ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان الفرعى بالعربية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="homeSubTitle_ar"
            type="text"
            placeholder={'العنوان الفرعى بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.homeSubTitle_ar?.message}
            validateStatus={errors?.homeSubTitle_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان الفرعى بالانجليزية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="homeSubTitle_en"
            type="text"
            placeholder={'العنوان الفرعى بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.homeSubTitle_en?.message}
            validateStatus={errors?.homeSubTitle_en ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>لينك التطبيق على متجر جوجل</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="googlePlay"
            type="text"
            placeholder={'لينك التطبيق على متجر جوجل...'}
            // label="الاســــم"
            errorMsg={errors?.googlePlay?.message}
            validateStatus={errors?.googlePlay ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>لينك التطبيق على متجر أبل</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="appStore"
            type="text"
            placeholder={'لينك التطبيق على متجر أبل...'}
            // label="الاســــم"
            errorMsg={errors?.appStore?.message}
            validateStatus={errors?.appStore ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>رقم الهاتف</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="phone"
            type="text"
            placeholder={'رقم الهاتف...'}
            // label="الاســــم"
            errorMsg={errors?.phone?.message}
            validateStatus={errors?.phone ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="file-inputs-wrap">
          <FileInput
            name="video"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label={
              <p
                className="outside-label"
                style={{
                  color: '#404041',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  marginBottom: 8,
                  paddingRight: 8
                }}
              >
                <img src={imagesSrcs?.imageIcon} alt="name" />
                الفيديو
              </p>
            }
            accept="image/*, video/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الفيديو وضعه هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              fetchedSettings?.video ? [{ url: fetchedSettings.video }] : []
            }
            canDelete={false}
            showError={errors?.video}
            errorMsg={errors?.video?.message}
            files={files}
            setFiles={setFiles}
          />
        </div>

        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            type="button"
            onClick={() => {
              reset({
                facebook: fetchedSettings?.facebook || '',
                twitter: fetchedSettings?.twitter || '',
                instagram: fetchedSettings?.instagram || '',
                tiktok: fetchedSettings?.tiktok || '',
                snap: fetchedSettings?.snap || '',
                youtube: fetchedSettings?.youtube || '',
                address_ar: fetchedSettings?.address_ar || '',
                address_en: fetchedSettings?.address_en || '',
                footer_ar: fetchedSettings?.footer_ar || '',
                footer_en: fetchedSettings?.footer_en || '',
                homeTitle_ar: fetchedSettings?.homeTitle_ar || '',
                homeTitle_en: fetchedSettings?.homeTitle_en || '',
                homeSubTitle_ar: fetchedSettings?.homeSubTitle_ar || '',
                homeSubTitle_en: fetchedSettings?.homeSubTitle_en || ''
              });
              setFiles(null);
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {isSubmitting && <LoadingModal />}
    </Form>
  );
};

export default WebsiteSettingsForm;
