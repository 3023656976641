/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import {
  deleteWebsiteContactAction,
  getWebsiteContactsAction
} from '../../redux/website-contacts-slice/websiteContactsSliceThunkApi';
import CustomLoader from '../../common/custom-loader/CustomLoader';
import CustomEmpty from '../../common/custom-empty/CustomEmpty';
import './WebsiteContactsPage.scss';
import trimWords from '../../utils/trimWords';
import {
  setDeleteModalOpened,
  setSelectedTableRow
} from '../../redux/website-contacts-slice/websiteContactsSlice';
import ConfirmModal from '../../common/confirm-modal/ConfirmModal';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';
import DeleteIcon from '../../common/icons/DeleteIcon';
import Xicon from '../../common/icons/Xicon';
import { useTranslation } from 'react-i18next';

const WebsiteContactsPage = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoadingTableData = useSelector(
    (store) => store.websiteContacts.isLoadingTableData
  );
  const isSubmitting = useSelector(
    (store) => store.websiteContacts.isSubmitting
  );
  const selectedTableRow = useSelector(
    (store) => store.websiteContacts.selectedTableRow
  );
  const deleteModalOpened = useSelector(
    (store) => store.websiteContacts.deleteModalOpened
  );
  const fetchedTableData = useSelector(
    (store) => store.websiteContacts.fetchedTableData
  );
  const fetchDataCount = useSelector(
    (store) => store.websiteContacts.fetchDataCount
  );

  const handleDeleteContact = () => {
    if (isMounted && selectedTableRow?.id) {
      const formData = new FormData();
      formData.append('id', selectedTableRow.id);
      dispatch(
        deleteWebsiteContactAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  let isMounted = true;
  useEffect(() => {
    if (isMounted)
      dispatch(
        getWebsiteContactsAction({
          filterObj: null
        })
      );

    return function () {
      isMounted = false;
    };
  }, [fetchDataCount]);

  const SelectedItem = () => {
    return (
      <div className="selected-item">
        <p className="view-p">عرض الرسالة</p>
        <div className="email-header">
          <div className="time">
            {/* <p className="date-p">{t('time')} :</p> */}
            <p className="date-p">
              {selectedTableRow?.time} - {selectedTableRow?.date}
            </p>
          </div>
          <div className="initials">
            {selectedTableRow?.name
              ? selectedTableRow?.name[0].toUpperCase()
              : '?'}
          </div>

          <div className="name-email">
            <p className="name-p">{selectedTableRow?.name}</p>
            <p className="name-email">{selectedTableRow?.email}</p>
            {selectedTableRow?.phone && (
              <p className="email-p">{selectedTableRow.phone}</p>
            )}
          </div>
        </div>
        <div className="email-body">{selectedTableRow?.message}</div>
      </div>
    );
  };

  const renderPageContent = () => {
    if (isLoadingTableData) {
      return (
        <div
          style={{
            minHeight: 400,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}
        >
          <CustomLoader />
        </div>
      );
    } else if (fetchedTableData?.length === 0) {
      return (
        <CustomEmpty>
          <p>لا توجد رسائل</p>
        </CustomEmpty>
      );
    } else if (fetchedTableData?.length > 0) {
      return (
        <div className="page-list-selected-wrap">
          <div className="page-list">
            {fetchedTableData.map((item) => {
              return (
                <div
                  key={item.id}
                  onClick={() => {
                    dispatch(setSelectedTableRow(item));
                  }}
                  type="button"
                  className={`contact-card ${
                    selectedTableRow?.id === item?.id ? 'selected' : ''
                  }`}
                >
                  <div className="initials">
                    {item?.name ? item?.name[0].toUpperCase() : '?'}
                  </div>

                  <div className="name-email-desc-wrapper">
                    <p className="name-p">{item.name}</p>
                    <p className="email-p">{item?.email}</p>
                    {item?.phone && <p className="email-p">{item.phone}</p>}
                    {item?.message ? `${trimWords(item.message, 12)}...` : null}
                    <div
                      style={{
                        display: 'flex',
                        gap: '.5rem',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <p className="date-p">{t('time')} :</p>
                      <p className="date-p">
                        {item?.time} - {item?.date}
                      </p>
                    </div>
                  </div>

                  <button
                    onClick={() => {
                      dispatch(setDeleteModalOpened(true));
                    }}
                    className="remove-btn"
                  >
                    <Xicon color="#E72929" />
                  </button>
                </div>
              );
            })}
          </div>

          {selectedTableRow && <SelectedItem />}
        </div>
      );
    }

    return null;
  };

  return (
    <div className="website-contacts-page">
      <p className="website-title">الموقع الالكترونى</p>
      <p className="page-title">المساعدة</p>

      <div className="page-content">{renderPageContent()}</div>

      {/* delete contact modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteModalOpened}
        modalType={confirmModalTypesValues?.errorModal}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedTableRow(null));
          }
          dispatch(setDeleteModalOpened(v));
        }}
        handleAccept={handleDeleteContact}
      >
        <h3>{`هل انت متأكد من حذف الرسالة #${selectedTableRow?.id || ''}`}</h3>
      </ConfirmModal>
      {/* end delete contact modal */}

      {isSubmitting && <LoadingModal />}
    </div>
  );
};

export default WebsiteContactsPage;
