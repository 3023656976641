import React, { useEffect, useRef, useState } from 'react';
import QRCode from 'react-qr-code';
import { useParams } from 'react-router-dom';
import './InvoiceNoClient.scss';
import axios from 'axios';
import InvoicePdf from '../../blocks/InvoicePdf';

const InvoiceNoClient = () => {
  const { invoice_id } = useParams();
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  const handleGetData = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `https://mycashbackend.fatoorah.online/api/General/single_invoice?invoice_id=${invoice_id}`,
        {}
      );
      if (res?.data?.data) {
        console.log(res?.data?.data);
        setData(res?.data?.data);
      } else {
        setData(res?.data);
      }
      setLoading(false);
    } catch (error) {
      setError(error?.response?.data?.message);
      setLoading(false);
    }
  };
  useEffect(() => {
    handleGetData();
  }, []);

  const svgRef = useRef(null);
  const [pngImage, setPngImage] = useState('');
  useEffect(() => {
    const svgElement = svgRef.current;

    if (svgElement) {
      // Create a new Image object
      const img = new Image();

      // Set the source of the Image to the SVG content
      img.src = `data:image/svg+xml;base64,${btoa(
        new XMLSerializer().serializeToString(svgElement)
      )}`;

      // Wait for the Image to load
      img.onload = () => {
        // Create a canvas element
        const canvas = document.createElement('canvas');
        canvas.width = svgElement.width.baseVal.value;
        canvas.height = svgElement.height.baseVal.value;

        // Draw the SVG on the canvas
        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0);

        // Convert the canvas to a data URL with PNG format
        const pngUrl = canvas.toDataURL('image/png');

        // Set the PNG data URL as the state
        setPngImage(pngUrl);
      };
    }
  }, [data]);

  const zodicRef = useRef(null);
  const [zodicImage, setZodicImage] = useState('');

  useEffect(() => {
    const svgElement = zodicRef.current;

    if (svgElement) {
      // Create a new Image object
      const img = new Image();

      // Set the source of the Image to the SVG content
      img.src = `data:image/svg+xml;base64,${btoa(
        new XMLSerializer().serializeToString(svgElement)
      )}`;

      // Wait for the Image to load
      img.onload = () => {
        // Create a canvas element
        const canvas = document.createElement('canvas');
        canvas.width = svgElement.width.baseVal.value;
        canvas.height = svgElement.height.baseVal.value;

        // Draw the SVG on the canvas
        const context = canvas.getContext('2d');
        context.drawImage(img, 0, 0);

        // Convert the canvas to a data URL with PNG format
        const pngUrl = canvas.toDataURL('image/png');

        // Set the PNG data URL as the state
        setZodicImage(pngUrl);
      };
    }
  }, [data]);

  if (loading) return <div>Loading...</div>;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        height: '100vh',
        width: '100vw',
        padding: '20px'
      }}
    >
      {data && pngImage && zodicImage && (
        <InvoicePdf data={data} imageUrl={pngImage} zakatQr={zodicImage} />
      )}
      <div
        style={{
          opacity: '0',
          position: 'absolute',
          top: '50%',
          left: '0'
        }}
      >
        <QRCode
          ref={svgRef}
          value={`https://dashboard.mycash.sa/invoice_public/${invoice_id}`}
          style={{
            height: '200px',
            width: '100%'
          }}
        />
        {data?.zakatQr && (
          <QRCode
            ref={zodicRef}
            value={data?.zakatQr}
            style={{
              height: '200px',
              width: '100%'
            }}
          />
        )}
      </div>
    </div>
  );
};

export default InvoiceNoClient;
