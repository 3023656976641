import { useController } from 'react-hook-form';
import { Form, Checkbox } from 'antd';

const AntdCheckbox = ({
  control,
  name,
  label,
  validateStatus,
  errorMsg,
  style,
  className
}) => {
  const {
    // field: { ...inputProps }
    field
  } = useController({
    name,
    control
  });

  return (
    <Form.Item
      className={className}
      style={style}
      help={errorMsg}
      validateStatus={validateStatus}
      colon={false}
    >
      <Checkbox {...field} checked={field.value}>
        {label}
      </Checkbox>
    </Form.Item>
  );
};

export default AntdCheckbox;
