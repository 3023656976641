import axios from 'axios';
import apisUrls from '../urls';

export const createWebsitePartnerApi = (values, edit = false) => {
  const url = !edit
    ? apisUrls?.createWebsitePartnerUrl
    : apisUrls?.updateWebsitePartnerUrl;

  return axios.post(url, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const deleteWebsitePartnerApi = (values) => {
  return axios.post(apisUrls?.deleteWebsitePartnerUrl, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export const getAllWebsitePartnersApi = (filterObj) => {
  return axios.get(apisUrls?.getWebsitePartnersUrl(filterObj));
};

export const getSingleWebsitePartnerApi = (filterObj) => {
  return axios.get(apisUrls?.getSingleWebsitePartnerUrl(filterObj));
};
