/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import { LoadingOutlined } from '@ant-design/icons';
import { Switch, Tag } from 'antd';
import { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import ConfirmModal from '../../common/confirm-modal/ConfirmModal';
import CustomEmpty from '../../common/custom-empty/CustomEmpty';
import CustomImage from '../../common/custom-image/CustomImage';
import EditIcon from '../../common/icons/EditIcon';
import TrashIcon from '../../common/icons/TrashIcon';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import sharedRouterLinks from '../../components/app/shared-routes/sharedRouterLinks';
import TitleDescForm from '../../components/title-desc-form/TitleDescForm';
import {
  brandsStatus,
  getBrandsStatusArr
} from '../../const-values/brandsStatus';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';
import getFoundObjByValue from '../../helpers/getFoundObjByValue';
import { setFetchedSingleTitle } from '../../redux/section-titles-slice/SectionTitlesSlice';
import { getSingleSectionTitlesAction } from '../../redux/section-titles-slice/SectionTitlesSliceThunkApi';
import {
  changeWebsiteServiceStatusAction,
  deleteWebsiteServiceAction,
  getWebsiteServicesAction,
  getWebsiteServicesFeaturesAction
} from '../../redux/website-services-slice/websiteServiceSliceThunkApi';
import {
  setDeleteModalOpened,
  setSelectedTableRow
} from '../../redux/website-services-slice/websiteServicesSlice';
import WebsiteServiceForm from './WebsiteServiceForm';
import './WebsiteServicesPage.scss';

const WebsiteServicesPage = () => {
  const dispatch = useDispatch();
  const deleteModalOpened = useSelector(
    (store) => store.websiteServices.deleteModalOpened
  );
  const isLoadingTableData = useSelector(
    (store) => store.websiteServices.isLoadingTableData
  );
  const selectedTableRow = useSelector(
    (store) => store.websiteServices.selectedTableRow
  );
  const fetchDataCount = useSelector(
    (store) => store.websiteServices.fetchDataCount
  );
  const isSubmitting = useSelector(
    (store) => store.websiteServices.isSubmitting
  );
  const fetchedTableData = useSelector(
    (store) => store.websiteServices.fetchedTableData
  );
  const fetchSingleTitleCount = useSelector(
    (store) => store.sectionTitles.fetchSingleCount
  );
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  console.log(pathname);

  let isMounted = true;

  useEffect(() => {
    if (isMounted) {
      if (pathname === sharedRouterLinks?.websitesServicesFeatruesRoute)
        dispatch(
          getSingleSectionTitlesAction({
            filterObj: {
              id: 5
            }
          })
        );
      else
        dispatch(
          getSingleSectionTitlesAction({
            filterObj: {
              id: 1
            }
          })
        );
    }

    return () => dispatch(setFetchedSingleTitle(null));
  }, [fetchSingleTitleCount]);

  useEffect(() => {
    if (isMounted)
      if (pathname === sharedRouterLinks?.websitesServicesFeatruesRoute)
        dispatch(
          getWebsiteServicesFeaturesAction({
            filterObj: null
          })
        );
      else
        dispatch(
          getWebsiteServicesAction({
            filterObj: null
          })
        );

    return function () {
      isMounted = false;
    };
  }, [fetchDataCount, pathname]);

  const handleDeleteObj = () => {
    if (isMounted && selectedTableRow?.id) {
      const formData = new FormData();
      formData.append('id', selectedTableRow.id);
      dispatch(
        deleteWebsiteServiceAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  const handleToggleStatus = (id) => {
    if (id) {
      const formData = new FormData();
      formData.append('id', id);
      dispatch(
        changeWebsiteServiceStatusAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  const ServiceItem = (card) => {
    return (
      <div className="item-item">
        <CustomImage src={card?.logo} className="item-img-wrapper" />
        <div className="item-content">
          <div
            className="item-details"
            style={{
              display: 'flex',
              gap: 4,
              alignItems: 'center'
            }}
          >
            <p className="name">{card?.name}</p>
          </div>
          <div className="item-btns-wrapper">
            <button
              onClick={() => {
                dispatch(setSelectedTableRow(card));
                dispatch(setDeleteModalOpened(true));
              }}
              style={{
                padding: 0
              }}
              className="btn delete-btn"
            >
              <TrashIcon color="rgba(64, 64, 65, 0.6)" />
              حذف
            </button>
            <button
              style={{
                padding: 0
              }}
              className="btn edit-btn"
              onClick={() => {
                dispatch(setSelectedTableRow(card));
              }}
            >
              <EditIcon color="rgba(64, 64, 65, 0.6)" />
              تعديل
            </button>

            <Switch
              defaultChecked={card?.status == brandsStatus?.active}
              onChange={(v) => {
                handleToggleStatus(card?.id);
              }}
              className={`affiliate-toggle ${
                card?.status == brandsStatus?.notActive ? 'not-active' : ''
              }`}
            />

            {getFoundObjByValue(getBrandsStatusArr(), card?.status) && (
              <Tag
                color={card?.status === brandsStatus?.active ? 'green' : 'red'}
              >
                {getFoundObjByValue(getBrandsStatusArr(), card?.status).name}
              </Tag>
            )}
          </div>
        </div>
      </div>
    );
  };

  const renderServicesList = useMemo(() => {
    return () => {
      if (isLoadingTableData) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 360
            }}
          >
            <LoadingOutlined />
          </div>
        );
      } else if (fetchedTableData?.length === 0) {
        return (
          <CustomEmpty
            style={{
              minHeight: 642
            }}
          >
            <h3
              style={{
                color: '#40404166',
                marginTop: 18
              }}
            >
              لم تقم بإضافة خدمات بعد
            </h3>
          </CustomEmpty>
        );
      } else if (fetchedTableData?.length > 0) {
        return (
          <div className="sec-list-wrapper">
            {fetchedTableData.map((item) => {
              return <ServiceItem key={item.id} {...item} />;
            })}
          </div>
        );
      }
      return null;
    };
  }, [fetchedTableData, isLoadingTableData]);

  return (
    <div className="website-page website-services-page">
      <p className="website-title">الموقع الالكترونى</p>
      <p className="page-title">خدماتنا</p>
      <div className="form-list-wrapper">
        <div className="forms-section">
          <TitleDescForm />
          <WebsiteServiceForm fetchedTableData={fetchedTableData} />
        </div>

        <div className="desc-sec">
          <div className="desc-header">الخدمات</div>
          {renderServicesList()}
        </div>
      </div>

      {/* delete service modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteModalOpened}
        modalType={confirmModalTypesValues?.errorModal}
        setModalOpened={(v) => dispatch(setDeleteModalOpened(v))}
        handleAccept={handleDeleteObj}
      >
        <h3>{`هل انت متأكد من الحذف ${selectedTableRow?.name || ''}`}</h3>
      </ConfirmModal>
      {/* end delete service modal */}

      {(isSubmitting || isLoadingTableData) && <LoadingModal />}
    </div>
  );
};

export default WebsiteServicesPage;
