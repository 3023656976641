/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect } from 'react';
import InfluencerInfoSection from './InfluencerInfoSection';
import queryString from 'query-string';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import { LoadingOutlined } from '@ant-design/icons';
import './SingleInfluencerPage.scss';
import CustomEmpty from '../../common/custom-empty/CustomEmpty';
import imagesSrcs from '../../helpers/imagesSrcs';

import {
  setInfluencerInvoiceModalOpened,
  setSelectedInfluencerInvoice
} from '../../redux/influcencers-slice/influencersSlice';
import InfluencerInvoiceModal from './InfluencerInvoiceModal';
import { getSingleInfluencerAction } from '../../redux/influcencers-slice/influencersSliceThunkApi';

const SingleInfluencerPage = () => {
  const { search } = useLocation();
  const values = queryString.parse(search);
  const params = useParams();
  const fetchDataCount = useSelector(
    (store) => store.influencers.fetchDataCount
  );
  const isLoadingTableData = useSelector(
    (store) => store.influencers.isLoadingTableData
  );
  const selectedInfluencerInvoice = useSelector(
    (store) => store.influencers.selectedInfluencerInvoice
  );
  const fetchedSingleInfluencer = useSelector(
    (store) => store.influencers.fetchedSingleInfluencer
  );
  const invoiceModalOpened = useSelector(
    (store) => store.influencers.invoiceModalOpened
  );
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted)
      dispatch(
        getSingleInfluencerAction({
          filterObj: {
            influencer_id: params?.influencer_id || '',
            ...values
          }
        })
      );

    return function () {
      isMounted = false;
    };
  }, [fetchDataCount, search]);

  if (isLoadingTableData) {
    return (
      <div className="single-influencer-page">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 360
          }}
          className="loading-wrapper"
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      </div>
    );
  } else if (!fetchedSingleInfluencer) {
    return (
      <div className="single-influencer-page">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 360
          }}
          className="loading-wrapper"
        >
          <h2>لا توجد بيانات متاحة</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="single-influencer-page">
      <InfluencerInfoSection
        selectedInfluencerInvoice={selectedInfluencerInvoice}
        fetchedSingleInfluencer={fetchedSingleInfluencer}
        invoiceModalOpened={invoiceModalOpened}
      />
    </div>
  );
};

export default SingleInfluencerPage;
