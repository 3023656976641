/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import './InfluencerForm.scss';
import {
  createInfluencerAction,
  updateInfluencerAction
} from '../../redux/influcencers-slice/influencersSliceThunkApi';
import { setPostFormModalOpened } from '../../redux/influcencers-slice/influencersSlice';
import FileInput from '../../common/file-input/FileInput';
import CustomAntdDatePicker from '../../common/antd-form-components/CustomAntdDatePicker';
import ArrowToLeftIcon from '../../common/icons/ArrowToLeftIcon';
import moment from 'moment';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import NotesIcon from '../../common/icons/NotesIcon';
import { customAlphabet, nanoid } from 'nanoid';
import { useState } from 'react';
import { getInfluencerStatusArr } from '../../const-values/influencerStatus.js';

const schema = Yup.object().shape({
  name: Yup.string().required('من فضلك ادخل اسم المسوق'),
  phone: Yup.string()
    .required('من فضلك ادخل رقم هاتف المسوق')
    .matches(/^[0-9]+$/, 'رقم هاتف خاطئ')
    .min(10, 'حد أدنى 10 ارقام'),
  email: Yup.string()
    .required('من فضلك ادخل البريد الالكترونى')
    .email('بريد الكترونى خاطئ'),
  code: Yup.string().required('من فضلك ادخل كود الخصم'),
  discount: Yup.string()
    .required('من فضلك ادخل نسبة الخصم')
    .matches(/^(\d+)?(\.\d+)?$/, 'ادخل الخصم على هيئة ارقام')
    .test('discount', 'خصم غير صحيح', (v, context) => {
      let result = true;
      if (!v || (v && (parseFloat(v) < 0 || parseFloat(v) >= 100))) {
        result = false;
      }
      return result;
    }),
  commission: Yup.string()
    .required('من فضلك ادخل عمولة المسوق')
    .matches(/^(\d+)?(\.\d+)?$/, 'ادخل العمولة')
    .test('discount', 'عمولة غير صحيحة', (v, context) => {
      let result = true;
      if (!v || (v && (parseFloat(v) <= 0 || parseFloat(v) >= 100))) {
        result = false;
      }
      return result;
    }),
  startDate: Yup.mixed().required('من فضلك اختار تاريخ بداية الخصم'),
  endDate: Yup.mixed().required('من فضلك اختار تاريخ نهاية الخصم'),
  days: Yup.string()
    .required('من فضلك ادخل مدة الخصم')
    .matches(/^[0-9]+$/, 'ادخل المدة على هيئة ارقام')
    .test('days', 'مدة خاطئة', (v, context) => {
      let result = true;
      if (!v || parseFloat(v) <= 0) result = false;
      return result;
    }),

  youtube: Yup.string().test('youtube', 'رابط غير صحيح', (v, context) => {
    let result = true;
    if (
      v &&
      !v.match(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
      )
    )
      result = false;
    return result;
  }),
  instagram: Yup.string().test('instagram', 'رابط غير صحيح', (v, context) => {
    let result = true;
    if (
      v &&
      !v.match(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
      )
    )
      result = false;
    return result;
  }),
  twitter: Yup.string().test('twitter', 'رابط غير صحيح', (v, context) => {
    let result = true;
    if (
      v &&
      !v.match(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
      )
    )
      result = false;
    return result;
  }),
  facebook: Yup.string().test('facebook', 'رابط غير صحيح', (v, context) => {
    let result = true;
    if (
      v &&
      !v.match(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
      )
    )
      result = false;
    return result;
  }),
  snapchat: Yup.string().test('snapchat', 'رابط غير صحيح', (v, context) => {
    let result = true;
    if (
      v &&
      !v.match(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
      )
    )
      result = false;
    return result;
  }),
  tiktok: Yup.string().test('tiktok', 'رابط غير صحيح', (v, context) => {
    let result = true;
    if (
      v &&
      !v.match(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/
      )
    )
      result = false;
    return result;
  })
});

const { medalIcon, emailIcon, phoneIcon, imageIcon,fontSizeIcon } = imagesSrcs;

const emptyFormValues = {
  name: '',
  phone: '',
  email: '',
  code: '',
  discount: '',
  startDate: '', 
   endDate: '',
  days: '',
  commission: '',
  note: '',
  socials: [],
  youtube: '',
  instagram: '',
  twitter: '',
  facebook: '',
  snapchat: '',
  tiktok: '',
  country_id:"1",
  status:"1"
};

const InfluencerForm = () => {
  const [files, setFiles] = useState(null);
  const dispatch = useDispatch();
  const fetchedSocials = useSelector(
    (store) => store.influencers.fetchedSocials
  );
  const selectedTableRow = useSelector(
    (store) => store.influencers.selectedTableRow
  );
  ///////////////
  // brands selectors
  const fetchedPackages = useSelector(
    (store) => store.influencers.fetchedTableData
  );
  const fetchedCountries = useSelector(
    (store) => store.devices.fetchedCountries
  );
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    register,
    unregister,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: emptyFormValues
  });

  useEffect(() => {
    moment.locale('en');
  }, []);
  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      setValue('name', selectedTableRow?.name || '');
      setValue('phone', selectedTableRow?.phone || '');
      setValue('email', selectedTableRow?.email || '');
      setValue('code', selectedTableRow?.code || '');
      setValue('discount', selectedTableRow?.discount || '');
      setValue('days', selectedTableRow?.days || '');
      setValue('commission', selectedTableRow?.commission || '');
      setValue('note', selectedTableRow?.note || '');
      setValue('youtube', selectedTableRow?.youtube || '');
      setValue('instagram', selectedTableRow?.instagram || '');
      setValue('twitter', selectedTableRow?.twitter || '');
      setValue('facebook', selectedTableRow?.facebook || '');
      setValue('snapchat', selectedTableRow?.snapchat || '');
      setValue('tiktok', selectedTableRow?.tiktok || '');
      setValue('status', String(selectedTableRow.status));
      setValue('country_id', selectedTableRow?.country?.id || '1');
      if (selectedTableRow?.links?.length > 0) setValue('links');
      setValue('startDate', moment(selectedTableRow.startDate, 'YYYY-MM-DD'));
      setValue('endDate', moment(selectedTableRow.endDate, 'YYYY-MM-DD'));
      if (selectedTableRow?.socials?.length) {
        setValue(
          'socials',
          selectedTableRow.socials.map((s) => String(s.id))
        );
      }
    }
  }, [selectedTableRow, fetchedPackages]);

  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('name', data.name || '');
    formData.append('phone', data.phone || '');
    formData.append('email', data.email || '');
    formData.append('code', data.code || '');
    formData.append('discount', data.discount || '');
    formData.append('days', data.days || '');
    formData.append('commission', data.commission || '');
    formData.append('note', data.note || '');
    formData.append('youtube', data.youtube || '');
    formData.append('instagram', data.instagram || '');
    formData.append('twitter', data.twitter || '');
    formData.append('facebook', data.facebook || '');
    formData.append('snapchat', data.snapchat || '');
    formData.append('tiktok', data.tiktok || '');
    formData.append('status', data.status || '');
    formData.append('country_id',"1");
    if (data?.image?.length > 0) formData.append('image', data.image[0]);
    if (data?.socials?.length > 0) {
      for (let social of data.socials) {
        formData.append('socials[]', social);
      }
    }
    if (data?.startDate) {
      formData.append('startDate', moment(data.startDate).format('YYYY-MM-DD'));
    }
    if (data?.endDate) {
      formData.append('endDate', moment(data.endDate).format('YYYY-MM-DD'));
    }let countryId = Number(data?.country_id);
    if (countryId) {
      formData.append('country_id', data?.country_id || '');
    } else {
      const country = fetchedCountries.find(
        (obj) => obj.name === data?.country_id
      );
      formData.append('country_id', country?.id || '');
    }
    if (!selectedTableRow) {
      dispatch(
        createInfluencerAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    } else {
      formData.append('influencer_id', selectedTableRow?.id || '');

      dispatch(
        updateInfluencerAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form influencer-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <p className="form-title">
        إضافة مسوق جديد
        <button
          type="button"
          className="back-btn"
          onClick={() => {
            dispatch(setPostFormModalOpened(false));
          }}
        >
          {/* <ArrowToLeftIcon color="#40404199" /> */}
          <ArrowToLeftIcon color="#40404199" />
        </button>
      </p>
      <div className="form-body">
        <div className="two-cols-form">
          <div className="main-col">
            <div className="shared-app-text-field-label-wrapper">
              <p className="outside-label">
                <img src={medalIcon} alt="name" />
                <span>إســم المسوق</span>
              </p>
              <AntdTextField
                className="form-text-field"
                name="name"
                type="text"
                placeholder={'إســم المسوق...'}
                // label="الاســــم"
                errorMsg={errors?.name?.message}
                validateStatus={errors?.name ? 'error' : ''}
                control={control}
              />
            </div>

            <div className="shared-app-text-field-label-wrapper">
              <p className="outside-label">
                <img src={phoneIcon} alt="name" />
                <span>رقم الهاتف</span>
              </p>
              <AntdTextField
                className="form-text-field"
                name="phone"
                type="text"
                placeholder={'رقم الهاتف...'}
                // label="الاســــم"
                errorMsg={errors?.phone?.message}
                validateStatus={errors?.phone ? 'error' : ''}
                control={control}
              />
            </div>

            <div className="shared-app-text-field-label-wrapper">
              <p className="outside-label">
                <img src={emailIcon} alt="name" />
                <span>البريد الالكترونى</span>
              </p>
              <AntdTextField
                className="form-text-field"
                name="email"
                type="text"
                placeholder={'البريد الالكترونى...'}
                // label="الاســــم"
                errorMsg={errors?.email?.message}
                validateStatus={errors?.email ? 'error' : ''}
                control={control}
              />
            </div>
            <div className="custom-select-wrap without-icon shared-app-select-option-label-wrapper">
          <p className="outside-label">
            <img src={fontSizeIcon} alt="name" />
            ادخل البلد
          </p>
          <AntdSelectOption
            name={`country_id`}
            errorMsg={errors?.country_id && errors.country_id.message}
            validateStatus={errors?.country_id ? 'error' : ''}
            control={control}
            setValue={setValue}
            placeholder="ادخل البلد"
            options={
              fetchedCountries?.data?.length > 0 &&
              fetchedCountries?.data?.map((obj) => ({
                title: obj?.name,
                value: obj?.id
              }))
            }
            showSearch={true}
            // onSearch={onSearch}
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            formClassName="device-form"
          />
        </div>
            <div className="shared-app-text-field-label-wrapper code-input-wrapper">
              <p className="outside-label">
                <img src={emailIcon} alt="name" />
                <span>كود دالخصم</span>
              </p>
              <AntdTextField
                className="form-text-field"
                // disabled={true}
                name="code"
                type="text"
                placeholder={'كود دالخصم...'}
                // label="الاســــم"
                errorMsg={errors?.code?.message}
                validateStatus={errors?.code ? 'error' : ''}
                control={control}
              />

              {/* <button
                className="code-btn"
                type="button"
                onClick={() => {
                  const nanoid = customAlphabet(
                    '1234567890abcdefghijklmnopqrstuvwxyz',
                    6
                  );
                  setValue('code', nanoid().toUpperCase());
                }}
              >
                <img src={imagesSrcs?.rotateRight} alt="generate code" />
              </button> */}
            </div>

            <div className="shared-app-text-field-label-wrapper">
              <p className="outside-label">
                <img src={emailIcon} alt="name" />
                <span>نسبة الخصم</span>
              </p>
              <AntdTextField
                className="form-text-field"
                name="discount"
                type="text"
                placeholder={'نسبة الخصم...'}
                // label="الاســــم"
                errorMsg={errors?.discount?.message}
                validateStatus={errors?.discount ? 'error' : ''}
                control={control}
              />
            </div>

            <label className="custom-date-input order-date-label">
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8
                }}
                className="label-span"
              >
                <img src={emailIcon} alt="name" />
                تاريخ بداية الخصم
              </span>
              <CustomAntdDatePicker
                disablePast
                control={control}
                name={`startDate`}
                placeholder="تاريخ بداية الخصم"
                className="order-discount-picker"
                formClassName="influencer-form"
                // errorMsg={errors?.startDate && errors.startDate.message}
              />
              {errors?.startDate && errors.startDate.message && (
                <p
                  style={{
                    marginTop: '-24px'
                  }}
                  className="error-p"
                >
                  {errors?.startDate && errors.startDate.message}
                </p>
              )}
            </label>
            <label className="custom-date-input order-date-label">
              <span
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8
                }}
                className="label-span"
              >
                <img src={emailIcon} alt="name" />
                تاريخ نهاية الخصم
              </span>
              <CustomAntdDatePicker
                disablePast
                control={control}
                name={`endDate`}
                placeholder="تاريخ نهاية الخصم"
                className="order-discount-picker"
                formClassName="influencer-form-end"
                // errorMsg={errors?.endDate && errors.endDate.message}
              />
              {errors?.endDate && errors.endDate.message && (
                <p
                  style={{
                    marginTop: '-24px'
                  }}
                  className="error-p"
                >
                  {errors?.endDate && errors.endDate.message}
                </p>
              )}
            </label>

            <div className="shared-app-text-field-label-wrapper">
              <p className="outside-label">
                <img src={emailIcon} alt="name" />
                <span>مده صلاحية الخصم</span>
              </p>
              <AntdTextField
                className="form-text-field"
                name="days"
                type="text"
                placeholder={'مده صلاحية الخصم...'}
                // label="الاســــم"
                errorMsg={errors?.days?.message}
                validateStatus={errors?.days ? 'error' : ''}
                control={control}
              />
            </div>

            <div className="file-inputs-wrap">
              <FileInput
                name="image"
                label={
                  <p
                    className="outside-label"
                    style={{
                      color: '#404041',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 8,
                      marginBottom: 8,
                      paddingRight: 8
                    }}
                  >
                    <img src={imageIcon} alt="name" />
                    صورة المسوق
                  </p>
                }
                accept="image/*"
                multiple={false}
                setValue={setValue}
                watch={watch}
                register={register}
                unregister={unregister}
                // setUrls={setUrls}
                dropzoneText="اسحب الصورة وضعها هنا ..."
                className="product-images-dropzone"
                dropzoneUrls={
                  selectedTableRow?.image
                    ? [{ url: selectedTableRow.image }]
                    : []
                }
                canDelete={false}
                showError={false}
                errorMsg={errors?.image?.message}
                files={files}
                setFiles={setFiles}
              />
            </div>
          </div>

          <div className="side-col">
            <div className="commision-wrapper">
              <div className="shared-app-text-field-label-wrapper">
                <p className="outside-label">
                  <img src={emailIcon} alt="name" />
                  <span>عمولة المسوق ( نسبة المسوق )</span>
                </p>
                <AntdTextField
                  className="form-text-field"
                  name="commission"
                  type="text"
                  placeholder="حدد عمولة المسوق المتفق عليها"
                  // label="الاســــم"
                  errorMsg={errors?.commission?.message}
                  validateStatus={errors?.commission ? 'error' : ''}
                  control={control}
                />
              </div>
              <div className="select-label-wrap shared-app-select-option-label-wrapper">
            <p className="outside-label">
              <img src={medalIcon} alt="name" />
              حالة الباقة
            </p>
            <div className="custom-select-wrap without-icon">
              <AntdSelectOption
                name={`status`}
                errorMsg={errors?.status && errors.status.message}
                validateStatus={errors?.status ? 'error' : ''}
                control={control}
                setValue={setValue}
                placeholder="حالة الباقة"
                options={
                  getInfluencerStatusArr()?.length > 0 &&
                  getInfluencerStatusArr().map((obj) => ({
                    title: obj?.name,
                    value: obj?.value
                  }))
                }
                showSearch={true}
                // onSearch={onSearch}
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                formClassName="package-form"
              />
            </div>
          </div>
            </div>

            {/*  */}
            <div className="socials-wrapper">
              <div className="custom-select-wrap without-icon shared-app-select-option-label-wrapper multiple-select-option">
                <p className="outside-label">
                  <img src={imagesSrcs?.bubble} alt="name" />
                  منصات التسويق
                </p>
                <AntdSelectOption
                  name={`socials`}
                  errorMsg={errors?.socials && errors.socials.message}
                  validateStatus={errors?.socials ? 'error' : ''}
                  control={control}
                  setValue={setValue}
                  placeholder="حدد نوع منصات التسويق"
                  mode="multiple"
                  options={
                    fetchedSocials?.length > 0 &&
                    fetchedSocials.map((obj) => ({
                      title: obj?.name,
                      value: obj?.id
                    }))
                  }
                  showSearch={true}
                  // onSearch={onSearch}
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  formClassName="influencer-form"
                />
              </div>
              {/*  */}
              {/*  */}
              <p className="links-label">
                <img src={imagesSrcs?.linkImg} alt="name" />
                روابط منصات التسويق
              </p>

              <div className="links-wrapper">
                <div className="shared-app-text-field-label-wrapper">
                  <AntdTextField
                    className="form-text-field"
                    name="youtube"
                    type="text"
                    placeholder="رابط Youtube"
                    prefix={<img src={imagesSrcs?.youtubeIcon} alt="youtube" />}
                    errorMsg={errors?.youtube?.message}
                    validateStatus={errors?.youtube ? 'error' : ''}
                    control={control}
                  />
                </div>
                <div className="shared-app-text-field-label-wrapper">
                  <AntdTextField
                    className="form-text-field"
                    name="facebook"
                    type="text"
                    placeholder="رابط Facebook"
                    prefix={<img src={imagesSrcs?.facebook} alt="facebook" />}
                    errorMsg={errors?.facebook?.message}
                    validateStatus={errors?.facebook ? 'error' : ''}
                    control={control}
                  />
                </div>
                <div className="shared-app-text-field-label-wrapper">
                  <AntdTextField
                    className="form-text-field"
                    name="instagram"
                    type="text"
                    placeholder="رابط Instagram"
                    prefix={<img src={imagesSrcs?.instagram} alt="youtube" />}
                    errorMsg={errors?.instagram?.message}
                    validateStatus={errors?.instagram ? 'error' : ''}
                    control={control}
                  />
                </div>
                <div className="shared-app-text-field-label-wrapper">
                  <AntdTextField
                    className="form-text-field"
                    name="twitter"
                    type="text"
                    placeholder="رابط Twitter"
                    prefix={<img src={imagesSrcs?.twitter} alt="twitter" />}
                    errorMsg={errors?.twitter?.message}
                    validateStatus={errors?.twitter ? 'error' : ''}
                    control={control}
                  />
                </div>
                <div className="shared-app-text-field-label-wrapper">
                  <AntdTextField
                    className="form-text-field"
                    name="snapchat"
                    type="text"
                    placeholder="رابط Snapchat"
                    prefix={<img src={imagesSrcs?.snapchat} alt="snapchat" />}
                    errorMsg={errors?.snapchat?.message}
                    validateStatus={errors?.snapchat ? 'error' : ''}
                    control={control}
                  />
                </div>
                <div className="shared-app-text-field-label-wrapper">
                  <AntdTextField
                    className="form-text-field"
                    name="tiktok"
                    type="text"
                    placeholder="رابط Tiktok"
                    prefix={<img src={imagesSrcs?.tiktok} alt="tiktok" />}
                    errorMsg={errors?.tiktok?.message}
                    validateStatus={errors?.tiktok ? 'error' : ''}
                    control={control}
                  />
                </div>
              </div>
              {/*  */}

              <div className="shared-app-text-field-label-wrapper is-text-area">
                <p className="outside-label">
                  <NotesIcon width={24} height={24} />
                  <span>ملاحظات</span>
                </p>
                <AntdTextarea
                  className="form-text-field"
                  rows={5}
                  name="note"
                  type="text"
                  placeholder={'ملاحظات...'}
                  // label="الاســــم"
                  errorMsg={errors?.note?.message}
                  validateStatus={errors?.note ? 'error' : ''}
                  control={control}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="modal-action-btns-wrapper form-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            type="button"
            onClick={() => {
              dispatch(setPostFormModalOpened(false));
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {/* {isLoadingSignin && <LoadingModal />} */}
    </Form>
  );
};

export default InfluencerForm;
