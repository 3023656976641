import './CommonTag.scss';

const CommonTag = ({ className, iconSrc, svg, title }) => {
  return (
    <div className={`common-tag-wrap ${className || ''}`}>
      {svg ? svg : null}
      {iconSrc && <img src={iconSrc} alt="icon" />}
      {title}
    </div>
  );
};

export default CommonTag;
