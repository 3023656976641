/* eslint-disable eqeqeq */
import React, { useContext } from 'react';
import { Dropdown, Menu, Button, Avatar } from 'antd';
import { Link as RouterLink } from 'react-router-dom';
import { UserOutlined, LoadingOutlined } from '@ant-design/icons';
import UserContext from '../../../contexts/user-context/UserProvider';
import useSignout from '../../../custom-hooks/useSignout';
import CustomImage from '../../../common/custom-image/CustomImage';
import { useTranslation } from 'react-i18next';
import dropdownIcon from '../../../assets/imgs/icons/appbar-dropdown-icon.svg';
import routerLinks from '../routerLinks';
import signoutImg from '../../../assets/imgs/icons/logout.png';
import './MainAppProfileMenu.scss';

const MainAppProfileMenu = () => {
  const { user } = useContext(UserContext);
  const { t } = useTranslation();
  const { isLoadingSignout, signMeOut } = useSignout();
  const handleSignout = () => {
    signMeOut();
  };

  const renderMenu = () => [
    {
      key: 'user_settings_key',
      label: (
        <RouterLink to={routerLinks?.profileRoute} className="profile-link">
          <CustomImage
            style={{
              width: '58px',
              height: '58px',
              border: '2px solid #2a9d7c',
              borderRadius: '50%',
              padding: 8
            }}
            src={user?.image}
            className="profile-img"
          />
          <div className="name-email-country-wrapper">
            <p className="name-p">{user?.name}</p>
            <p className="email-p">{user?.email}</p>
            {/* <p className="location-p">
                  <PinIcon color="#404041" />
                  المملكة العربية السعودية
                </p> */}
          </div>
        </RouterLink>
      )
    },
    {
      key: 'signout_key',
      icon: <img src={signoutImg} alt="logout" />,
      className: 'signout-btn',
      label: t('main_app_bar_links.signout'),
      onClick: () => {
        handleSignout();
      }
    }
  ];

  return (
    <div className="profile-dropdown-btn-wrapper">
      <Dropdown
        overlayClassName="main-app-profile-dropdown"
        arrow
        trigger={['click']}
        // disabled={loadingSignout}
        menu={{
          items: renderMenu()
        }}
      >
        <Button className="profile-menu-btn" type="text">
          <Avatar size={40} icon={<UserOutlined />} src={user?.image} />

          {isLoadingSignout ? (
            <LoadingOutlined />
          ) : (
            <img src={dropdownIcon} alt="drop" />
          )}
        </Button>
      </Dropdown>
    </div>
  );
};

export default MainAppProfileMenu;
