import { createSlice } from '@reduxjs/toolkit';
import { getArchivesAction } from './archiveSliceThunkApi';

const initialState = {
  /////////////////////
  isLoadingTableData: false,
  fetchedTableData: [],
  fetchDataCount: 0,
  postFormModalOpened: false,
  isSubmitting: false,
  selectedTableRow: null,

  //
  tablePagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  }
};

const archiveSlice = createSlice({
  name: 'archiveSlice',
  initialState,
  reducers: {
    setSelectedTableRow: (sliceState, action) => {
      sliceState.selectedTableRow = action.payload;
    }
  },
  extraReducers: {
    // get clients
    [getArchivesAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getArchivesAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedTableData = data?.data;
      if (data?.pagination) state.tablePagination = data.pagination;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getArchivesAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    }
    //
    //
  }
});

const archiveReducer = archiveSlice.reducer;

export const {
  setPostFormModalOpened,
  setDeleteModalOpened,
  setSelectedTableRow,
  setConfirmModal,
  setSelectedTab
} = archiveSlice.actions;

export default archiveReducer;
