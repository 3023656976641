/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import './AppGuideForm.scss';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import FileInput from '../../common/file-input/FileInput';
import checkRes from '../../utils/checkRes';
import { setSelectedTableRow } from '../../redux/app-guide-slice/appGuideSlice';
import {
  createAppGuideAction,
  updateAppGuideAction
} from '../../redux/app-guide-slice/appGuideSliceThunkApi';

const schema = Yup.object().shape({
  title_ar: Yup.string().required('من فضلك ادخل الاسم بالعربية'),
  title_en: Yup.string().required('من فضلك ادخل الاسم بالانجليزية')
});

const { medalIcon } = imagesSrcs;

const emptyFormValues = {
  title_ar: '',
  title_en: '',
  link: ''
};

const AppGuideForm = ({ fetchedTableData }) => {
  const dispatch = useDispatch();
  const selectedTableRow = useSelector(
    (store) => store.appGuide.selectedTableRow
  );
  const {
    control,
    handleSubmit,
    setValue,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: emptyFormValues
  });

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      setValue('title_ar', selectedTableRow?.title_ar || '');
      setValue('title_en', selectedTableRow?.title_en || '');
      setValue('link', selectedTableRow?.link || '');
    }
  }, [selectedTableRow, fetchedTableData]);
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('title_ar', data.title_ar || '');
    formData.append('title_en', data.title_en || '');
    formData.append('link', data.link || '');

    if (!selectedTableRow) {
      const action = await dispatch(
        createAppGuideAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action?.payload?.res;
      if (checkRes(res)) {
        reset(emptyFormValues);
      }
    } else {
      formData.append('id', selectedTableRow?.id || '');

      const action = await dispatch(
        updateAppGuideAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action?.payload?.res;
      if (checkRes(res)) {
        reset(emptyFormValues);
      }
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form website-partner-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان بالعربية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="title_ar"
            type="text"
            placeholder={'العنوان بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.title_ar?.message}
            validateStatus={errors?.title_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>العنوان بالانجليزية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="title_en"
            type="text"
            placeholder={'العنوان بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.title_en?.message}
            validateStatus={errors?.title_en ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>الرابط</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="link"
            type="text"
            placeholder={'الرابط...'}
            // label="الاســــم"
            errorMsg={errors?.link?.message}
            validateStatus={errors?.link ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            type="button"
            onClick={() => {
              reset(emptyFormValues);
              dispatch(setSelectedTableRow(null));
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {/* {isLoadingSignin && <LoadingModal />} */}
    </Form>
  );
};

export default AppGuideForm;
