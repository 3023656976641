import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  getAllWebsitePartnersApi,
  getSingleWebsitePartnerApi,
  createWebsitePartnerApi,
  deleteWebsitePartnerApi
} from '../../apis/website-partners-apis/websitePartnersApis';
import customApiRequest from '../../helpers/customApiRequest';

export const getWebsitePartnersAction = createAsyncThunk(
  'websitePartners/getWebsitePartnersAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getAllWebsitePartnersApi(filterObj),
      thunkAPI
    });
  }
);

export const getSingleWebsitePartnerAction = createAsyncThunk(
  'websitePartners/getSingleWebsitePartnerAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getSingleWebsitePartnerApi(filterObj),
      thunkAPI
    });
  }
);

// create website partner
export const createWebsitePartnerAction = createAsyncThunk(
  'websitePartners/createWebsitePartnerAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createWebsitePartnerApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
//
// update website partner
export const updateWebsitePartnerAction = createAsyncThunk(
  'websitePartners/updateWebsitePartnerAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createWebsitePartnerApi(dataToBeSubmitted, true),
      thunkAPI
    });
  }
);
//

export const deleteWebsitePartnerAction = createAsyncThunk(
  'websiteServices/deleteWebsitePartnerAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteWebsitePartnerApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
