import React, { useState, useEffect, useContext, createContext } from 'react';
import mainAppBarLinks from '../../components/main-app-bar/mainAppBarLinks';
import UserContext from '../user-context/UserProvider';
import sharedAppBarLinks from '../../components/main-app-bar/sharedAppBarLinks';

const INITIAL_VALUES = {
  links: [...mainAppBarLinks],
  setLinks: (v) => {},
  selectedLink: null,
  setSelectedLink: (v) => {},
  activeLink: null, // to handle the color of the current nav link
  setActiveLink: (v) => {}, // to handle the color of the current nav link
  menuMdOpened: false,
  setMenuMdOpened: (v) => {}
};

const MainAppBarContext = createContext(INITIAL_VALUES);

export const MainAppBarProvider = ({ children }) => {
  const { user } = useContext(UserContext);

  const [links, setLinks] = useState(INITIAL_VALUES.links);
  const [selectedLink, setSelectedLink] = useState(INITIAL_VALUES.selectedLink);
  const [activeLink, setActiveLink] = useState(INITIAL_VALUES.activeLink);
  const [menuMdOpened, setMenuMdOpened] = useState(false);
  // check roles
  useEffect(() => {
    if (user) {
      // // manager
      // if (user?.role === accountypes()?.manager) {
      //   setLinks([...mainAppBarLinks]);
      // }
      // // accountant
      // else if (user?.role === accountypes()?.accountant) {
      //   setLinks([
      //     {
      //       id: 1,
      //       to: routerLinks.homePage,
      //       name: 'الرئيســية',
      //       isLink: true,
      //       icon: (color) =>
      //         color ? <HomeIcon color={color} /> : <HomeIcon />,
      //       subMenu: [],
      //       visibleSubMenu: false
      //     }
      //   ]);
      // } else {
      //   setLinks([...sharedAppBarLinks]);
      // }

      setLinks([...sharedAppBarLinks]);
    }
  }, [user]);

  return (
    <MainAppBarContext.Provider
      value={{
        links,
        setLinks,
        selectedLink,
        setSelectedLink,
        activeLink,
        setActiveLink,
        menuMdOpened,
        setMenuMdOpened
      }}
    >
      {children}
    </MainAppBarContext.Provider>
  );
};

export default MainAppBarContext;
