import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter as Router } from 'react-router-dom';
import { MainAppBarProvider } from './contexts/main-app-bar-context/MainAppBarProvider';
import store from './redux/store';
import { Provider as ReactReduxProvider } from 'react-redux';
import App from './components/app/App';
import 'react-toastify/dist/ReactToastify.css';

// import 'antd/dist/antd.css';
// import 'antd/dist/antd.less';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import './scss/index.scss';
import { UserProvider } from './contexts/user-context/UserProvider';
import { SharedAppProvider } from './contexts/shared-app-context/SharedAppContext';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <UserProvider>
    <ReactReduxProvider store={store}>
      <MainAppBarProvider>
        <Router>
          <SharedAppProvider>
            <App />
          </SharedAppProvider>
        </Router>
      </MainAppBarProvider>
    </ReactReduxProvider>
  </UserProvider>
);
