/* eslint-disable eqeqeq */

// 1 => active
// 0 => not active

export const influencerStatusValues = {
  active: 1,
  notActive: 0
};

export const getInfluencerStatusArr = (t) => {
  return [
    {
      id: 1,
      name: 'مفعلة',
      value: influencerStatusValues?.active
    },
    {
      id: 0,
      name: 'غير مفعلة',
      value: influencerStatusValues?.notActive
    }
  ];
};
