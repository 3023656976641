import { createAsyncThunk } from '@reduxjs/toolkit';
import createAdminApi from '../../apis/admins-apis/createAdminApi';
import deleteAdminApi from '../../apis/admins-apis/deleteAdminApi';
import getAdminsApi from '../../apis/admins-apis/getAdminsApi';
import getSingleAdminApi from '../../apis/admins-apis/getSingleAdminApi';
import toggleAdminApi from '../../apis/admins-apis/toggleAdminApi';
import customApiRequest from '../../helpers/customApiRequest';

export const getAdminsAction = createAsyncThunk(
  'admins/getAdminsAction',
  async ({ filterObj }, thunkAPI) => {
    // console.log('thunk api : ', thunkAPI);

    return customApiRequest({
      apiCall: await getAdminsApi(filterObj),
      thunkAPI
    });
  }
);

// single admin
export const getSingleAdminAction = createAsyncThunk(
  'admins/getSingleAdminAction',
  async ({ filterObj }, thunkAPI) => {
    // console.log('thunk api : ', thunkAPI);

    return customApiRequest({
      apiCall: await getSingleAdminApi(filterObj),
      thunkAPI
    });
  }
);

// create admin
export const createAdminAction = createAsyncThunk(
  'admins/createAdminAction',
  async ({ dataToBeSubmitted, cb }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createAdminApi(dataToBeSubmitted),
      thunkAPI,
      cb
    });
  }
);
//
// create admin
export const updateAdminAction = createAsyncThunk(
  'admins/updateAdminAction',
  async ({ dataToBeSubmitted, cb }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createAdminApi(dataToBeSubmitted, true),
      thunkAPI,
      cb
    });
  }
);
//
export const toggleAdminAction = createAsyncThunk(
  'admins/toggleAdminAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await toggleAdminApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);

export const deleteAdminAction = createAsyncThunk(
  'admins/deleteAdminAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteAdminApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
