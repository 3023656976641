import { createAsyncThunk } from '@reduxjs/toolkit';
import changeClientStatusApi from '../../apis/clients-apis/changeClientStatusApi';
import createClientApi from '../../apis/clients-apis/createClientApi';
import deleteClientApi from '../../apis/clients-apis/deleteClientApi';
import getAllClientsApi from '../../apis/clients-apis/getAllClientsApi';
import getSingleClientApi from '../../apis/clients-apis/getSingleClientApi';
import customApiRequest from '../../helpers/customApiRequest';

export const getClientsAction = createAsyncThunk(
  'clients/getClientsAction',
  async ({ filterObj, cb }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getAllClientsApi(filterObj),
      thunkAPI,
      cb
    });
  }
);

export const getSingleClientsAction = createAsyncThunk(
  'clients/getSingleClientsAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getSingleClientApi(filterObj),
      thunkAPI
    });
  }
);

// create client
export const createClientAction = createAsyncThunk(
  'clients/createClientAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createClientApi(dataToBeSubmitted),
      thunkAPI
    });
  }
);
//
// update client
export const updateClientAction = createAsyncThunk(
  'clients/updateClientAction',
  async ({ dataToBeSubmitted }, thunkAPI) => {
    return customApiRequest({
      apiCall: await createClientApi(dataToBeSubmitted, true),
      thunkAPI
    });
  }
);
//
// change client status
export const changeClientStatusAction = createAsyncThunk(
  'clients/changeClientStatusAction',
  async ({ dataToBeSubmitted, isClientPage, cb }, thunkAPI) => {
    return customApiRequest({
      apiCall: await changeClientStatusApi(dataToBeSubmitted),
      thunkAPI,
      isClientPage,
      cb
    });
  }
);
//

export const deleteClientAction = createAsyncThunk(
  'clients/deleteClientAction',
  async ({ dataToBeSubmitted, isClientPage, cb }, thunkAPI) => {
    return customApiRequest({
      apiCall: await deleteClientApi(dataToBeSubmitted),
      thunkAPI,
      isClientPage,
      cb
    });
  }
);
