import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  getPaymentTypesAction,
  updatePaymentTypeAction
} from './paymentTypesSliceThunkApi';

const initialState = {
  /////////////////////
  isLoadingTableData: false,
  fetchedTableData: [],
  fetchDataCount: 0,
  postFormModalOpened: false,
  isSubmitting: false,
  selectedTableRow: null,

  //
  tablePagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  }
};

const paymentTypesSlice = createSlice({
  name: 'paymentTypesSlice',
  initialState,
  reducers: {
    setSelectedTableRow: (sliceState, action) => {
      sliceState.selectedTableRow = action.payload;
    },
    setPostFormModalOpened: (sliceState, action) => {
      sliceState.postFormModalOpened = action.payload;
    }
  },
  extraReducers: {
    // get payment types
    [getPaymentTypesAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getPaymentTypesAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedTableData = data;
      // if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getPaymentTypesAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    //
    //
    //
    // edit payment type
    [updatePaymentTypeAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [updatePaymentTypeAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action?.payload?.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.postFormModalOpened = false;
      }
    },
    [updatePaymentTypeAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
    //
  }
});

const paymentTypesReducer = paymentTypesSlice.reducer;

export const {
  setPostFormModalOpened,
  setDeleteModalOpened,
  setSelectedTableRow,
  setConfirmModal,
  setSelectedTab
} = paymentTypesSlice.actions;

export default paymentTypesReducer;
