import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { Button, Form } from 'antd';
import useSigninEmailPassword from '../../custom-hooks/useSigninEmailPassword';
import useFCMDeviceToken from '../../custom-hooks/useFCMDeviceToken';
import EmailIcon from '../../common/icons/EmailIcon';
import LockIcon from '../../common/icons/LockIcon';
import EyeOffIcon from '../../common/icons/EyeOffIcon';
import EyeOnIcon from '../../common/icons/EyeOnIcon';
import './SigninForm.scss';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import AntdCheckbox from '../../common/antd-form-components/AntdCheckbox';

const schema = Yup.object().shape({
  email: Yup.string()
    .required('ادخل البريد الاكترونى')
    .email('ادخل بريد الكترونى صحيح'),
  password: Yup.string().required('من فضلك ادخل الرقم السرى')
});

const SigninForm = () => {
  const [passwrodVisible, setPasswordVisible] = React.useState(false);
  const fcmDeviceToken = useFCMDeviceToken();

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all'
    // defaultValues: {
    //   email: 'admin@sa.com',
    //   password: 123456,
    //   remember: true
    // }
  });

  const { signMeInWithEmailPassword, isLoadingSignin } =
    useSigninEmailPassword();
  const onSubmit = (data) => {
    signMeInWithEmailPassword({
      ...data,
      fire_base: fcmDeviceToken
    });
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form signin-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-header">
        <p className="bold-font main-title">مرحبا بعودتك !</p>
        <p className="sub-title">تسجيل الدخول</p>
      </div>

      <div className="form-body">
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <EmailIcon />
            <span>البريد الاكترونى</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="email"
            type="text"
            placeholder={'البريد الاكترونى...'}
            // label="الاســــم"
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <LockIcon />
            <span>كلمة المرور</span>
          </p>
          <div className="login-password-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="password"
              type={passwrodVisible ? 'text' : 'password'}
              placeholder="كلمة المرور..."
              errorMsg={errors?.password?.message}
              validateStatus={errors?.password ? 'error' : ''}
              control={control}
            />

            {watch('password') && (
              <div
                className="eye-icon-btn"
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </div>
            )}
          </div>
        </div>

        <AntdCheckbox
          className="remember-checkbox"
          name="remember"
          control={control}
          label="تذكرنى"
        />

        {/* <AntdCheckbox name="remember" label="تذكرنى" control={control} /> */}
        <Button
          className="submit-btn"
          htmlType="submit"
          type="primary"
          // icon={<LoginOutlined />}
          // loading={isSubmitting}
          loading={isLoadingSignin}
        >
          تسجيل الدخول
        </Button>
      </div>

      {isLoadingSignin && <LoadingModal />}
    </Form>
  );
};

export default SigninForm;
