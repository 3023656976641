/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import './AppGuidePage.scss';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useMemo } from 'react';
import ConfirmModal from '../../common/confirm-modal/ConfirmModal';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';
import { LoadingOutlined } from '@ant-design/icons';
import {
  setDeleteModalOpened,
  setSelectedTableRow
} from '../../redux/app-guide-slice/appGuideSlice';
import CustomEmpty from '../../common/custom-empty/CustomEmpty';
import EditIcon from '../../common/icons/EditIcon';
import getFoundObjByValue from '../../helpers/getFoundObjByValue';
import {
  brandsStatus,
  getBrandsStatusArr
} from '../../const-values/brandsStatus';
import { Tag } from 'antd';
import TrashIcon from '../../common/icons/TrashIcon';
import AppGuideForm from './AppGuideForm';
import {
  deleteAppGuideAction,
  getAppGuideAction
} from '../../redux/app-guide-slice/appGuideSliceThunkApi';

const AppGuidePage = () => {
  const dispatch = useDispatch();
  const deleteModalOpened = useSelector(
    (store) => store.appGuide.deleteModalOpened
  );
  const isLoadingTableData = useSelector(
    (store) => store.appGuide.isLoadingTableData
  );
  const selectedTableRow = useSelector(
    (store) => store.appGuide.selectedTableRow
  );
  const fetchDataCount = useSelector((store) => store.appGuide.fetchDataCount);
  const isSubmitting = useSelector((store) => store.appGuide.isSubmitting);
  const fetchedTableData = useSelector(
    (store) => store.appGuide.fetchedTableData
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let isMounted = true;

  useEffect(() => {
    if (isMounted)
      dispatch(
        getAppGuideAction({
          filterObj: null
        })
      );

    return function () {
      isMounted = false;
    };
  }, [fetchDataCount]);

  const handleDeleteObj = () => {
    if (isMounted && selectedTableRow?.id) {
      const formData = new FormData();
      formData.append('id', selectedTableRow.id);
      dispatch(
        deleteAppGuideAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  const CardItem = (card) => {
    return (
      <div className="item-item">
        <div className="item-content">
          <div
            className="item-details"
            style={{
              display: 'flex',
              gap: 4,
              alignItems: 'center'
            }}
          >
            <p className="name">{card?.title}</p>
          </div>
          <div className="item-btns-wrapper">
            <button
              onClick={() => {
                dispatch(setSelectedTableRow(card));
                dispatch(setDeleteModalOpened(true));
              }}
              style={{
                padding: 0
              }}
              className="btn delete-btn"
            >
              <TrashIcon color="rgba(64, 64, 65, 0.6)" />
              حذف
            </button>
            <button
              style={{
                padding: 0
              }}
              className="btn edit-btn"
              onClick={() => {
                dispatch(setSelectedTableRow(card));
              }}
            >
              <EditIcon color="rgba(64, 64, 65, 0.6)" />
              تعديل
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderList = useMemo(() => {
    return () => {
      if (isLoadingTableData) {
        return (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              minHeight: 360
            }}
          >
            <LoadingOutlined />
          </div>
        );
      } else if (fetchedTableData?.length === 0) {
        return (
          <CustomEmpty
            style={{
              minHeight: 642
            }}
          >
            <h3
              style={{
                color: '#40404166',
                marginTop: 18
              }}
            >
              لم تقم بإضافة الدليل بعد
            </h3>
          </CustomEmpty>
        );
      } else if (fetchedTableData?.length > 0) {
        return (
          <div className="sec-list-wrapper">
            {fetchedTableData.map((item) => {
              return <CardItem key={item.id} {...item} />;
            })}
          </div>
        );
      }
      return null;
    };
  }, [fetchedTableData, isLoadingTableData]);

  return (
    <div className="website-page website-partners-page">
      <p className="page-title">دليل التطبيق</p>
      <div className="form-list-wrapper">
        <div className="forms-section">
          <AppGuideForm fetchedTableData={fetchedTableData} />
        </div>

        <div className="desc-sec">
          <div className="desc-header">الدليل</div>
          {renderList()}
        </div>
      </div>

      {/* delete modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteModalOpened}
        modalType={confirmModalTypesValues?.errorModal}
        setModalOpened={(v) => dispatch(setDeleteModalOpened(v))}
        handleAccept={handleDeleteObj}
      >
        <h3>{`هل انت متأكد من الحذف ${selectedTableRow?.name || ''}`}</h3>
      </ConfirmModal>
      {/* end delete modal */}

      {(isSubmitting || isLoadingTableData) && <LoadingModal />}
    </div>
  );
};

export default AppGuidePage;
