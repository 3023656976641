import merchentRouterLinks from './merchent-routes/merchentRouterLinks';
import sharedRouterLinks from './shared-routes/sharedRouterLinks';
import userRouterLinks from './user-routes/uesRouterLinks';

const routerLinks = {
  ////////////////////////////
  // homePage: '/',
  ///////////////////////////
  signinPage: '/signin',
  signupPage: '/signup',
  userSignupRoute: '/signup/user',
  merchentSignupRoute: '/signup/merchent',
  employeeSignupRoute: '/signup/employee',
  profilePage: '/profile',
  forgetPasswordRoute: '/forget-password',
  notificationsRoute: '/notifications',
  ////////////////////////////
  ////////////////////////////
  ...userRouterLinks,
  ...merchentRouterLinks,
  ...sharedRouterLinks,
  ////////////////////////////
  ////////////////////////////
  // ...servicesRouterLinks,
  ////////////////////////////
  // slidesRoute: '/slides',
  // categoriesRoute: '/categories',
  // servicesRoute: '/services',
  // clientsRoute: '/clients',
  // ordersRoute: '/orders',
  // orderDetailsRoute: (orderId) =>
  //   orderId ? `/orders/${orderId}` : '/orders/:orderId',
  // ourValuesRoute: '/our-values',
  // faqRoute: '/faq',
  // contactMessagesRoute: '/contact-messages',
  // socialLinksRoute: '/social-links',
  // packagesRoute: '/packages',
  // discountRoute: '/discount',
  settingsRoute: '/settings',
  // ////////////////////////////////
  // aboutUsRoute: '/about-us',
  // ////////////////////////////////
  notFound: '/not-found'
  //////////////////////////////
};

export default routerLinks;
