/* eslint-disable eqeqeq */

import CustomImage from '../../common/custom-image/CustomImage';
import EditIcon from '../../common/icons/EditIcon';
import TrashIcon from '../../common/icons/TrashIcon';
import medalIcon from '../../assets/imgs/icons/packages-icons/medal.svg';
import posIcon from '../../assets/imgs/icons/packages-icons/pos-icon.svg';
import CommonTag from '../../common/common-tag/CommonTag';
import { Link } from 'react-router-dom';
import sharedRouterLinks from '../../components/app/shared-routes/sharedRouterLinks';
import routerLinks from '../../components/app/routerLinks';

const ClientsTableColumns = ({
  allFetchedTableData,
  setFormModalOpened,
  setDetailsModalOpened,
  setFetchTableDataCount,
  setSelectedTableRow,
  setIsLoadingTableData,
  token,
  customApiRequest,
  setConfirmModal,
  setDeleteModalOpened,
  i18n,
  t,
  ...props
}) => {
  const handleEdit = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setFormModalOpened(true);
    }
  };
  const handleDeleteClient = (record) => {
    const found = allFetchedTableData.find((obj) => obj.id === record.id);
    if (found) {
      setSelectedTableRow({ ...found });
      setDeleteModalOpened(true);
    }
  };

  const renderActions = (record) => {
    return (
      <div className="action-btns-wrap two-rows">
        <div className="main-btns-wrapper">
          <Link
            to={sharedRouterLinks?.appSingleClientRoute(record?.id || '')}
            className="action-btn view"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              width="24"
              height="24"
              fill="#196c10"
            >
              <path fill="none" d="M0 0h24v24H0z" />
              <path d="M12 3c5.392 0 9.878 3.88 10.819 9-.94 5.12-5.427 9-10.819 9-5.392 0-9.878-3.88-10.819-9C2.121 6.88 6.608 3 12 3zm0 16a9.005 9.005 0 0 0 8.777-7 9.005 9.005 0 0 0-17.554 0A9.005 9.005 0 0 0 12 19zm0-2.5a4.5 4.5 0 1 1 0-9 4.5 4.5 0 0 1 0 9zm0-2a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
            </svg>
          </Link>
          <button
            className="action-btn edit"
            onClick={() => handleDeleteClient(record)}
          >
            <TrashIcon color="#196c10" />
          </button>
          <Link
            to={routerLinks?.appClientsRoute}
            className="action-btn edit"
            onClick={() => handleEdit(record)}
          >
            <EditIcon color="#196c10" />
          </Link>
        </div>

        {/* {record?.subscriptionStatus == subscriptionStatus?.active && (
          <div className="accept-reject-btns">
            <button
              onClick={() => {
                handleClickConfirm(
                  record,
                  confirmModalTypesValues?.successModal
                );
              }}
              className="accept-btn"
            >
              <CheckmarkCircledIcon />
              قبول
            </button>
            <button
              onClick={() => {
                handleClickConfirm(record, confirmModalTypesValues?.errorModal);
              }}
              className="reject-btn"
            >
              <CancelCircledIcon />
              رفض
            </button>
          </div>
        )} */}
      </div>
    );
  };

  // const SingleTag = (item) => {
  //   if (item?.pacakge?.name) {
  //     return ()
  //   }
  // }

  // const renderSubscriptionTags = arr => {
  //   return (
  //     arr.map(item => {
  //       return (
  //         <SingleTag key={item?.id} item={item} />
  //       )
  //     })
  //   )
  // }

  const RenderTag = ({ iconSrc, title, className }) => {
    return <CommonTag title={title} iconSrc={iconSrc} className={className} />;
  };

  return [
    {
      title: '',
      dataIndex: 'image',
      render: (_, record) => {
        console.log(record);

        return (
          <div className="table-img-wrapper">
            <CustomImage className="table-package-img" src={record?.image} />
          </div>
        );
      }
    },
    {
      title: '#',
      dataIndex: 'id',
      render: (_, record) => {
        return record?.id || '----';
      }
    },
    {
      title: 'الاسم',
      dataIndex: 'name',
      render: (_, record) => {
        return record?.name || '----';
      }
    },
    {
      title: 'رقم الهاتف',
      dataIndex: 'phone',
      render: (_, record) => {
        return record?.phone || '----';
      }
    },
    {
      title: 'الجهاز',
      dataIndex: 'device',
      render: (_, record) => {
        return record?.subscription?.device?.name || '----';
      }
    },
    {
      title: 'الاشتراك',
      dataIndex: 'tags',
      render: (_, record) => {
        return (
          <div className="package-tags">
            {record?.subscription?.package ? (
              <>
                <div className="subscription-tags">
                  {record?.subscription?.package?.duration ? (
                    <RenderTag
                      iconSrc={medalIcon}
                      title={`اشتراك ${
                        record?.subscription?.package?.duration || ''
                      }`}
                      className="yellow"
                    />
                  ) : null}
                  <RenderTag
                    iconSrc={medalIcon}
                    title={`${
                      record?.subscription?.package?.price
                        ? `${record?.subscription?.package?.price} ${t(
                            'currency.sar'
                          )}`
                        : ''
                    }`}
                    className="yellow"
                  />
                </div>
                <div className="device-tags">
                  {record?.subscription?.package?.device?.name ? (
                    <RenderTag
                      iconSrc={posIcon}
                      title={`${record?.subscription?.package?.device?.name} ${record?.subscription?.package?.device?.model}`}
                      className="green"
                    />
                  ) : null}
                  {record?.subscription?.package?.device?.brand?.name ? (
                    <RenderTag
                      iconSrc={posIcon}
                      title={`${record?.subscription?.package?.device.brand.name}`}
                      className="green"
                    />
                  ) : null}
                </div>
              </>
            ) : (
              '---'
            )}
          </div>
        );
      }
    },

    {
      title: 'إجراءات',
      dataIndex: 'actions',
      render: (_, record) => {
        return (
          <div className="row-cell row-actions">{renderActions(record)}</div>
        );
      }
    }

    // {
    //   title: 'عرض التفاصيل',
    //   dataIndex: 'details',
    //   // width: 192,
    //   render: (_, record) => {
    //     return (
    //       <Tooltip title="عرض التفاصيل">
    //         <Button
    //           size="large"
    //           type="dashed"
    //           shape="round"
    //           icon={<EyeOutlined />}
    //           onClick={() => handleViewDelegateDetails(record)}
    //         />
    //       </Tooltip>
    //     );
    //   }
    // }
  ];
};

export default ClientsTableColumns;
