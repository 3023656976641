/* eslint-disable react-hooks/exhaustive-deps */
import { Badge } from 'antd';

import { medalImgSrc, usersImgSrc } from '../../const-values/imagesImports';
import CreateNewBtn from '../../common/create-new-btn/CreateNewBtn';
import { useDispatch, useSelector } from 'react-redux';
import {
  setSelectedTableRow,
  setWebsitePackageFormOpenedAction
} from '../../redux/website-packages-slice/websitePackagesSlice';
import { packagesStatusValues } from '../../const-values/packagesStatus';

const StatBox = ({ title, value, imgSrc }) => {
  return (
    <div
      className="stat-box"
      style={{
        marginLeft: 22,
        paddingLeft: 22
      }}
    >
      <img src={imgSrc} alt="medal" />
      <span
        style={{
          marginLeft: 12
        }}
        className="label-span"
      >
        {title}
      </span>
      <Badge
        className="value-badge"
        overflowCount={100000}
        count={value}
        showZero
      />
    </div>
  );
};
const WebsitePackagesPageFilter = () => {
  const dispatch = useDispatch();
  const fetchedPackages = useSelector(
    (store) => store.websitePackages.fetchedTableData
  );
  return (
    <div
      style={{
        marginBottom: 18
      }}
      className="filter-stats-search-wrapper"
    >
      <div className="stats-new-btn-wrapper">
        <div className="stats-wrap">
          <StatBox
            title="الباقات"
            value={fetchedPackages?.length || '-'}
            imgSrc={medalImgSrc}
          />
          <StatBox
            title="المفعلة"
            value={
              fetchedPackages?.length > 0
                ? fetchedPackages.reduce((acc, curr) => {
                    const currNum =
                      curr?.status !== packagesStatusValues?.active ? 0 : 1;
                    return acc + currNum;
                  }, 0)
                : '-'
            }
            imgSrc={usersImgSrc}
          />
        </div>
        <CreateNewBtn
          onClick={() => {
            dispatch(setSelectedTableRow(null));
            dispatch(setWebsitePackageFormOpenedAction(true));
          }}
          btnText="باقة جديدة"
        />
      </div>
    </div>
  );
};

export default WebsitePackagesPageFilter;
