import { Text, View } from '@react-pdf/renderer';
import React from 'react';

const InvoiceTable = ({ tableData }) => {
  return (
    <View
      style={{
        borderBottomWidth: 1,
        borderBottomStyle: 'solid',
        borderBottomColor: '#bbb'
      }}
    >
      <View
        style={{
          marginTop: 12,
          padding: '12 22',
          fontSize: 12,
          backgroundColor: '#404041',
          color: '#fff',
          display: 'flex',
          flexDirection: 'row-reverse',
          justifyContent: 'space-between'
        }}
      >
        <Text
          style={{
            width: '16%',
            padding: '0 4',
            textAlign: 'center'
          }}
        >
          السلعة/الخدمة
        </Text>

        <Text
          style={{
            width: '14%',
            padding: '0 4',
            textAlign: 'center'
          }}
        >
          الخدمة
        </Text>
        <Text
          style={{
            width: '14%',
            padding: '0 4',
            textAlign: 'center'
          }}
        >
          الكمية
        </Text>
        <Text
          style={{
            width: '14%',
            padding: '0 4',
            textAlign: 'center'
          }}
        >
          الاجمالي
        </Text>
        <Text
          style={{
            width: '14%',
            padding: '0 4',
            textAlign: 'center'
          }}
        >
          ضريبة %
        </Text>
        <Text
          style={{
            width: '14%',
            padding: '0 4',
            textAlign: 'center'
          }}
        >
          الضريبة
        </Text>
        <Text
          style={{
            width: '14%',
            padding: '0 4',
            textAlign: 'center'
          }}
        >
          الاجمالي
        </Text>
      </View>
      {tableData?.length > 0 &&
        tableData?.map((item) => (
          <View
            style={{
              padding: '12 22',
              fontSize: 12,
              color: '#000',
              display: 'flex',
              flexDirection: 'row-reverse',
              justifyContent: 'space-between'
            }}
          >
            <Text
              style={{
                width: '16%',
                padding: '0 4',
                textAlign: 'center'
              }}
            >
              {item?.product?.name}
            </Text>

            <Text
              style={{
                width: '14%',
                padding: '0 4',
                textAlign: 'center'
              }}
            >
              {item?.unitPrice}
            </Text>
            <Text
              style={{
                width: '14%',
                padding: '0 4',
                textAlign: 'center'
              }}
            >
              {item?.quantity}
            </Text>
            <Text
              style={{
                width: '14%',
                padding: '0 4',
                textAlign: 'center'
              }}
            >
              {item?.product?.price}
            </Text>
            <Text
              style={{
                width: '14%',
                padding: '0 4',
                textAlign: 'center'
              }}
            >
              {item?.tax} %
            </Text>
            <Text
              style={{
                width: '14%',
                padding: '0 4',
                textAlign: 'center'
              }}
            >
              {item?.taxPrice}{' '}
            </Text>
            <Text
              style={{
                width: '14%',
                padding: '0 4',
                textAlign: 'center'
              }}
            >
              {item?.totalPrice}{' '}
            </Text>
          </View>
        ))}
    </View>
  );
};

export default InvoiceTable;
