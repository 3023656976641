import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  createAdminAction,
  deleteAdminAction,
  getAdminsAction,
  getSingleAdminAction,
  toggleAdminAction,
  updateAdminAction
} from './settingsSliceThunkApi';

export const deviceOrBrand = {
  device: 1,
  brand: 2
};

const initialState = {
  /////////////////////
  isLoadingTableData: false,
  fetchedSingleAdmin: null,
  fetchedTableData: [],
  fetchDataCount: 0,
  deleteModalOpened: false,
  isSubmitting: false,
  selectedTableRow: null,
  confirmModal: {
    opened: false,
    type: ''
  },
  //
  selectedTab: deviceOrBrand?.device,
  tablePagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  }
};

const settingsSlice = createSlice({
  name: 'settingsSlice',
  initialState,
  reducers: {
    setDeleteModalOpened: (sliceState, action) => {
      sliceState.deleteModalOpened = action.payload;
    },
    setConfirmModal: (sliceState, action) => {
      sliceState.confirmModal = {
        opened: action.payload.opened,
        type: action.payload.type
      };
    },
    setSelectedTableRow: (sliceState, action) => {
      sliceState.selectedTableRow = action.payload;
    },
    setSelectedTab: (sliceState, action) => {
      sliceState.selectedTab = action.payload;
    }
  },
  extraReducers: {
    [getAdminsAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getAdminsAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedTableData = data?.data;
      if (data?.pagination) state.tablePagination = data.pagination;
    },
    [getAdminsAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    // get single admin
    [getSingleAdminAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getSingleAdminAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedSingleAdmin = data;
    },
    [getSingleAdminAction.rejected]: (state, action) => {
      state.isLoadingTableData = false;
    },
    //
    // create admin
    [createAdminAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [createAdminAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      const cb = action.payload?.cb;
      if (checkRes(res)) {
        // state.fetchDataCount += 1;
        if (cb && typeof cb === 'function') cb();
      }
    },
    [createAdminAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // update admin
    [updateAdminAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [updateAdminAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        // state.selectedTableRow = null;
        // state.fetchDataCount += 1;
        // state.packageFormOpened = false;
      }
    },
    [updateAdminAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // toggle admin
    [toggleAdminAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [toggleAdminAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchDataCount += 1;
        state.confirmModal = {
          opened: false,
          type: ''
        };
      }
    },
    [toggleAdminAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    //
    [deleteAdminAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [deleteAdminAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.deleteModalOpened = false;
      }
    },
    [deleteAdminAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
    //
  }
});

const settingsReducer = settingsSlice.reducer;

export const {
  setDeleteModalOpened,
  setSelectedTableRow,
  setConfirmModal,
  setSelectedTab
} = settingsSlice.actions;

export default settingsReducer;
