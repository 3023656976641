import { Modal } from 'antd';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';
import CancelCircledIcon from '../icons/CancelCircledIcon';
import CheckmarkCircledIcon from '../icons/CheckmarkCircledIcon';
import './ConfirmModal.scss';
const ConfirmModal = ({
  modalOpened,
  setModalOpened,
  children,
  handleAccept,
  modalTitle,
  modalType = confirmModalTypesValues?.successModal
}) => {
  return (
    <Modal
      className={`shared-custom-modal confirm-modal ${
        modalType === confirmModalTypesValues?.successModal
          ? 'success-modal'
          : ''
      } ${
        modalType === confirmModalTypesValues?.errorModal ? 'error-modal' : ''
      }`}
      width="96%"
      style={{ maxWidth: '542px' }}
      title={modalTitle || ''}
      open={modalOpened}
      // onOk={() => {
      //   setModalOpened(false);
      // }}
      onCancel={() => {
        setModalOpened(false);
      }}
      footer={false}
    >
      {children}

      <div className="modal-action-btns-wrapper">
        <button
          className="accept-btn"
          onClick={() => {
            handleAccept();
          }}
        >
          <CheckmarkCircledIcon />
          نعم
        </button>
        <button
          onClick={() => {
            setModalOpened(false);
          }}
          className="cancel-btn"
        >
          <CancelCircledIcon color="#404041" />
          لا
        </button>
      </div>
    </Modal>
  );
};

export default ConfirmModal;
