/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import './WebsiteServiceForm.scss';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import FileInput from '../../common/file-input/FileInput';
import AntdTextarea from '../../common/antd-form-components/AntdTextarea';
import {
  createWebsiteServiceAction,
  updateWebsiteServiceAction
} from '../../redux/website-services-slice/websiteServiceSliceThunkApi';
import checkRes from '../../utils/checkRes';
import { setSelectedTableRow } from '../../redux/website-services-slice/websiteServicesSlice';
import sharedRouterLinks from '../../components/app/shared-routes/sharedRouterLinks';
import { useLocation } from 'react-router-dom';

const schema = Yup.object().shape({
  name_ar: Yup.string().required('من فضلك ادخل الاسم بالعربية'),
  name_en: Yup.string().required('من فضلك ادخل الاسم بالانجليزية'),
  desc_ar: Yup.string().required('من فضلك ادخل التفاصيل بالعربية'),
  desc_en: Yup.string().required('من فضلك ادخل التفاصيل بالانجليزية')
});

const { medalIcon } = imagesSrcs;

const emptyFormValues = {
  name_ar: '',
  name_en: '',
  desc_ar: '',
  desc_en: '',
  logo: null
};

const WebsiteServiceForm = ({ fetchedTableData, submitAction }) => {
  const { pathname } = useLocation();
  const [files, setFiles] = useState(null);
  const dispatch = useDispatch();
  const selectedTableRow = useSelector(
    (store) => store.websiteServices.selectedTableRow
  );
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    register,
    unregister,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: emptyFormValues
  });

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      setValue('name_ar', selectedTableRow?.name_ar || '');
      setValue('name_en', selectedTableRow?.name_en || '');
      setValue('desc_ar', selectedTableRow?.desc_ar || '');
      setValue('desc_en', selectedTableRow?.desc_en || '');
    }
  }, [selectedTableRow, fetchedTableData]);
  const onSubmit = async (data) => {
    const formData = new FormData();
    formData.append('name_ar', data.name_ar || '');
    formData.append('name_en', data.name_en || '');
    formData.append('desc_ar', data.desc_ar || '');
    formData.append('desc_en', data.desc_en || '');
    if (data?.logo?.length > 0) {
      formData.append('logo', data.logo[0]);
    }
    if (pathname === sharedRouterLinks?.websitesServicesFeatruesRoute)
      formData.append('type', 2);
    else {
      formData.append('type', 1);
    }

    if (!selectedTableRow) {
      const action = await dispatch(
        createWebsiteServiceAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action?.payload?.res;
      if (checkRes(res)) {
        reset(emptyFormValues);
        setFiles(null);
      }
    } else {
      formData.append('id', selectedTableRow?.id || '');

      const action = await dispatch(
        updateWebsiteServiceAction({
          dataToBeSubmitted: formData
        })
      );
      const res = action?.payload?.res;
      if (checkRes(res)) {
        reset(emptyFormValues);
        setFiles(null);
      }
    }
  };

  const [form] = Form.useForm();
  return (
    <Form
      className="custom-shared-form website-service-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <div className="form-body">
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>إســم الخدمة بالعربية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="name_ar"
            type="text"
            placeholder={'إســم الخدمة بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.name_ar?.message}
            validateStatus={errors?.name_ar ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>إســم الخدمة بالانجليزية</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="name_en"
            type="text"
            placeholder={'إســم الخدمة بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.name_en?.message}
            validateStatus={errors?.name_en ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="shared-app-text-field-label-wrapper is-text-area">
          <p className="outside-label">
            <img src={imagesSrcs?.deviceIcon} alt="name" />
            <span>تفاصيل الخدمة بالعربية</span>
          </p>
          <AntdTextarea
            className="form-text-field"
            rows={5}
            name="desc_ar"
            type="text"
            placeholder={'تفاصيل الخدمة بالعربية...'}
            // label="الاســــم"
            errorMsg={errors?.desc_ar?.message}
            validateStatus={errors?.desc_ar ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="shared-app-text-field-label-wrapper is-text-area">
          <p className="outside-label">
            <img src={imagesSrcs?.deviceIcon} alt="name" />
            <span>تفاصيل الخدمة بالانجليزية</span>
          </p>
          <AntdTextarea
            className="form-text-field"
            rows={5}
            name="desc_en"
            type="text"
            placeholder={'تفاصيل الخدمة بالانجليزية...'}
            // label="الاســــم"
            errorMsg={errors?.desc_en?.message}
            validateStatus={errors?.desc_en ? 'error' : ''}
            control={control}
          />
        </div>

        <div className="file-inputs-wrap">
          <FileInput
            name="logo"
            // accept="image/png, image/jpg, image/jpeg, image/gif"
            label={
              <p
                className="outside-label"
                style={{
                  color: '#404041',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  marginBottom: 8,
                  paddingRight: 8
                }}
              >
                <img src={imagesSrcs?.imageIcon} alt="name" />
                صورة الخدمة
              </p>
            }
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الصورة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedTableRow?.logo ? [{ url: selectedTableRow.logo }] : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.logo?.message}
            files={files}
            setFiles={setFiles}
          />
        </div>

        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            type="button"
            onClick={() => {
              reset(emptyFormValues);
              dispatch(setSelectedTableRow(null));
              setFiles(null);
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {/* {isLoadingSignin && <LoadingModal />} */}
    </Form>
  );
};

export default WebsiteServiceForm;
