/* eslint-disable react-hooks/exhaustive-deps */
import { AnimatePresence, motion } from 'framer-motion';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMediaQuery } from 'react-responsive';
import { useLocation } from 'react-router-dom';
import ConfirmModal from '../../common/confirm-modal/ConfirmModal';
import PlusIcon from '../../common/icons/PlusIcon';
import Xicon from '../../common/icons/Xicon';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';
import {
  setBrandFormOpenedAction,
  setDeleteModalOpened as setDeleteBrandModalOpened,
  setIsLoadingBrands,
  setSelectedTableRow as setSelectedBrandTableRow
} from '../../redux/brands-slice/brandsSlice';
import {
  deleteBrandAction,
  getBrandsAction
} from '../../redux/brands-slice/brandsSliceThunkApis';
import {
  setDeleteModalOpened as setDeleteDeviceModalOpened,
  setDeviceFormOpenedAction,
  setFetchedCountries,
  setIsLoadingDevices,
  setSelectedTableRow as setSelectedDeviceTableRow
} from '../../redux/devices-slice/devicesSlice';
import {
  deleteDeviceAction,
  getCountries,
  getDevicesAction
} from '../../redux/devices-slice/devicesSliceThunkApis';
import {
  deviceOrBrand,
  setConfirmModal,
  setDeleteModalOpened,
  setSelectedTab,
  setSelectedTableRow
} from '../../redux/packages-slice/appPackagesSlice';
import {
  deleteAppPackageAction,
  getPackagesAction,
  toggleAppPackageAction
} from '../../redux/packages-slice/appPackagesSliceThunkApi';
import sleep from '../../utils/sleep';
import './DevicesPage.scss';
import AppPackagesFilter from './AppPackagesPageFilter';
import AppPackagesPageTable from './AppPackagesPageTable';
import BrandModal from './BrandModal';
import BrandsTable from './BrandsTable';
import DeviceDescription from './DeviceDescription';
import DeviceForm from './DeviceForm';
import DevicesTable from './DevicesTable';
import PackageForm from './PackageForm';
import axios from 'axios';
import apisUrls from '../../apis/urls';

const DevicesPage = () => {
  const isDesktop = useMediaQuery({
    query: '(min-width: 1200px)'
  });
  const dispatch = useDispatch();
  const opened = useSelector((store) => store.appPackages.confirmModal.opened);
  const deleteModalOpened = useSelector(
    (store) => store.appPackages.deleteModalOpened
  );
  const type = useSelector((store) => store.appPackages.confirmModal.type);
  const isLoadingTableData = useSelector(
    (store) => store.appPackages.isLoadingTableData
  );
  const selectedTableRow = useSelector(
    (store) => store.appPackages.selectedTableRow
  );
  const packageFormOpened = useSelector(
    (store) => store.appPackages.packageFormOpened
  );
  const watchPackageDevice = useSelector(
    (store) => store.appPackages.watchPackageDevice
  );
  const selectedBrandRow = useSelector(
    (store) => store.brands.selectedTableRow
  );
  const selectedDeviceRow = useSelector(
    (store) => store.devices.selectedTableRow
  );
  const fetchDataCount = useSelector(
    (store) => store.appPackages.fetchDataCount
  );
  const fetchBrandsCount = useSelector((store) => store.brands.fetchDataCount);
  const fetchDevicesCount = useSelector(
    (store) => store.devices.fetchDataCount
  );
  const selectedTab = useSelector((store) => store.appPackages.selectedTab);
  const isSubmitting = useSelector((store) => store.appPackages.isSubmitting);
  const isSubmittingBrands = useSelector((store) => store.brands.isSubmitting);
  const isSubmittingDevices = useSelector(
    (store) => store.devices.isSubmitting
  );
  const deviceFormOpened = useSelector(
    (store) => store.devices.deviceFormOpened
  );
  const deleteBrandModalOpened = useSelector(
    (store) => store.brands.deleteModalOpened
  );
  const deleteDeviceModalOpened = useSelector(
    (store) => store.devices.deleteModalOpened
  );
  const fetchedDevices = useSelector((store) => store.devices.fetchedDevices);
  const fetchedBrands = useSelector((store) => store.brands.fetchedBrands);
  const brandFormOpened = useSelector((store) => store.brands.brandFormOpened);
  const { search } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [search]);

  let isMounted = true;

  useEffect(() => {
    if (isMounted) dispatch(getBrandsAction());
    return function () {
      isMounted = false;
    };
  }, [fetchBrandsCount]);
  useEffect(() => {
    if (isMounted) dispatch(getDevicesAction());
    return function () {
      isMounted = false;
    };
  }, [fetchDevicesCount]);

  //get countries
  const getCountries = async () => {
    try {
      await axios.get(apisUrls.getCountries).then((res) => {
     
        dispatch(setFetchedCountries(res?.data?.data));
      });
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    if (isMounted) {
      getCountries();
    }
    return function () {
      isMounted = false;
    };
  }, []);

  const handleDeleteBrand = () => {
    if (isMounted && selectedBrandRow?.id) {
      // const formData = new FormData();
      // formData.append('brand_id', selectedBrandRow.id);
      dispatch(
        deleteBrandAction({
          dataToBeSubmitted: selectedBrandRow.id
        })
      );
    }
  };
  const handleDeleteDevice = () => {
    if (isMounted && selectedDeviceRow?.id) {
 
      dispatch(
        deleteDeviceAction({
          dataToBeSubmitted: selectedDeviceRow.id
        })
      );
    }
  };

  // if (isLoadingTableData) {
  //   return (
  //     <div className="app-packages-page">
  //       <div
  //         style={{
  //           display: 'flex',
  //           justifyContent: 'center',
  //           alignItems: 'center',
  //         }}
  //         className="loading-wrapper"
  //       >
  //         <LoadingOutlined style={{ fontSize: 24 }} spin />
  //       </div>
  //     </div>
  //   );
  // }
  return (
    <div className="app-packages-page">
      <div className="page-content-devices-wrapper">
        <div>
          {/* {isDesktop ? (
            <AnimatePresence>
              {!deviceFormOpened && !packageFormOpened && (
                <motion.div
                  // layout
                  initial={{
                    height: 0,
                    opacity: 0,
                    translateX: 700,
                    transition: {
                      opacity: { ease: 'linear', duration:.3 },
                      translateX: { ease: 'linear', duration:.3 }
                    }
                  }}
                  animate={{
                    opacity: 1,
                    translateX: 0,
                    transition: {
                      delay: 0.7,
                      opacity: { ease: 'linear', duration:.3 },
                      translateX: { ease: 'linear', duration:.3 }
                    }
                  }}
                  exit={{
                    opacity: 0,
                    translateX: 700,
                    transition: {
                      opacity: { ease: 'linear', duration:.3 },
                      translateX: { ease: 'linear', duration:.3 }
                    }
                  }}
                  className="page-main-content"
                >
                  <div className="page-title">الباقات والاشتراكات</div>
                  <AppPackagesFilter />
                  <AppPackagesPageTable />
                </motion.div>
              )}
            </AnimatePresence>
          ) : (
            !deviceFormOpened &&
            !packageFormOpened && (
              <div className="page-main-content">
                <div className="page-title">الباقات والاشتراكات</div>
                <AppPackagesFilter />
                <AppPackagesPageTable />
              </div>
            )
          )} */}

          {/* DEVICES FORM */}
          <AnimatePresence>
            {true && (
              <motion.div
                initial={{
                  opacity: 0,
                  translateX: 700
                }}
                animate={{
                  opacity: 1,
                  translateX: 0,
                  transition: {
                    delay: 0.6,
                    opacity: { ease: 'linear', duration: 0.3 },
                    width: { ease: 'linear', duration: 0.3 },
                    translateX: { ease: 'linear', duration: 0.3 }
                  }
                }}
                exit={{
                  opacity: 0,
                  translateX: 700,
                  transition: {
                    opacity: { ease: 'linear', duration: 0.3 },
                    width: { ease: 'linear', duration: 0.3 },
                    translateX: { ease: 'linear', duration: 0.3 }
                  }
                }}
                // transition={{
                //   delay: 0.7,
                //   opacity: { ease: 'linear', duration:.3 },
                //   width: { ease: 'linear', duration:.3 },
                //   translateX: { ease: 'linear', duration:.3 }
                // }}
                className="device-form-wrapper"
              >
                <DeviceForm />
              </motion.div>
            )}
          </AnimatePresence>
        </div>

        {/* PAGE SIDE CONTENT */}

        {/* DEVICES LIST */}
        <div className="devices-add-device-form-device-desc-wrapper">
          {/* {packageFormOpened && ( */}
          {/* <AnimatePresence>
            <motion.div
              initial={{
                opacity: 0,
                translateX: -700,
                transition: {
                  opacity: { ease: 'linear', duration: 0.3 },
                  translateX: { ease: 'linear', duration: 0.3 }
                }
              }}
              animate={{
                opacity: 1,
                translateX: 0,
                transition: {
                  delay: 0.7,
                  opacity: { ease: 'linear', duration: 0.3 },
                  translateX: { ease: 'linear', duration: 0.3 }
                }
              }}
              exit={{
                opacity: 0,
                translateX: -700,
                transition: {
                  opacity: { ease: 'linear', duration: 0.3 },
                  translateX: { ease: 'linear', duration: 0.3 }
                }
              }}
            >
              <DeviceDescription device={watchPackageDevice} />
            </motion.div>
          </AnimatePresence> */}

          <AnimatePresence>
            <motion.div
              initial={{
                translateX: -700,
                transitionDelay: 0.8,
                transition: {
                  opacity: { ease: 'linear', duration: 0.3 },
                  translateX: { ease: 'linear', duration: 0.3 }
                }
              }}
              animate={{
                display: 'grid',
                translateX: 0,
                transition: {
                  delay: 0.2,
                  opacity: { ease: 'linear', duration: 0.3 },
                  translateX: { ease: 'linear', duration: 0.3 }
                }
              }}
              exit={{
                display: 'none',
                // translateX: -700,
                transition: {
                  opacity: { ease: 'linear', duration: 0.3 },
                  width: { ease: 'linear', duration: 0.3 },
                  translateX: { ease: 'linear', duration: 0.3 }
                }
              }}
              className="page-devices-wrapper"
            >
              <p className="section-title">
                <button
                  className={`${
                    selectedTab === deviceOrBrand?.device ? 'active' : ''
                  }`}
                  onClick={async () => {
                    dispatch(setSelectedTab(deviceOrBrand?.device));
                    dispatch(setIsLoadingDevices(true));
                    await sleep(300);
                    dispatch(setIsLoadingDevices(false));
                  }}
                >
                  الأجهزة المستخدمة
                </button>
                <button
                  className={`${
                    selectedTab === deviceOrBrand?.brand ? 'active' : ''
                  }`}
                  onClick={async () => {
                    dispatch(setSelectedTab(deviceOrBrand?.brand));
                    dispatch(setIsLoadingBrands(true));
                    await sleep(300);
                    dispatch(setIsLoadingBrands(false));
                  }}
                >
                  براندات الاجهزة
                </button>
              </p>
              {selectedTab === deviceOrBrand?.device ? (
                <>
                  <DevicesTable fetchedDevices={fetchedDevices} />
                  {/* <motion.button
                      type="button"
                      onClick={() =>
                        dispatch(setDeviceFormOpenedAction(!deviceFormOpened))
                      }
                      animate={{
                        backgroundColor: deviceFormOpened ? 'red' : '#2a9d7c',
                        rotate: deviceFormOpened ? '-180deg' : 0,
                        transition: {
                          backgroundColor: { ease: 'easeOut', duration: 0.3 },
                          rotate: { ease: 'easeOut', duration: 0.3 }
                        }
                      }}
                      className={`add-device-btn ${
                        deviceFormOpened ? 'with-device-form' : ''
                      }`}
                    >
                      {deviceFormOpened ? (
                        <Xicon color="#fff" />
                      ) : (
                        <PlusIcon color="#fff" />
                      )}
                    </motion.button> */}
                </>
              ) : (
                <>
                  <BrandsTable fetchedBrands={fetchedBrands} />
                  <button
                    type="button"
                    onClick={() => dispatch(setBrandFormOpenedAction(true))}
                    className="add-brand-btn"
                  >
                    <PlusIcon color="#fff" />
                  </button>
                </>
              )}
            </motion.div>
          </AnimatePresence>
        </div>

        {/* END PAGE SIDE CONTENT */}
        {/* <AnimatePresence>
          <motion.div
            initial={{
              opacity: 0,
              translateX: -700,
              transition: {
                opacity: { ease: 'linear', duration: 0.3 },
                translateX: { ease: 'linear', duration: 0.3 }
              }
            }}
            animate={{
              opacity: 1,
              translateX: 0,
              transition: {
                delay: 0.7,
                opacity: { ease: 'linear', duration: 0.3 },
                translateX: { ease: 'linear', duration: 0.3 }
              }
            }}
            exit={{
              opacity: 0,
              translateX: -700,
              transition: {
                opacity: { ease: 'linear', duration: 0.3 },
                translateX: { ease: 'linear', duration: 0.3 }
              }
            }}
          > */}
        <BrandModal
          modalOpened={brandFormOpened}
          setModalOpened={dispatch(setBrandFormOpenedAction)}
        />
        {/* </motion.div>
        </AnimatePresence> */}
      </div>

      {/*  */}
      {/*  */}
      {/*  */}
      {/*  */}

      {/* delete brand modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteBrandModalOpened}
        modalType={confirmModalTypesValues?.errorModal}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedBrandTableRow(null));
          }
          dispatch(setDeleteBrandModalOpened(v));
        }}
        handleAccept={handleDeleteBrand}
      >
        <h3>{`هل أنت متأكد من حذف البراند ${selectedBrandRow?.name || ''}`}</h3>
      </ConfirmModal>
      {/* ende delete brand modal */}

      {/* delete device modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteDeviceModalOpened}
        modalType={confirmModalTypesValues?.errorModal}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedDeviceTableRow(null));
          }
          dispatch(setDeleteDeviceModalOpened(v));
        }}
        handleAccept={handleDeleteDevice}
      >
        <h3>{`هل أنت متأكد من حذف الجهاز ${selectedDeviceRow?.name || ''}`}</h3>
      </ConfirmModal>
      {/* ende delete device modal */}

      {(isLoadingTableData ||
        isSubmitting ||
        isSubmittingBrands ||
        isSubmittingDevices) && <LoadingModal />}
    </div>
  );
};

export default DevicesPage;
