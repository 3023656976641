/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import ProfileAdminsTable from './ProfileAdminsTable';
import ProfileInfoSection from './ProfileInfoSection';
import queryString from 'query-string';
import {
  deleteAdminAction,
  getAdminsAction,
  toggleAdminAction
} from '../../redux/settings-slice/settingsSliceThunkApi';
import ConfirmModal from '../../common/confirm-modal/ConfirmModal';
import {
  setConfirmModal,
  setDeleteModalOpened,
  setSelectedTableRow
} from '../../redux/settings-slice/settingsSlice';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import UserContext from '../../contexts/user-context/UserProvider';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import myInfoApi from '../../apis/auth/myInfoApi';
import { useTranslation } from 'react-i18next';
import checkRes from '../../utils/checkRes';
import routerLinks from '../../components/app/routerLinks';

const ProfilePage = () => {
  const dispatch = useDispatch();
  const { i18n } = useTranslation();
  const navigate = useNavigate();
  const { search } = useLocation();
  const values = queryString.parse(search);

  const fetchDataCount = useSelector((store) => store.settings.fetchDataCount);
  const isSubmitting = useSelector((store) => store.settings.isSubmitting);
  const isLoadingTableData = useSelector(
    (store) => store.settings.isLoadingTableData
  );
  const type = useSelector((store) => store.settings.confirmModal.type);
  const opened = useSelector((store) => store.settings.confirmModal.opened);
  const deleteModalOpened = useSelector(
    (store) => store.settings.deleteModalOpened
  );
  const selectedTableRow = useSelector(
    (store) => store.settings.selectedTableRow
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [search]);

  const { user, setCurrentUser, removeCurrentUser } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  useEffect(() => {
    let isMounted = true;
    if (user) {
      customApiRequest(
        myInfoApi(user?.token, i18n.language),
        (res) => {
          if (isMounted) {
            if (checkRes(res)) {
              setCurrentUser({
                ...res?.data?.data,
                token: user?.token,
                accountType: user.accountType,
                activated: user.activated
              });
            } else {
              removeCurrentUser();
              navigate(routerLinks?.signinPage);
            }
          }
        },
        (error) => {}
      );
    }
    return () => {
      isMounted = false;
    };
  }, []);

  useEffect(() => {
    let isMounted = true;
    if (isMounted)
      dispatch(
        getAdminsAction({
          filterObj: values
        })
      );

    return function () {
      isMounted = false;
    };
  }, [fetchDataCount, search]);

  let isMounted = true;
  const handleToggleAdmin = () => {
    if (isMounted && selectedTableRow?.id) {
      const formData = new FormData();
      formData.append('admin_id', selectedTableRow.id);
      dispatch(
        toggleAdminAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };
  const handleDeleteAdmin = () => {
    if (isMounted && selectedTableRow?.id) {
      const formData = new FormData();
      formData.append('admin_id', selectedTableRow.id);
      dispatch(
        deleteAdminAction({
          dataToBeSubmitted: formData
        })
      );
    }
  };

  return (
    <div className="profile-page">
      <ProfileInfoSection />

      <ProfileAdminsTable />

      {/* delete admin modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteModalOpened}
        modalType={confirmModalTypesValues?.errorModal}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedTableRow(null));
          }
          dispatch(setDeleteModalOpened(v));
        }}
        handleAccept={handleDeleteAdmin}
      >
        <h3>{`هل انت متأكد من حذف المستخدم ${
          selectedTableRow?.name || ''
        }`}</h3>
      </ConfirmModal>
      {/* end delete admin modal */}

      {/* confirm admin modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={opened}
        modalType={type}
        setModalOpened={(v) => {
          if (!v) {
            dispatch(setSelectedTableRow(null));
          }
          dispatch(
            setConfirmModal({
              opened: v,
              type: ''
            })
          );
        }}
        handleAccept={handleToggleAdmin}
      >
        {type === confirmModalTypesValues?.successModal && (
          <h3>{`هل انت متأكد من تفعيل المستخدم ${
            selectedTableRow?.name || ''
          }`}</h3>
        )}
        {type === confirmModalTypesValues?.errorModal && (
          <h3>{`هل انت متأكد من ايقاف عمل المستخدم ${
            selectedTableRow?.name || ''
          }`}</h3>
        )}
      </ConfirmModal>
      {/* end confirm admin modal */}

      {(isSubmitting || isLoadingTableData) && <LoadingModal />}
    </div>
  );
};

export default ProfilePage;
