import axios from 'axios';

const changePasswordApi = async (values) => {
  try {
    const signinRes = await axios.post('/auth/changePassword', values, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
    return signinRes;
  } catch (error) {
    throw error;
  }
};

export default changePasswordApi;
