import React from 'react';

const SmillesIcon = ({ color, width, height, ...props }) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3333 19.6666H8.26667C3.70667 19.6666 1.66666 17.6267 1.66666 13.0667V8.26656C1.66666 3.70656 3.70667 1.66663 8.26667 1.66663H13.0667C17.6267 1.66663 19.6667 3.70656 19.6667 8.26656V13.3333C19.6667 13.88 19.2267 14.32 18.68 14.3333C15.48 14.3866 14.3867 15.4933 14.3333 18.68C14.32 19.2266 13.88 19.6666 13.3333 19.6666ZM8.26667 3.66663C4.82667 3.66663 3.66666 4.82656 3.66666 8.26656V13.0667C3.66666 16.5067 4.82667 17.6666 8.26667 17.6666H12.3867C12.7067 14.4133 14.4133 12.72 17.6667 12.3867V8.26656C17.6667 4.82656 16.5067 3.66663 13.0667 3.66663H8.26667Z"
        fill={color || '#404041'}
      />
      <path
        d="M6.93334 8.84007C6.62667 8.84007 6.30667 8.69347 6.12001 8.41347C5.80001 7.96014 5.90667 7.33341 6.36001 7.01341C7.40001 6.28007 8.8 6.26671 9.85334 6.98671C10.3067 7.29338 10.4267 7.9201 10.12 8.37343C9.81334 8.82676 9.18667 8.9467 8.73334 8.64003C8.37334 8.40003 7.89334 8.40005 7.53334 8.65338C7.33334 8.78671 7.13334 8.84007 6.93334 8.84007Z"
        fill={color || '#404041'}
      />
      <path
        d="M12.2667 8.84007C11.96 8.84007 11.64 8.69347 11.4533 8.41347C11.1333 7.96014 11.24 7.33341 11.6933 7.01341C12.7333 6.28007 14.1333 6.26671 15.1867 6.98671C15.64 7.29338 15.76 7.9201 15.4533 8.37343C15.1467 8.82676 14.52 8.9467 14.0667 8.64003C13.7067 8.40003 13.2267 8.40005 12.8667 8.65338C12.6667 8.78671 12.4667 8.84007 12.2667 8.84007Z"
        fill={color || '#404041'}
      />
      <path
        d="M10.8797 16.2267H7.78641C6.83974 16.2267 6.06641 15.4533 6.06641 14.5067C6.06641 11.9733 8.13307 9.90674 10.6664 9.90674C11.7064 9.90674 12.7331 10.2666 13.5464 10.9199C13.9731 11.2666 14.0531 11.8933 13.7064 12.32C13.3597 12.7467 12.7331 12.8133 12.3064 12.48C11.8397 12.1066 11.2797 11.9067 10.6797 11.9067C9.34641 11.9067 8.23974 12.9201 8.09307 14.2267H10.8931C11.4397 14.2267 11.8931 14.6801 11.8931 15.2267C11.8931 15.7734 11.4264 16.2267 10.8797 16.2267Z"
        fill={color || '#404041'}
      />
      <path
        d="M23.7333 30.3334H18.9333C14.3733 30.3334 12.3333 28.2934 12.3333 23.7334V18.6667C12.4133 14.4134 14.4133 12.4134 18.6533 12.3334H23.7333C28.2933 12.3334 30.3333 14.3733 30.3333 18.9333V23.7334C30.3333 28.2934 28.2933 30.3334 23.7333 30.3334ZM18.6667 14.3334C15.4933 14.3867 14.3867 15.4934 14.3333 18.6801V23.7334C14.3333 27.1734 15.4933 28.3334 18.9333 28.3334H23.7333C27.1733 28.3334 28.3333 27.1734 28.3333 23.7334V18.9333C28.3333 15.4933 27.1733 14.3334 23.7333 14.3334H18.6667Z"
        fill={color || '#404041'}
      />
      <path
        d="M18.7867 19.5067C18.16 19.5067 17.5467 19.3199 17.0267 18.9466C16.5733 18.6266 16.4667 18 16.7867 17.5467C17.1067 17.0934 17.7333 16.9866 18.1867 17.3066C18.5467 17.56 19.0267 17.56 19.3867 17.32C19.84 17.0133 20.4667 17.1199 20.7733 17.5866C21.08 18.0399 20.9733 18.6666 20.5067 18.9733C19.9867 19.3333 19.3867 19.5067 18.7867 19.5067Z"
        fill={color || '#404041'}
      />
      <path
        d="M24.12 19.5067C23.4933 19.5067 22.88 19.3199 22.36 18.9466C21.9067 18.6266 21.8 18 22.12 17.5467C22.44 17.0934 23.0667 16.9866 23.52 17.3066C23.88 17.56 24.36 17.56 24.72 17.32C25.1733 17.0133 25.8 17.1199 26.1067 17.5866C26.4133 18.0399 26.3067 18.6666 25.84 18.9733C25.32 19.3333 24.72 19.5067 24.12 19.5067Z"
        fill={color || '#404041'}
      />
      <path
        d="M21.3331 26.8934C18.7997 26.8934 16.7331 24.8266 16.7331 22.2933C16.7331 21.3466 17.5064 20.5734 18.4531 20.5734H24.2131C25.1597 20.5734 25.9331 21.3466 25.9331 22.2933C25.9331 24.8266 23.8664 26.8934 21.3331 26.8934ZM18.7464 22.5734C18.8797 23.88 19.9864 24.8934 21.3331 24.8934C22.6797 24.8934 23.7731 23.88 23.9197 22.5734H18.7464Z"
        fill={color || '#404041'}
      />
    </svg>
  );
};

export default SmillesIcon;
