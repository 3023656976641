import axios from 'axios';
import apisUrls from '../urls';

const createAppPackageApi = (values, edit = false,id) => {
  console.log(id);
  
  const url = !edit ? apisUrls?.createAppPackage : `${apisUrls?.updateAppPackage}?package_id=${id}`;

  return axios.post(url, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export default createAppPackageApi;
