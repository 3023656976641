import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  setBrandFormOpenedAction,
  setSelectedTableRow
} from '../../redux/brands-slice/brandsSlice';
import BrandForm from './BrandForm';
import './BrandModal.scss';
const BrandModal = ({ modalOpened, setModalOpened }) => {
  const selectedBrandTableRow = useSelector(
    (store) => store.brands.selectedTableRow
  );
  const dispatch = useDispatch();
  return (
    <Modal
      destroyOnClose
      className={`shared-custom-modal`}
      width="96%"
      style={{ maxWidth: '542px' }}
      title={selectedBrandTableRow ? 'تعديل البراند' : 'إضافة براند جديد'}
      open={modalOpened}
      onOk={() => {
        setModalOpened(false);
      }}
      onCancel={() => {
        dispatch(setBrandFormOpenedAction(false));
        dispatch(setSelectedTableRow(null));
      }}
      footer={false}
    >
      <BrandForm />
    </Modal>
  );
};

export default BrandModal;
