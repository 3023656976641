import { createSlice } from '@reduxjs/toolkit';
import checkRes from '../../utils/checkRes';
import {
  createDeviceAction,
  deleteDeviceAction,
  updateDeviceAction,
  getDevicesAction
} from './devicesSliceThunkApis';

const initialState = {
  isLoadingJsonData: false,
  jsonData: [],
  /////////////////////
  isLoadingTableData: false,
  fetchedDevices: [],
  fetchDataCount: 0,
  fetchedCountries: [],
  deviceFormOpened: true,
  deleteModalOpened: false,
  isSubmitting: false,
  selectedTableRow: null,
  tablePagination: {
    current_page: 1,
    par_page: 0,
    total: 0
  }
};

const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setIsLoadingDevices: (stateSlice, action) => {
      stateSlice.isLoadingTableData = action.payload;
    },
    setDeviceFormOpenedAction: (sliceState, action) => {
      sliceState.deviceFormOpened = action.payload;
      if (action.payload === false) {
        sliceState.selectedTableRow = null;
      }
      window.scrollTo(0, 0);
    },
    setDeleteModalOpened: (sliceState, action) => {
      sliceState.deleteModalOpened = action.payload;
    },
    setSelectedTableRow: (sliceState, action) => {
      sliceState.selectedTableRow = action.payload;
    },
    setFetchDataCount: (sliceState, action) => {
      sliceState.fetchedCountries = action.payload;
    },
    setFetchedCountries: (sliceState, action) => {
      sliceState.fetchedCountries = action.payload;
    }
  },

  extraReducers: {
    // get all devivces
    [getDevicesAction.pending]: (state) => {
      state.isLoadingTableData = true;
    },
    [getDevicesAction.fulfilled]: (state, action) => {
      state.isLoadingTableData = false;
      const data = action?.payload?.res?.data?.data;
      state.fetchedDevices = data;
    },
    [getDevicesAction.rejected]: (state) => {
      state.isLoadingTableData = false;
    },
    // create device
    [createDeviceAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [createDeviceAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload?.res;
      if (checkRes(res)) {
        state.fetchDataCount += 1;
        state.deviceFormOpened = false;
      }
    },
    [createDeviceAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    // edit device
    [updateDeviceAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [updateDeviceAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action?.payload?.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.deviceFormOpened = false;
      }
    },
    [updateDeviceAction.rejected]: (state) => {
      state.isSubmitting = false;
    },
    //
    [deleteDeviceAction.pending]: (state) => {
      state.isSubmitting = true;
    },
    [deleteDeviceAction.fulfilled]: (state, action) => {
      state.isSubmitting = false;
      const res = action.payload.res;
      if (checkRes(res)) {
        state.selectedTableRow = null;
        state.fetchDataCount += 1;
        state.deleteModalOpened = false;
      }
    },
    [deleteDeviceAction.rejected]: (state) => {
      state.isSubmitting = false;
    }
  }
});

const devicesReducer = devicesSlice.reducer;

export const {
  setDeviceFormOpenedAction,
  setIsLoadingDevices,
  setDeleteModalOpened,
  setSelectedTableRow,
  setFetchedCountries
} = devicesSlice.actions;

export default devicesReducer;
