import axios from 'axios';
import { useState } from 'react';
import { useEffect } from 'react';

const useGetData = (url) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);

  const handleGetData = async () => {
    try {
      const res = await axios.get(url);
      if (res?.data?.data) {
        console.log(res?.data?.data);
        setData(res?.data?.data);
      } else {
        setData(res?.data);
      }
      setLoading(false);
    } catch (error) {
      setError(error?.response?.data?.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    handleGetData();
  }, []);

  return { data, loading, error };
};

export default useGetData;
