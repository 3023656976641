export const packageTypesValues = {
  online: 1,
  offline: 2
};

export const packageTypesArr = (t) => {
  return [
    {
      id: 1,
      name: 'متصل بشبكه الانترنت',
      value: packageTypesValues.online
    },
    {
      id: 2,
      name: 'غير متصل بشبكه الانترنت',
      value: packageTypesValues.offline
    }
  ];
};
