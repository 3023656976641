/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Button, Dropdown, Form } from 'antd';
import './ClientForm.scss';
import imagesSrcs from '../../helpers/imagesSrcs';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import { useDispatch, useSelector } from 'react-redux';
import Xicon from '../../common/icons/Xicon';
import AntdSelectOption from '../../common/antd-form-components/AntdSelectOption';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import {
  setPostFormModalOpened,
  setWatchClientPackage
} from '../../redux/clients-slice/clientsSlice';
import {
  createClientAction,
  updateClientAction
} from '../../redux/clients-slice/clientsSliceThunkApi';
import EyeOnIcon from '../../common/icons/EyeOnIcon';
import EyeOffIcon from '../../common/icons/EyeOffIcon';
import lngs from '../../languages';
import { useContext } from 'react';
import SharedAppContext from '../../contexts/shared-app-context/SharedAppContext';
import FileInput from '../../common/file-input/FileInput';

const schema = Yup.object().shape({
  name: Yup.string().required('من فضلك ادخل اسم المؤسسة'),
  phone: Yup.string()
    .required('من فضلك ادخل رقم هاتف العميل')
    .matches(/^[0-9]+$/, 'رقم هاتف خاطئ')
    .length(9, 'رقم هاتف يجب ان يكون 9 ارقام'),
  email: Yup.string()
    .required('من فضلك ادخل البريد الالكترونى')
    .email('بريد الكترونى خاطئ'),
  password: Yup.string('كلمة المرور يجب ان تكون اكبر من 6').test(
    (value, context) => {
      if (!value) return true;
      if (value.length < 6)
        return context.createError({
          message: 'كلمة المرور يجب ان تكون اكبر من 6'
        });
      return true;
    }
  ),
  password_confirmation: Yup.string().oneOf(
    [Yup.ref('password'), null],
    'كلمة المرور غير متطابقة'
  ),
  package_id: Yup.string().required('من فضلك اختار الباقة'),
  device_id: Yup.string().required('من فضلك اختار الجهاز')
});

const { medalIcon, emailIcon, phoneIcon, lockIcon, imageIcon, deviceIcon } =
  imagesSrcs;

const emptyFormValues = {
  name: '',
  phone: '',
  email: '',
  password: '',
  package_id: '',
  device_id: '',
  image: ''
};

const ClientForm = () => {
  const { fetchedCountries } = useContext(SharedAppContext);
  const [passwrodVisible, setPasswordVisible] = useState(false);
  const [files, setFiles] = useState([]);
  const dispatch = useDispatch();
  const selectedTableRow = useSelector(
    (store) => store.clients.selectedTableRow
  );
  ///////////////
  // brands selectors
  const fetchedPackages = useSelector(
    (store) => store.appPackages.fetchedTableData
  );
  const fetchedDevices = useSelector((store) => store.devices.fetchedDevices);
  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    register,
    unregister,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'onSubmit',
    defaultValues: emptyFormValues
  });

  useEffect(() => {
    if (fetchedPackages?.length > 0) {
      const found = fetchedPackages.find((p) => p?.id == watch('package_id'));
      if (found) dispatch(setWatchClientPackage(found));
    }
    return () => {
      dispatch(setWatchClientPackage(null));
    };
  }, [watch('package_id')]);
  const [packagesOptions, setPackagesOptions] = useState([]);
  useEffect(() => {
    if (fetchedPackages?.length > 0) {
      setPackagesOptions(fetchedPackages.filter((obj) => obj?.status === 1));
    }
  }, [fetchedPackages]);

  // handle initial values
  useEffect(() => {
    if (selectedTableRow) {
      setValue('name', selectedTableRow?.name || '');
      setValue('phone', selectedTableRow?.phone || '');
      setValue('email', selectedTableRow?.email || '');
      setValue('password', selectedTableRow?.password || '');
      setValue('image', selectedTableRow?.image || '');

      setValue(
        'package_id',
        selectedTableRow?.subscription?.package?.id
          ? String(selectedTableRow.subscription.package.id)
          : ''
      );
      console.log('selectedTableRow', selectedTableRow);
      setValue(
        'device_id',
        selectedTableRow?.subscription?.device?.id
          ? String(selectedTableRow.subscription.device.id)
          : ''
      );
    }
  }, [selectedTableRow, fetchedPackages]);
  const onSubmit = (data) => {
    const formData = new FormData();
    formData.append('name', data.name || '');
    formData.append('phone', data.phone || '');
    formData.append('email', data.email || '');
    formData.append('password', data.password || '');
    formData.append('package_id', data.package_id || '');
    formData.append('device_id', data.device_id || '');
    if (data?.image?.length > 0) formData.append('image', data.image[0]);

    if (!selectedTableRow) {
      dispatch(
        createClientAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    } else {
      formData.append('client_id', selectedTableRow?.id || '');

      dispatch(
        updateClientAction({
          dataToBeSubmitted: formData,
          cb: () => {
            reset(emptyFormValues);
          }
        })
      );
    }
  };

  const [form] = Form.useForm();

  return (
    <Form
      className="custom-shared-form client-form"
      form={form}
      layout="vertical"
      onFinish={handleSubmit(onSubmit)}
    >
      <p className="form-title">
        إضافة عميل جديد
        <button
          type="button"
          className="back-btn"
          onClick={() => {
            dispatch(setPostFormModalOpened(false));
          }}
        >
          {/* <ArrowToLeftIcon color="#40404199" /> */}
          <Xicon color="#40404199" />
        </button>
      </p>
      <div className="form-body">
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            <span>إســم المؤسسة</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="name"
            type="text"
            placeholder={'إســم المؤسسة...'}
            // label="الاســــم"
            errorMsg={errors?.name?.message}
            validateStatus={errors?.name ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={phoneIcon} alt="name" />
            <span>رقم الهاتف</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="phone"
            type="text"
            placeholder={'رقم الهاتف...'}
            // label="الاســــم"
            errorMsg={errors?.phone?.message}
            validateStatus={errors?.phone ? 'error' : ''}
            control={control}
            prefix={
              <Dropdown
                arrow
                trigger={['click']}
                // disabled={loadingSignout}
                menu={{
                  items:
                    fetchedCountries?.length > 0
                      ? [
                          ...fetchedCountries.map((obj) => ({
                            key: obj.id,
                            onClick: () => {
                              console.log('clicked');
                            },
                            label: (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: '4px',
                                  direction: 'ltr !important'
                                }}
                              >
                                <span
                                // key={obj?.countryCode}
                                // className={`fi fi-${obj.countryCode}`}
                                >
                                  {obj.name}
                                </span>
                                {obj.countryCode}
                              </div>
                            )
                          }))
                        ]
                      : []
                }}
              >
                <Button
                  style={{
                    direction: 'ltr',
                    backgroundColor: '#eee',
                    borderRadius: 99,
                    marginInlineEnd: '-14px',
                    padding: '5px 14px',
                    lineHeight: '1.4'
                  }}
                  className="profile-menu-btn"
                  type="text"
                >
                  {fetchedCountries[0]?.countryCode || '+966'}
                </Button>
              </Dropdown>
            }
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={emailIcon} alt="name" />
            <span>البريد الالكترونى</span>
          </p>
          <AntdTextField
            className="form-text-field"
            name="email"
            type="text"
            placeholder={'البريد الالكترونى...'}
            // label="الاســــم"
            errorMsg={errors?.email?.message}
            validateStatus={errors?.email ? 'error' : ''}
            control={control}
          />
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={lockIcon} alt="name" />
            <span>كلمة المرور</span>
          </p>
          <div className="client-password-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="password"
              type={passwrodVisible ? 'text' : 'password'}
              placeholder="كلمة المرور..."
              errorMsg={errors?.password?.message}
              validateStatus={errors?.password ? 'error' : ''}
              control={control}
            />

            {watch('password') && (
              <div
                className="eye-icon-btn"
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </div>
            )}
          </div>
        </div>
        <div className="shared-app-text-field-label-wrapper">
          <p className="outside-label">
            <img src={lockIcon} alt="name" />
            <span>أعد كتابة كلمة المرور</span>
          </p>
          <div className="client-password-field-wrap">
            <AntdTextField
              className="form-text-field"
              name="password_confirmation"
              type={passwrodVisible ? 'text' : 'password'}
              placeholder="أعد كتابة كلمة المرور..."
              errorMsg={errors?.password_confirmation?.message}
              validateStatus={errors?.password_confirmation ? 'error' : ''}
              control={control}
            />

            {watch('password_confirmation') && (
              <div
                className="eye-icon-btn"
                onClick={() => {
                  setPasswordVisible((prevState) => !prevState);
                }}
              >
                {passwrodVisible ? <EyeOnIcon /> : <EyeOffIcon />}
              </div>
            )}
          </div>
        </div>
        <div className="select-label-wrap shared-app-select-option-label-wrapper">
          <p className="outside-label">
            <img src={medalIcon} alt="name" />
            أختار الباقة
          </p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`package_id`}
              errorMsg={errors?.package_id && errors.package_id.message}
              validateStatus={errors?.package_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="اختار الباقة"
              options={
                packagesOptions?.length > 0 &&
                packagesOptions.map((obj) => ({
                  title: obj?.name,
                  value: obj?.id
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="client-form"
            />
          </div>
        </div>
        <div className="select-label-wrap shared-app-select-option-label-wrapper">
          <p className="outside-label">
            <img src={deviceIcon} alt="name" />
            أختار الجهاز
          </p>
          <div className="custom-select-wrap without-icon">
            <AntdSelectOption
              name={`device_id`}
              errorMsg={errors?.device_id && errors.device_id.message}
              validateStatus={errors?.device_id ? 'error' : ''}
              control={control}
              setValue={setValue}
              placeholder="اختار الباقة"
              options={
                fetchedDevices?.length > 0 &&
                fetchedDevices.map((obj) => ({
                  title: obj?.name,
                  value: obj?.id
                }))
              }
              showSearch={true}
              // onSearch={onSearch}
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              formClassName="client-form"
            />
          </div>
        </div>
        <div className="file-inputs-wrap">
          <FileInput
            name="image"
            label={
              <p
                className="outside-label"
                style={{
                  color: '#404041',
                  display: 'flex',
                  alignItems: 'center',
                  gap: 8,
                  marginBottom: 8,
                  paddingRight: 8
                }}
              >
                <img src={imageIcon} alt="name" />
                صورة العميل
              </p>
            }
            accept="image/*"
            multiple={false}
            setValue={setValue}
            watch={watch}
            register={register}
            unregister={unregister}
            // setUrls={setUrls}
            dropzoneText="اسحب الصورة وضعها هنا ..."
            className="product-images-dropzone"
            dropzoneUrls={
              selectedTableRow?.image ? [{ url: selectedTableRow.image }] : []
            }
            canDelete={false}
            showError={false}
            errorMsg={errors?.image?.message}
            files={files}
            setFiles={setFiles}
          />
        </div>
        <div className="modal-action-btns-wrapper">
          <button className="accept-btn" onClick={() => {}}>
            <CheckmarkCircledIcon />
            حفظ
          </button>
          <button
            type="button"
            onClick={() => {
              dispatch(setPostFormModalOpened(false));
            }}
            className="cancel-btn"
          >
            <CancelCircledIcon color="#404041" />
            إلغاء
          </button>
        </div>
      </div>

      {/* {isLoadingSignin && <LoadingModal />} */}
    </Form>
  );
};

export default ClientForm;
