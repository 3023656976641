/* eslint-disable react-hooks/exhaustive-deps */
import { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import CancelCircledIcon from '../../common/icons/CancelCircledIcon';
import CheckmarkCircledIcon from '../../common/icons/CheckmarkCircledIcon';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Form } from 'antd';
import AntdTextField from '../../common/antd-form-components/AntdTextField';
import imagesSrcs from '../../helpers/imagesSrcs';
import FileInput from '../../common/file-input/FileInput';
import UserContext from '../../contexts/user-context/UserProvider';
import './EditProfilePage.scss';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import updateProfileApi from '../../apis/auth/updateProfileApi';
import checkRes from '../../utils/checkRes';
import LoadingModal from '../../common/loading-modal/LoadingModal';
import { AnimatePresence, motion } from 'framer-motion';
import ChangePasswordForm from './ChangePasswordForm';

const EditProfilePage = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const { user, setCurrentUser } = useContext(UserContext);

  const schema = Yup.object().shape({
    email: Yup.string()
      .required('ادخل البريد الاكترونى')
      .email('ادخل بريد الكترونى صحيح'),
    phone: Yup.string().required('من فضلك ادخل رقم الهاتف'),
    name: Yup.string().required('من فضلك ادخل الاسم')
  });
  const {
    control,
    handleSubmit,
    setValue,
    register,
    unregister,
    watch,
    reset,
    formState: { errors }
  } = useForm({
    resolver: yupResolver(schema),
    mode: 'all',
    defaultValues: {
      name: '',
      phone: '',
      email: '',
      image: null
    }
  });

  // handle initial values
  useEffect(() => {
    if (user) {
      setValue('name', user?.name || '');
      setValue('phone', user?.phone || '');
      setValue('email', user?.email || '');
    }
  }, [user]);
  const [files, setFiles] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const customApiRequest = useCustomApiRequest();
  const onSubmit = (data) => {
    setIsSubmitting(true);
    const formData = new FormData();
    formData.append('name', data.name || '');
    formData.append('phone', data.phone || '');
    formData.append('email', data.email || '');
    if (data.image) formData.append('image', data.image[0]);
    customApiRequest(
      updateProfileApi(formData),
      (res) => {
        setIsSubmitting(false);
        if (checkRes(res) && res?.data?.data) {
          setCurrentUser({
            ...res?.data?.data,
            token: user?.token,
            accountType: user.accountType,
            activated: user.activated
          });
        }
      },
      (error) => {
        setIsSubmitting(false);
      }
    );
  };

  const handleCancelForm = () => {
    reset({
      name: user?.name || '',
      phone: user?.phone || '',
      email: user?.email || ''
    });
  };
  const [changePassFormVisible, setChangePassFormVisible] = useState(false);

  const [form] = Form.useForm();
  return (
    <>
      <div className="edit-profile-page">
        <div className="profil-page-title">
          <h4>تعديل بيانات الحساب الشخصي</h4>
        </div>

        <div className="page-content-wrapper">
          <div className="page-main-content-wrapper">
            <Form
              className="custom-shared-form edit-profile-form"
              form={form}
              layout="vertical"
              onFinish={handleSubmit(onSubmit)}
            >
              <div className="form-body">
                <div className="shared-app-text-field-label-wrapper">
                  <p className="outside-label">
                    <img src={imagesSrcs?.userIcon} alt="name" />
                    <span>اسم السمتخدم</span>
                  </p>
                  <AntdTextField
                    className="form-text-field"
                    name="name"
                    type="text"
                    placeholder={'اسم المستخدم...'}
                    // label="الاســــم"
                    errorMsg={errors?.name?.message}
                    validateStatus={errors?.name ? 'error' : ''}
                    control={control}
                  />
                </div>
                <div className="shared-app-text-field-label-wrapper">
                  <p className="outside-label">
                    <img src={imagesSrcs?.phoneIcon} alt="name" />
                    <span>رقم هاتف المستخدم</span>
                  </p>
                  <AntdTextField
                    className="form-text-field"
                    name="phone"
                    type="text"
                    placeholder={'رقم هاتف المستخدم...'}
                    // label="الاســــم"
                    errorMsg={errors?.phone?.message}
                    validateStatus={errors?.phone ? 'error' : ''}
                    control={control}
                  />
                </div>
                <div className="shared-app-text-field-label-wrapper">
                  <p className="outside-label">
                    <img src={imagesSrcs?.emailIcon} alt="name" />
                    <span>البريد الالكترونى</span>
                  </p>
                  <AntdTextField
                    className="form-text-field"
                    name="email"
                    type="text"
                    placeholder={'البريد الالكترونى...'}
                    // label="الاســــم"
                    errorMsg={errors?.email?.message}
                    validateStatus={errors?.email ? 'error' : ''}
                    control={control}
                  />
                </div>

                <div className="file-inputs-wrap">
                  <FileInput
                    name="image"
                    // accept="image/png, image/jpg, image/jpeg, image/gif"
                    label={
                      <p
                        className="outside-label"
                        style={{
                          color: '#404041',
                          display: 'flex',
                          alignItems: 'center',
                          gap: 8,
                          marginBottom: 8,
                          paddingRight: 8
                        }}
                      >
                        <img src={imagesSrcs?.imageIcon} alt="name" />
                        صورة الجهاز
                      </p>
                    }
                    accept="image/*"
                    multiple={false}
                    setValue={setValue}
                    watch={watch}
                    register={register}
                    unregister={unregister}
                    // setUrls={setUrls}
                    dropzoneText="اسحب الصورة وضعها هنا ..."
                    className="product-images-dropzone"
                    dropzoneUrls={user?.image ? [{ url: user.image }] : []}
                    canDelete={false}
                    showError={false}
                    errorMsg={errors?.image?.message}
                    files={files}
                    setFiles={setFiles}
                  />
                </div>

                <div className="modal-action-btns-wrapper">
                  <button className="accept-btn" onClick={() => {}}>
                    <CheckmarkCircledIcon />
                    حفظ
                  </button>
                  <button
                    type="button"
                    onClick={() => {
                      handleCancelForm();
                    }}
                    className="cancel-btn"
                  >
                    <CancelCircledIcon color="#404041" />
                    إلغاء
                  </button>
                </div>
              </div>

              {/* {isLoadingSignin && <LoadingModal />} */}
            </Form>

            <div
              className={`change-pass-btn-wrapper ${
                changePassFormVisible ? 'visible' : ''
              }`}
            >
              <button
                onClick={() => {
                  setChangePassFormVisible((prev) => !prev);
                }}
              >
                تغيير كلمة المرور
                <img src={imagesSrcs?.arrowLeft} alt="arrow" />
              </button>
            </div>
          </div>

          {/*  */}

          <AnimatePresence>
            {changePassFormVisible && (
              <motion.div
                initial={{
                  opacity: 0,
                  scale: 0.98
                }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  transitionDuration: 0.2
                }}
                exit={{
                  opacity: 0,
                  transitionDuration: 0.2
                }}
              >
                <ChangePasswordForm />
              </motion.div>
            )}
          </AnimatePresence>
        </div>
      </div>
      {isSubmitting && <LoadingModal />}
    </>
  );
};

export default EditProfilePage;
