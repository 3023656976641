import { LoadingOutlined } from '@ant-design/icons';
import queryString from 'query-string';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import CustomEmpty from '../../common/custom-empty/CustomEmpty';
import imagesSrcs from '../../helpers/imagesSrcs';
import {
  setClientInvoiceModalOpened,
  setSelectedClientInvoice
} from '../../redux/clients-slice/clientsSlice';
import { getSingleClientsAction } from '../../redux/clients-slice/clientsSliceThunkApi';
import ClientInfoSection from './ClientInfoSection';
import ClientInvoiceModal from './ClientInvoiceModal';
import PackageDescription from './PackageDescription';
import './SingleClientPage.scss';

const SingleClientPage = () => {
  const { search } = useLocation();
  const values = queryString.parse(search);
  const params = useParams();
  const fetchDataCount = useSelector((store) => store.clients.fetchDataCount);
  const selectedClientInvoice = useSelector(
    (store) => store.clients.selectedClientInvoice
  );
  const isLoadingTableData = useSelector(
    (store) => store.clients.isLoadingTableData
  );
  const fetchedSingleClient = useSelector(
    (store) => store.clients.fetchedSingleClient
  );
  const invoiceModalOpened = useSelector(
    (store) => store.clients.invoiceModalOpened
  );
  const navigate = useNavigate();
  // const isSubmitting = useSelector((store) => store.clients.isSubmitting);
  const dispatch = useDispatch();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  let isMounted = true;

  useEffect(() => {
    if (isMounted)
      dispatch(
        getSingleClientsAction({
          filterObj: {
            client_id: params?.client_id || '',
            ...values
          }
        })
      );

    return function () {
      isMounted = false;
    };
  }, [fetchDataCount, search]);

  if (isLoadingTableData) {
    return (
      <div className="single-client-page">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 360
          }}
          className="loading-wrapper"
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      </div>
    );
  } else if (!fetchedSingleClient) {
    return (
      <div className="single-client-page">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 360
          }}
          className="loading-wrapper"
        >
          <h2>لا توجد بيانات متاحة</h2>
        </div>
      </div>
    );
  }

  return (
    <div className="single-client-page">
      <ClientInfoSection fetchedSingleClient={fetchedSingleClient} />

      <div className="subscription-invoices-wrapper">
        <div className="subscription-wrapper">
          <PackageDescription
            item={fetchedSingleClient?.clientInfo?.subscription?.package}
          />
        </div>
        <div className="invoices-wrapper">
          <p className="sec-title">فواتير العميل</p>

          {fetchedSingleClient?.invoices?.length > 0 ? (
            <div className="invoices-list-wrapper">
              {fetchedSingleClient.invoices.map((invoice) => {
                return (
                  <div key={invoice.id} className="list-item-wrapper">
                    <div className="main-c">
                      <div className="img-wrap">
                        <img src={imagesSrcs.receiptIcon} alt="receipt" />
                      </div>

                      <div className="item-content">
                        <div className="label">فاتورة # {invoice?.id}</div>
                        <div className="price">باقة {invoice?.price} ر.س</div>
                      </div>
                    </div>
                    <div className="date">{invoice?.date}</div>

                    <button
                      className="invoice-deatils-btn"
                      onClick={() => {
                        // dispatch(setSelectedClientInvoice(invoice));
                        // dispatch(setClientInvoiceModalOpened(true));
                        window.open(
                          `https://mycash.sa/client_public/${params?.client_id}/${invoice?.id}`,
                          '_blank'
                        );

                        // navigate(
                        //   `/client_public/${params?.client_id}/${invoice?.id}`
                        // );
                      }}
                    >
                      التفاصيل
                    </button>
                  </div>
                );
              })}

              <ClientInvoiceModal
                modalData={selectedClientInvoice}
                parentInfo={fetchedSingleClient?.clientInfo}
                modalOpened={invoiceModalOpened}
                setModalOpened={(v) => {
                  if (!v) dispatch(setSelectedClientInvoice(null));
                  dispatch(setClientInvoiceModalOpened(v));
                }}
              />
            </div>
          ) : (
            <CustomEmpty />
          )}
        </div>
      </div>
    </div>
  );
};

export default SingleClientPage;
