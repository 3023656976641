/* eslint-disable react-hooks/exhaustive-deps */
import { useState, createContext, useEffect, useContext } from 'react';
import Geocode from 'react-geocode';
import { useTranslation } from 'react-i18next';
import getGeneralCountriesApi from '../../apis/general/generalCountriesApi';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import useGeoLocation from '../../custom-hooks/useGeoLocation';
import checkRes from '../../utils/checkRes';
import UserContext from '../user-context/UserProvider';

const INITIAL_VALUES = {
  allFetchedFilterCats: [],
  location: null,
  appCity: null,
  setAppCity: (v) => {},

  // home data
  isLoadingCountries: false,
  setIsLoadingCountries: (v) => {},
  fetchedCountries: [],
  setFetchedCountries: (v) => {}
};

const SharedAppContext = createContext(INITIAL_VALUES);

export const SharedAppProvider = ({ children }) => {
  const { user } = useContext(UserContext);
  const customApiRequest = useCustomApiRequest();
  const { i18n } = useTranslation();
  const [appCity, setAppCity] = useState(INITIAL_VALUES?.appCity);
  const [isLoadingCountries, setIsLoadingCountries] = useState(
    INITIAL_VALUES.isLoadingCountries
  );
  const [fetchedCountries, setFetchedCountries] = useState(
    INITIAL_VALUES.fetchedCountries
  );

  const location = useGeoLocation();

  useEffect(() => {
    Geocode.setApiKey(process.env.REACT_APP_GOOGLE_MAPS_API_KEY);
    Geocode.setLanguage(i18n.language);
    Geocode.fromLatLng(
      location?.coordinates?.lat || 21.4858,
      location?.coordinates?.lng || 39.1925
    ).then(
      (res) => {
        if (
          res?.results?.length > 0 &&
          res.results[0]?.address_components?.length > 0 &&
          res?.results[0]?.address_components[4]
        ) {
          // console.log('results : ', res);
          setAppCity(res.results[0].address_components[4].short_name);
        }
      },
      (error) => {
        console.log('err : ', error);
      }
    );
  }, [location?.loaded, location?.coordinates, i18n.language]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && user?.token) {
      customApiRequest(
        getGeneralCountriesApi(i18n.language),
        (res) => {
          if (isMounted) {
            if (checkRes(res)) {
              setFetchedCountries(res.data.data);
            }
          }
        },
        (error) => {}
      );
    }
    return () => {
      isMounted = false;
    };
  }, []);

  return (
    <SharedAppContext.Provider
      value={{
        appCity,
        setAppCity,
        location,
        //
        isLoadingCountries,
        setIsLoadingCountries,
        fetchedCountries,
        setFetchedCountries
      }}
    >
      {children}
    </SharedAppContext.Provider>
  );
};

export default SharedAppContext;
