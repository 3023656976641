import { createAsyncThunk } from '@reduxjs/toolkit';
import getAllClientsApi from '../../apis/clients-apis/getAllClientsApi';
import customApiRequest from '../../helpers/customApiRequest';

export const getArchivesAction = createAsyncThunk(
  'archives/getArchivesAction',
  async ({ filterObj }, thunkAPI) => {
    return customApiRequest({
      apiCall: await getAllClientsApi({ ...filterObj, archive: 1 }),
      thunkAPI
    });
  }
);
