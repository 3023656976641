import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import './DropdownFilter.scss';

const { Option } = Select;

const DropdownFilter = ({
  filterOptionsArr,
  // defaultValue,
  onChange,
  value
}) => {
  const { t } = useTranslation();

  const handleChange = (e) => {
    if (onChange && typeof onChange === 'function') onChange(e);
  };

  return (
    <Select
      popupClassName="dropdown-filter-popup"
      className="sort-by-select-filter"
      // defaultValue={defaultValue || ''}
      value={value || ''}
      size="large"
      onChange={handleChange}
      suffixIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21 7.75H3C2.59 7.75 2.25 7.41 2.25 7C2.25 6.59 2.59 6.25 3 6.25H21C21.41 6.25 21.75 6.59 21.75 7C21.75 7.41 21.41 7.75 21 7.75Z"
            fill="#404041"
          />
          <path
            d="M18 12.75H6C5.59 12.75 5.25 12.41 5.25 12C5.25 11.59 5.59 11.25 6 11.25H18C18.41 11.25 18.75 11.59 18.75 12C18.75 12.41 18.41 12.75 18 12.75Z"
            fill="#404041"
          />
          <path
            d="M14 17.75H10C9.59 17.75 9.25 17.41 9.25 17C9.25 16.59 9.59 16.25 10 16.25H14C14.41 16.25 14.75 16.59 14.75 17C14.75 17.41 14.41 17.75 14 17.75Z"
            fill="#404041"
          />
        </svg>
      }
    >
      <Option value={''}>{t('sort.empty')}</Option>
      {filterOptionsArr?.length > 0 &&
        filterOptionsArr.map((op, index) => (
          <Option key={index} value={String(op.value)}>
            {op.name}
          </Option>
        ))}
    </Select>
  );
};
export default DropdownFilter;
