import axios from 'axios';
import apisUrls from '../urls';

const getSingleAdminApi = (paramsFilter) => {
  return axios.get(
    `${apisUrls?.getSingleAdmin}?admin_id=${paramsFilter?.admin_id || ''}`
  );
};

export default getSingleAdminApi;
