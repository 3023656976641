/* eslint-disable react-hooks/exhaustive-deps */
import StatisticsBoxs from './StatisticsBoxs';
import './HomePage.scss';
import RecentClientsTable from './RecentClientsTable';
import { useEffect, useState } from 'react';
import getHomeApi from '../../apis/general/getHomeApi';
import checkRes from '../../utils/checkRes';
import { useTranslation } from 'react-i18next';
import useCustomApiRequest from '../../custom-hooks/useCustomApiRequest';
import { LoadingOutlined } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import LoadingModal from '../../common/loading-modal/LoadingModal';

const HomePage = () => {
  const [isLoading, setIsLoading] = useState(0);
  const [fetchCount, setFetchCount] = useState(0);
  const [fetchedData, setFetchedData] = useState(null);
  const customApiRequest = useCustomApiRequest();
  const isSubmitting = useSelector((store) => store.clients.isSubmitting);
  const { i18n } = useTranslation();
  useEffect(() => {
    let isMounted = true;

    setIsLoading(true);
    customApiRequest(
      getHomeApi(i18n.language),
      (res) => {
        if (isMounted) {
          setIsLoading(false);
          if (checkRes(res) && res?.data?.data) {
            setFetchedData(res.data.data);
          }
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );

    return () => {
      isMounted = false;
    };
  }, [i18n.language, fetchCount]);

  if (isLoading) {
    return (
      <div className="settings-page">
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 360
          }}
          className="loading-wrapper"
        >
          <LoadingOutlined style={{ fontSize: 24 }} spin />
        </div>
      </div>
    );
  }
  return (
    <div className="home-page">
      <StatisticsBoxs obj={fetchedData} />

      {/* <div className="social-stats-main-box-wrapper">
        <SocialStatistics />
        <div className="main-box">
          <div className="box-title">
            <p>مستخدمى تطبيق</p>
            <p>mycash</p>
          </div>
          <p className="box-value">{fetchedData?.usersCount || '--'}</p>
          <p className="value-label">مستخدم</p>
        </div>
      </div> */}

      {/*  */}
      <RecentClientsTable
        fetchedTableData={fetchedData?.users}
        setFetchCount={setFetchCount}
      />
      {/*  */}

      {isSubmitting && <LoadingModal />}
    </div>
  );
};

export default HomePage;
