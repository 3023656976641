import axios from 'axios';
import apisUrls from '../urls';

const createAdminApi = (values, edit = false) => {
  const url = !edit ? apisUrls?.createAdmin : apisUrls?.updateAdmin;

  return axios.post(url, values, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
};

export default createAdminApi;
