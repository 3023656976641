/* eslint-disable react-hooks/exhaustive-deps */
import { useDispatch, useSelector } from 'react-redux';
import ConfirmModal from '../../common/confirm-modal/ConfirmModal';
import { confirmModalTypesValues } from '../../const-values/confirmModalTypes';
import {
  setConfirmModal,
  setDeleteModalOpened,
  setSelectedTableRow
} from '../../redux/clients-slice/clientsSlice';
import {
  changeClientStatusAction,
  deleteClientAction
} from '../../redux/clients-slice/clientsSliceThunkApi';
import ClientsPageTable from '../clients-page/ClientsPageTable';

const RecentClientsTable = ({ fetchedTableData, setFetchCount }) => {
  const opened = useSelector((store) => store.clients.confirmModal.opened);
  const deleteModalOpened = useSelector(
    (store) => store.clients.deleteModalOpened
  );
  const type = useSelector((store) => store.clients.confirmModal.type);
  const selectedTableRow = useSelector(
    (store) => store.clients.selectedTableRow
  );

  const dispatch = useDispatch();

  let isMounted = true;
  const handleDeleteClient = () => {
    if (isMounted && selectedTableRow?.id) {
      const formData = new FormData();
      formData.append('client_id', selectedTableRow.id);
      dispatch(
        deleteClientAction({
          dataToBeSubmitted: formData,
          isClientPage: false,
          cb: () => setFetchCount((prev) => prev + 1)
        })
      );
    }
  };

  const handleChangeClientStatus = () => {
    if (isMounted && selectedTableRow?.id) {
      const formData = new FormData();
      formData.append('client_id', selectedTableRow.id);
      dispatch(
        changeClientStatusAction({
          dataToBeSubmitted: formData,
          isClientPage: false,
          cb: () => setFetchCount((prev) => prev + 1)
        })
      );
    }
  };

  return (
    <div className="recent-clients-table-wrapper">
      <h3
        style={{
          color: 'rgba(64, 64, 65, 0.6)',
          marginBottom: 18,
          fontWeight: 'bold'
        }}
      >
        عملاء انضمو حديثا
      </h3>

      <ClientsPageTable
        fetchedTableData={fetchedTableData}
        tablePagination={null}
      />

      {/* confirm clients modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={opened}
        modalType={type}
        setModalOpened={(v) => {
          !selectedTableRow && dispatch(setSelectedTableRow(null));

          dispatch(
            setConfirmModal({
              opened: v,
              type: ''
            })
          );
        }}
        handleAccept={handleChangeClientStatus}
      >
        {type === confirmModalTypesValues?.successModal && (
          <h3>{`هل انت متأكد من قبول العميل  ${
            selectedTableRow?.name || ''
          }`}</h3>
        )}
        {type === confirmModalTypesValues?.errorModal && (
          <h3>{`هل انت متأكد من رفض العميل  ${
            selectedTableRow?.name || ''
          }`}</h3>
        )}
      </ConfirmModal>
      {/* end confirm clients modal */}

      {/* delete client modal */}
      <ConfirmModal
        modalTitle=""
        modalOpened={deleteModalOpened}
        modalType={confirmModalTypesValues?.errorModal}
        setModalOpened={(v) => dispatch(setDeleteModalOpened(v))}
        handleAccept={handleDeleteClient}
      >
        <h3>{`هل انت متأكد من حذف العميل ${selectedTableRow?.name || ''}`}</h3>
      </ConfirmModal>
      {/* end delete client modal */}

      {/* <SharedAntdTable
        scroll={1200}
        showHeader={true}
        className={`recent-clients-table ${i18n.dir()}`}
        hasPagination={false}
        tableDataSource={tableDataSource}
        tableColumns={RecentClientsTableColumns}
        allFetchedTableData={fetchedTableData}
        setFormModalOpened={() => setPostFormModalOpened(true)}
        // setDetailsModalOpened={setDetailsModalOpened}
        //
        deleteModalOpened={deleteModalOpened}
        setDeleteModalOpened={(v) => setDeleteModalOpened(v)}
        setSelectedTableRow={(v) => setSelectedTableRow(v)}
      setConfirmModal={(v) => (setConfirmModal(v))}

        //

        isLoadingTableData={isLoadingTableData}
        user={user}
      /> */}
    </div>
  );
};

export default RecentClientsTable;
